import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Facebook from "../../pages/image/facebook.png"
import Google from "../../pages/image/google.png"
import LoaderCon from "../../common/loader/containers/loader_cont";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import OtpInput from 'react-otp-input';
import vdo from "../../media/img/Computer.gif";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { Row } from 'rsuite';
import Pay from "../../auth/components/payment";
import logim from "../../pages/image/logim.png"
// import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

// import Login from "taskplay-web"



export default class Emplogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            otp: "",
            phone: "",
            contact_num: "",
            otpCode: '',
            change_num: false,
            enter_num: false,
            bt_type: true,
            otpClick: false,
            email: "",
        };
    }

    handleOnChange = (value, data, event, formattedValue) => {
        this.setState({
            contact_num: value,
        });
    }
    componentDidMount() {
        document.body.style.backgroundColor = "var(--bg, #FBFFFD)";
    }
    // handleChangeNumber = (event) => {
    //     const inputValue = event.target.value;
    //     const numberValue = Number(inputValue);
    //     if (!isNaN(numberValue) && numberValue.toString().length <= 10) {
    //       this.props.onChange(numberValue);
    //     }
    //   };
    handlenumChange = (value) => {
        // const inputValue = event.target.value;
        // const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
        // const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
        this.setState({ contact_num: value, });
    }
    handleChange = (otp) => this.setState({ otp });
    render() {
        // const handleChange = otpCode => {
        //     this.setState({ otpCode });
        // };
        return (

            <Grid container sx={{ backgroundColor: "#FBFFFD" }} direction={'row'}>
                <Grid item  xs={5}  mt={'1%'}>
                    <img  style={{width:"700px"}} src={logim} >
                    </img>
                </Grid>
                <Grid item xs={0.5}>
                </Grid>
                <Grid item xs={6} >
                    <center>
                        <Grid container display={'flex'} spacing={-20}>
                            <Grid className='login_text1' item xs={12} mt={20}>
                                <p style={{fontWeight:700}}>"Welcome, team Player!</p>
                            </Grid>

                            
                            <Grid className='login_text2' item xs={12} mt={-3}>
                                <p>Join us now to access your tasks from the inspiring team leader.<br/>
                                 Let's complete them with fun, enthusiasm, and meet all<br/>
                                  deadlines."</p>
                            </Grid>
                            {/* <Grid className='login_text3' item xs={12} mt={-2}>
                                <p>Login with your company's Gmail account to view assigned tasks and access your account.</p>
                            </Grid> */}
                            <Grid container >
                                <Grid item xs={12} mr={"65%"}>
                                    <p>Enter Your Email_ID<strong style={{ color: 'red' }}>*</strong></p>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField
                                        placeholder='Enter Your Email Id'
                                        sx={{ marginLeft: '10%' }}
                                        fullWidth
                                        size='small'
                                        type='email'
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={9}></Grid>
                                <Grid item xs={2} mt={1} direction={'row'}>
                                    {!this.state.otpClick && <Button

                                        sx={
                                            // this.state.email === '' ?
                                            { borderColor: "#AAAAAA", backgroundColor: "#AAAAAA", color: "#555555", }
                                            // : { width: "auto", borderColor: "#AAAAAA", backgroundColor: "var(--PrimaryColor, #1C352D)", color: "white" }
                                        }
                                        variant='outlined'
                                        onClick={() => {
                                            this.setState({ otpClick: true })
                                            this.props.payement_razorpay()
                                        }}
                                    >
                                        {
                                            // this.state.email === '' ?
                                            "Submit"
                                            // : 'Request OTP'
                                        }
                                    </Button>}
                                    {/* <Pay/> */}

                                </Grid>

                            </Grid>
                            {this.state.otpClick && <Grid container mt={'-3%'} >
                                <Grid item xs={12} mr={"80%"} >
                                    <p>OTP</p>
                                </Grid>
                                <Grid item xs={12} ml={'8%'}>
                                    <div style={{ marginLeft: '-2%' }}>
                                        <OtpInput
                                            inputStyle={{
                                                marginLeft: '%',
                                                margin: '2%',
                                                backgroundColor: '#FBFFFD',
                                                width: '5rem',
                                                height: '2rem',
                                                // marginLeft: "3%",
                                                fontSize: '2rem',
                                                borderRadius: '4px',
                                                borderBottom: '1px solid var(--grey-g-3, #B0BAC9)',
                                                borderTop: '0px solid black',
                                                borderRight: '0px solid black',
                                                borderLeft: '0px solid black',
                                                // boxShadow: " 2px 2px 6px rgba(0, 0, 0, 0.25)"
                                            }}

                                            onChange={this.handleChange}
                                            numInputs={5}
                                            value={this.state.otp}
                                            renderSeparator={<span></span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={8.2} display={'flex'} justifyContent={'flex-start'} mt={1} color={'var(--Warning-Color, #E74C3C)'}>Resend OTP</Grid>
                                <Grid item xs={2} mt={1} display={'flex'} justifyContent={'flex-start'}>
                                    <Button

                                        sx={{ borderColor: "#AAAAAA", backgroundColor: "var(--PrimaryColor, #1C352D)", color: "var(--grey-pure-white, #FFF)", }}
                                        variant='outlined'
                                        onClick={() => {
                                            this.props.payement_razorpay()
                                        }}
                                    >
                                        Submit
                                    </Button>
                                    {/* <Pay/> */}

                                </Grid>

                            </Grid>}
                        </Grid>
                    </center>
                </Grid>
                <Grid item xs={0.5}>

                </Grid>
            </Grid>

            // <div className='mainpage'>
            //     <Card style={{ height: "100vh", width: "40vw" }}>

            //         <img className='videos' src={vdo} >
            //             {/* <source src={vdo} type="video/mp4"/> */}
            //         </img>
            //     </Card>
            //     <Card className='logincard' style={{ height: "100vh", width: "60vw" }}>

            //         <Typography className='sign' style={{ fontSize: "22px", marginTop: "20%", marginLeft: "35%", fontWeight: 500 }}>
            //             Sign in
            //         </Typography>

            //         <Box style={{ marginLeft: "35%", marginTop: "5%" }} >
            //             <Typography>
            //                 Email
            //             </Typography>
            //             <TextField
            //                 type='email'
            //                 sx={{width:'55%'}}
            //                 onChange={(e) => {
            //                     this.setState({ contact_num: e.target.value, });
            //                 }}
            //             />
            //             {/* <PhoneInput

            //                 country={'in'}
            //                 // onChange={this.handleOnChange}
            //                 onChange={this.handlenumChange}
            //                 inputStyle={{ backgroundColor: "#F0EFFF" }}
            //                 buttonStyle={{ backgroundColor: "#f0efff" }}
            //                 disabled={this.state.change_num}
            //                 inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', }}
            //             /> */}
            //         </Box>
            //         {/* </center> */}


            //         {this.state.enter_num && <div>
            //             <Typography style={{ fontSize: "15px", marginTop: "3%", marginLeft: "35%", fontWeight: 400, color: "#B5B5B5" }}>
            //                 Please  Enter Your OTP  <Button
            //                     onClick={(e) => {
            //                         this.setState({
            //                             change_num: !this.state.change_num,
            //                             enter_num: !this.state.enter_num,
            //                             bt_type: !this.state.bt_type
            //                         })
            //                     }}
            //                 >
            //                     {/* <strong style={{ color: "#7900FF", fontSize: '15px', marginLeft: "20px" }}>Change Number</strong> */}
            //                     </Button>
            //             </Typography>




            //             <Box style={{ marginLeft: "34%", marginTop: "2%" }} >
            //                 <OtpInput
            //                     inputStyle={{
            //                         width: '2rem',
            //                         height: '2rem',
            //                         marginLeft: "3%",
            //                         fontSize: '2rem',

            //                         border: '1px solid rgba(0, 0, 0, 0.2)',
            //                         boxShadow: " 2px 2px 6px rgba(0, 0, 0, 0.25)"
            //                     }}
            //                     onChange={this.handleChange}
            //                     numInputs={5}
            //                     value={this.state.otp}
            //                     renderSeparator={<span></span>}
            //                     renderInput={(props) => <input {...props} />}
            //                 />
            //             </Box>





            //             {/* <Typography style={{ fontSize: "15px", marginTop: "3%", fontWeight: 400, color: "#B5B5B5", marginLeft: "35%", }}>
            //                 If you didn’t receive a code<Button><strong style={{ color: "#FF0000" }}> Resend OTP</strong></Button>
            //             </Typography> */}

            //         </div>}

            //         <Button style={{ backgroundColor: "#7900FF", height: "49px", width: "300px", marginTop: "3%", marginLeft: "35%" }} variant="contained"
            //             // disabled={this.state.phone.length!==10}
            //             disabled={this.state.contact_num.length < 12}
            //             onClick={(e) => {
            //                 this.setState({
            //                     change_num: !this.state.change_num,
            //                     enter_num: !this.state.enter_num,
            //                     bt_type: !this.state.bt_type
            //                 })
            //                 if (this.state.bt_type) {
            //                     this.props.phone_login(this.state.contact_num)
            //                 }
            //                 if (!this.state.bt_type) {
            //                     this.props.verify_otp(this.state.contact_num, this.state.otp)
            //                 }
            //             }}
            //         >{this.state.bt_type ? "Send OTP" : "Verify OTP"}</Button>

            //         {/* <center>
            //             <Typography style={{ fontSize: "15px", marginTop: "5%", fontWeight: 400, color: "#B5B5B5" }}>
            //                 or continue with
            //             </Typography>
            //         </center> */}


            //         {/* <div style={{ display: "flex", flexDirection: "row", marginTop: "7%", marginLeft: "43%" }}>

            //             <img
            //                 height={30}
            //                 src={Facebook}
            //                 alt="logo_img"
            //                 onClick={() => this.props.facebookLogin()} />
            //             <img
            //                 height={30}
            //                 src={Google}
            //                 alt="logo_img"
            //                 style={{ marginLeft: "5%" }}
            //                 onClick={() => this.props.googleLogin()}
            //             />
            //         </div> */}




            //     </Card>
            //     <LoaderCon />
            // </div>



        )
    }
}

