const UNIVERSAL = {
  //  BASEURL: "https://oms-backend-7wtf5c6yua-el.a.run.app/"
  // BASEURL: "https://821a-157-41-227-207.ngrok-free.app/",
  BASEURL: "https://task-backend-e5vkcvzdqa-el.a.run.app/",
  // BASEURL: "/",
  // URL: https://oms-backend-7wtf5c6yua-el.a.run.app,
  // BASEURL: "http://192.168.1.41:7008/",

};
export default UNIVERSAL;
