
 import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_ADMIN_ID = "SET_ADMIN_ID";
export const HANDLEDRAWER = "HANDLEDRAWER";
export const SETMOBILE = "SETMOBILE";
export const SET_ALL_ORG = "SET_ALL_ORG";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_ORG_DETAILS = "SET_ORG_DETAILS";
export const SET_ORG_USER_DEATILS = "SET_ORG_USER_DEATILS";
export const SET_ORG_ID = "SET_ORG_ID";
export const SET_ALL_DATA = "SET_ALL_DATA";
export const SET_USER = "SET_USER";
export const SET_ORG = "SET_ORG";
export const SETLOGINORG = "SETLOGINORG";
export const SET_ORG_USER_DETAILS = "SET_ORG_USER_DETAILS";
export const SET_ORG_DETAILS2 = "SET_ORG_DETAILS2";
export const VIEW_ALL_DEPARTMENT = "VIEW_ALL_DEPARTMENT";
export const VIEW_ALL_PRIORITY = "VIEW_ALL_PRIORITY";
export const VIEW_ALL_STATUS = "VIEW_ALL_STATUS";
export const SET_DOC = "SET_DOC";
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const VIEW_USER_PROFILE = "VIEW_USER_PROFILE";
export const SET_HOME_TRUE = "SET_HOME_TRUE";
export const SET_ORG_OWN = "SET_ORG_OWN";



// export const VIEW_ALL_PROJECT_TYPE = "VIEW_ALL_PROJECT_TYPE";

const firebaseConfig = {
  apiKey: "AIzaSyAJ2UYXylGCOfjqpIIGWlNSM2fDZ4jGmYo",
  authDomain: "flowtracker-d4465.firebaseapp.com",
  projectId: "flowtracker-d4465",
  storageBucket: "flowtracker-d4465.appspot.com",
  messagingSenderId: "918634594362",
  appId: "1:918634594362:web:ec4675ad2f272f2a9fb503",
  measurementId: "G-8WWFZ2Y2W3"
};
export const app = initializeApp(firebaseConfig);
// export const fb = firebase.initializeApp(firebaseConfig);