import React, { Component } from 'react';
import LOGO from "../../pages/image/TMS.svg";
import Profilimg from "../../pages/image/Prof.svg";
import Dashboradicon from "../../pages/image/element-2.svg";
import Bdashboard from "../../pages/image/element-bold.png";
import Breception from "../../pages/image/receipt-2-b.png";
import Buser from "../../pages/image/profile-bold.png";
import Settingicon from "../../pages/image/setting-2.svg";
import Charticon from "../../pages/image/chart.svg";
import Receipticon from "../../pages/image/receipt-2.svg";
import Usericon from "../../pages/image/profile-2user.svg";
import FeedbackIcon from '@mui/icons-material/Feedback';
// import SupportImg from "../image/support.png";
import Divider from '@mui/material/Divider';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Grid, Box, Typography, Button, Tooltip, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import "../navbar/navbar.css";
import Avatar from '@mui/material/Avatar';
import { ImCross } from "react-icons/im";
import { Dialog, Card, TextField } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
// import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Textarea from '@mui/joy/Textarea';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';




class navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {

            isHidden: true,
            isHidden2: true,
            isHidden3: true,
            isHidden4: true,
            isHidden5: true,
            isHidden6: true,

            activeIndex: localStorage.getItem("newNumber")

        }

        // this.handleEmailClick = this.handleEmailClick.bind(this)
        // this.handleButtonClick = this.handleButtonClick.bind(this);
        // this.handleCallButtonClick = this.handleCallButtonClick.bind(this)
    }

    updateAndStoreNumber = (newNumber) => {
        // Update the component state
        this.setState({ activeIndex: newNumber });

        // Store the number in localStorage
        localStorage.setItem('newNumber', newNumber.toString());
    };

    toggleElement = () => {
        this.setState((prevState) => ({
            isHidden: !prevState.isHidden,
            activeIndex: 0
        }));
    };
    toggleElement2 = () => {
        this.setState((prevState) => ({
            isHidden2: !prevState.isHidden2,
            activeIndex: 1
        }));
    };
    toggleElement3 = () => {
        this.setState((prevState) => ({
            isHidden3: !prevState.isHidden3,
            activeIndex: 2
        }));
    };

    toggleElement4 = () => {
        this.setState((prevState) => ({
            isHidden4: !prevState.isHidden4,
            activeIndex: 3
        }));
    };
    toggleElement5 = () => {
        this.setState((prevState) => ({
            isHidden5: !prevState.isHidden5,
            activeIndex: 4
        }));
    };

    handleEmailClick = () => {
        const emailAddress = 'no-reply@technoboot.in';
        const subject = 'Required Support on Task';
        const body = 'Body of the email';

        // Construct the mailto link
        const mailtoLink = `mailto:${emailAddress}?subject=${subject}`;

        // Open the default email client
        window.location.href = mailtoLink;
    };
    handleCallButtonClick() {
        // Replace '123456789' with the desired phone number

        const mobile = '919692108848'
        // Create the tel URL with the phone number
        const telUrl = `tel:${mobile}`;

        // Initiate a phone call
        window.location.href = telUrl;
    }
    handleWhastApClick() {

        // Replace '123456789' with the desired WhatsApp number
        const mobile = '919692108848'

        // Create the WhatsApp API URL with the phone number
        const whatsappUrl = `https://wa.me/${mobile}`;
        // Open the WhatsApp chat window in a new tab
        window.open(whatsappUrl, '_blank');
    }
    handleButtonClick = (index) => {
        this.setState({ activeIndex: index });

    };

    componentDidMount() {
        this.props.view_user_profile(this.props.login.user_id)
    }
    render() {
        const buttons = ["Button 1", "Button 2", "Button 3"];
        const { activeIndex } = this.state
        return (
            <div >
                <Grid style={{ background: "#005555", display: "flex", justifyContent: "space-evenly", alignItems: "center", width: "100%" }}>
                    <Box style={{ display: "flex", gap: "22px", justifyContent: "content", alignItems: "center" }}>
                        <Link style={{ textDecoration: "none" }} to="/Dashboard">
                            <img src={LOGO} /></Link>

                        <Box style={{ marginTop: "-36px" }}>
                            <div style={{ marginTop: "25%", paddingTop: "10px", "display": "inline-block", "padding": "3px 6px", "color": "white", "borderRadius": "5px", "fontSize": "9px", "fontWeight": "bold", "textTransform": "uppercase", border: " 2px solid yellow", "letterSpacing": "1px", "boxShadow": "0 4px 6px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto Condensed, sans-serif" }}>
                                Experiment
                            </div>
                        </Box>
                    </Box>
                    <Box>
                        <ul className='main-list'>






                            <Link style={{ textDecoration: "none" }} to="/Dashboard" onClick={() => {
                                this.toggleElement()
                                this.updateAndStoreNumber(0)
                            }}>

                                <Grid style={{
                                    //   background-color: white !important;
                                    borderRadius: "50px",
                                    color: activeIndex == "0" ? "#005555" : "white",
                                    //   zIndex: 1,
                                    padding: "8px",
                                    backgroundColor: activeIndex == "0" ? "white" : null
                                }}

                                >
                                    <span ><img src={Dashboradicon} style={{ marginBottom: "-6px" }} /></span>

                                    {this.state.isHidden ?
                                        <>
                                            <span ><img src={Bdashboard} style={{ marginBottom: "-6px" }} /></span>
                                            <span style={{ paddingRight: "20px" }}> Dashboard </span>
                                        </>
                                        :
                                        <>
                                            <span><img src={Bdashboard} style={{ marginBottom: "-6px" }} /></span>
                                            <span style={{ paddingRight: "20px" }}> Dashboard </span>
                                        </>
                                    }

                                </Grid>


                            </Link>

                            <Link style={{ textDecoration: "none" }} to="/Spacemain" onClick={() => {
                                this.toggleElement2()

                                this.updateAndStoreNumber(1)
                            }}>

                                <Grid style={{
                                    //   background-color: white !important;
                                    borderRadius: "50px",
                                    color: activeIndex == "1" ? "#005555" : "white",
                                    //   zIndex: 1,
                                    padding: "8px",
                                    backgroundColor: activeIndex == "1" ? "white" : null
                                }}

                                >
                                    <span><img src={Receipticon} style={{ marginBottom: "-6px" }} /></span>

                                    {this.state.isHidden2 ?
                                        <>
                                            <span><img src={Breception} style={{ marginBottom: "-6px", marginRight: "5px" }} /></span>
                                            <span style={{ paddingRight: "20px" }}>Spaces</span>
                                        </>
                                        :
                                        <>
                                            <span><img src={Breception} style={{ marginBottom: "-6px", marginRight: "5px" }} /></span>
                                            <span style={{ paddingRight: "20px" }}>Spaces</span>
                                        </>
                                    }
                                    {/* <span style={{paddingRight:"20px"}}>Spaces</span> */}
                                </Grid>
                            </Link>


                            <Link style={{ textDecoration: "none" }} to="/user" onClick={() => {

                                this.toggleElement3()
                                this.updateAndStoreNumber(2)
                            }}>
                                <Grid style={{
                                    //   background-color: white !important;
                                    borderRadius: "50px",
                                    color: activeIndex == "2" ? "#005555" : "white",
                                    //   zIndex: 1,
                                    padding: "8px",
                                    backgroundColor: activeIndex == "2" ? "white" : null
                                }}

                                >

                                    <span><img src={Usericon} style={{ marginBottom: "-6px" }} /></span>

                                    {this.state.isHidden3 ?
                                        <>
                                            <span><img src={Buser} style={{ marginBottom: "-6px", marginRight: "5px" }} /></span>
                                            <span style={{ paddingRight: "20px" }}>User</span>
                                        </>
                                        :
                                        <>
                                            <span><img src={Buser} style={{ marginBottom: "-6px", marginRight: "5px" }} /></span>
                                            <span style={{ paddingRight: "20px" }}>User</span>
                                        </>
                                    }
                                    {/* <span style={{ paddingRight: "20px" }}>User</span> */}
                                </Grid>
                            </Link>






                            {/* <Link style={{textDecoration:"none"}} to="/analysis">
                            <Typography   className='list-item'><span><img src={Charticon} /></span>Analysis</Typography></Link> */}

                            {/* <Link style={{textDecoration:"none"}} to="/user">
                            <Typography   className='list-item'><span><img src={Settingicon} /></span>Setting</Typography></Link> */}
                        </ul>


                    </Box>
                    <Grid style={{ display: "flex", gap: "32px" }}>
                        <Tooltip title="Support">


                            <ContactSupportIcon
                                onClick={() => {
                                    this.setState({
                                        support: true,

                                    });


                                }}
                                style={{ fontSize: "28px", marginTop: "5px", color: "white", cursor: "pointer" }} /></Tooltip>
                        {/* <Button


                            style={{
                                borderRadius: "50px",
                              
                                // padding: "8px",
                                fontWeight: "400",

                                textTransform: "capitalize",
                                
                            }}
                        > */}
                        <Tooltip title="Feedback">
                            <FeedbackIcon
                                onClick={() => {
                                    this.setState({
                                        feedback: true,

                                    })
                                }}


                                style={{ fontSize: "25px", marginTop: "7px", color: "white", cursor: "pointer" }} /></Tooltip>

                        {/* Feedback */}
                        {/* </Button> */}





                        {/* <Box
                            onClick={() => {
                                this.setState({
                                    support: true,

                                });
                                this.toggleElement4()

                            }}







                        >
                            {this.state.isHidden4 ?


                                <Button



                                    style={{
                                        borderRadius: "50px",
                                        color: activeIndex == 3 ? "#005555" : "white",
                                        padding: "8px",
                                        textTransform: "capitalize",
                                        fontWeight: "400",
                                        backgroundColor: activeIndex == 3 ? "white" : null
                                    }}
                                >
                                    <ContactSupportIcon
                                        style={{ marginRight: "7px" }} />
                                    Support
                                </Button>
                                :
                                <Button


                                    style={{

                                        borderRadius: "50px",
                                        color: activeIndex == 3 ? "#005555" : "white",
                                        textTransform: "capitalize",
                                        padding: "8px",
                                        fontWeight: "400",

                                        backgroundColor: activeIndex == 3 ? "white" : null
                                    }}
                                >
                                    <ContactSupportIcon
                                        style={{ marginRight: "7px" }} />
                                    Support
                                </Button>

                            }

                        </Box> */}


                        {/* ................feedback nav................................. */}

                        {/* <Box

                            onClick={() => {
                                this.setState({
                                    feedback: true,

                                });
                                this.toggleElement5()

                            }}




                        // onClick={this.toggleElement5}
                        >
                            {this.state.isHidden5 ?
                                <Button


                                    style={{
                                        borderRadius: "50px",
                                        color: activeIndex == 4 ? "#005555" : "white",
                                        padding: "8px",
                                        fontWeight: "400",

                                        textTransform: "capitalize",
                                        backgroundColor: activeIndex == 4 ? "white" : null
                                    }}
                                >
                                    <FeedbackIcon


                                        style={{ marginRight: "5px", fontSize: "20px" }} />

                                    Feedback
                                </Button>
                                :
                                <Button


                                    style={{

                                        borderRadius: "50px",
                                        color: activeIndex == 4 ? "#005555" : "white",
                                        textTransform: "capitalize",
                                        padding: "8px",
                                        fontWeight: "400",

                                        backgroundColor: activeIndex == 4 ? "white" : null
                                    }}
                                >
                                    <FeedbackIcon
                                        style={{ marginRight: "5px", fontSize: "20px" }} />
                                    Feedback
                                </Button>

                            }

                        </Box> */}
                    </Grid>
                    <Box>
                        <Link to="/Myprofile"
                            onClick={() => {
                                localStorage.setItem('newNumber', 5);
                                this.setState({ activeIndex: 5 })
                            }}
                        >
                            <Avatar src={this.props.login.user_profile && this.props.login.user_profile.profile_pic} />

                        </Link>
                    </Box>
                </Grid>











                {/* ////////////////////////////support....................................... */}

                <Dialog
                    open={this.state.support} style={{}}
                    // onClose={() => {
                    //     this.setState({ support: false })
                    // }}
                >
                    <Grid style={{ height: "auto", width: "450px", marginBottom: "30px", borderRadius: "50px" }}>


                        <Grid style={{ display: "flex", flexDirection: "row" ,justifyContent:"space-between",marginTop: "20px", marginLeft: "30px" ,marginRight:"30px"}}>
                            <Typography style={{ fontSize: "15px", fontWeight: 600, }}>Support Information</Typography>
                            <ImCross
                                onClick={() => {
                                    this.setState({ support: false });

                                }}

                                style={{ fontSize: "15px", cursor: "pointer" }} /></Grid>
                        <Divider style={{ marginTop: "8px" }} />

                        <Grid style={{ marginTop: "5%", display: "flex", flexDirection: "row" }}>
                            <Avatar
                                src={this.props.login.user_profile && this.props.login.user_profile.profile_pic}
                                style={{ marginLeft: "7%", height: 60, width: 60 }}
                            >

                            </Avatar>
                            <Typography style={{ fontWeight: 600, marginTop: "15px", marginLeft: "20px" }}>
                                {this.props.login.user_profile && this.props.login.user_profile.name}
                            </Typography>
                        </Grid>


                        <Grid style={{ marginTop: "5%", marginLeft: "7%", }}>
                            <Button style={{ backgroundColor: "#1C352D", width: "100px" }} variant="contained" startIcon={<PhoneIcon />}
                                onClick={() => {
                                    this.handleCallButtonClick()
                                }}
                            >
                                Call
                            </Button>

                            <Button style={{ marginLeft: "20px", backgroundColor: "#1C352D", width: "100px" }} variant="contained" startIcon={<MailIcon />}
                                onClick={() => {
                                    this.handleEmailClick()
                                }}
                            >
                                Mail
                            </Button>

                            <Button style={{ marginLeft: "20px", backgroundColor: "#1C352D", width: "130px" }} variant="contained" startIcon={<WhatsAppIcon />}
                                onClick={() => {
                                    this.handleWhastApClick()
                                }}
                            >
                                WhatsApp
                            </Button>

                            {/* <Button style={{ marginLeft: "20px", backgroundColor: "#1C352D", width: "100px" }} variant="contained" startIcon={<ContactSupportIcon />}>
                                Request
                            </Button> */}

                        </Grid>
                    </Grid>











                </Dialog>






                {/* /////////feedbck............................................. */}

                <Dialog
                    open={this.state.feedback} style={{}}

                >
                    <Grid style={{ height: "auto", width: "450px", marginBottom: "40px", borderRadius: "50px" }}>

                        <Typography style={{ display: "flex", flexDirection: "row", fontSize: "20px", fontWeight: 600, marginTop: "20px", marginLeft: "30px", justifyContent: "space-between", marginRight: "30px", }}>Your Feedback
                            <ImCross
                                onClick={() => {
                                    this.setState({ feedback: false });

                                }}

                                style={{ fontSize: "15px", cursor: "pointer" }} /></Typography>


                        <Typography style={{ marginTop: "20px", fontSize: "15px", fontWeight: 500, marginLeft: "30px" }}>Name</Typography>
                        <TextField style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"

                        />



                        <Typography style={{ marginTop: "10px", fontSize: "15px", fontWeight: 500, marginLeft: "30px" }}>Phone Number</Typography>
                        <TextField type="number" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"

                        />

                        <Typography style={{ marginTop: "10px", fontSize: "15px", fontWeight: 500, marginLeft: "30px" }}>Email</Typography>
                        <TextField type="Email" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"

                        />
                        <Typography style={{ marginTop: "10px", fontSize: "15px", fontWeight: 500, marginLeft: "30px" }}>Description</Typography>
                        <Textarea style={{ marginLeft: "30px", width: "380px", marginTop: "10px", borderColor: "#B5E4D5", }}
                            name="Outlined" placeholder="Type in here…" variant="outlined"
                        // onChange={(e) => {
                        //     this.setState({ ads: e.target.value })
                        // }}
                        />


                        <Button
                            onClick={() => {
                                this.setState({ submitfeedback: true, feedback: false });

                            }}
                            variant='contained' style={{ marginTop: "30px", border: "1px solid gray", color: "white", backgroundColor: "#1C352D", marginLeft: "70%" }}>
                            Submit
                        </Button>




                    </Grid>
















                </Dialog>

                {/* CheckCircleIcon */}

                <Dialog
                    open={this.state.submitfeedback} style={{}}
                    onClose={() => {
                        this.setState({ submitfeedback: false })
                    }}
                >

                    <Grid style={{ height: "auto", width: "310px", marginBottom: "30px", borderRadius: "50px" }}>
                        <center>
                            <CheckCircleIcon style={{ color: "green", fontSize: 60, marginTop: "20px", }} />



                            <Typography style={{ color: "green", fontSize: "15px", fontWeight: 500, marginTop: "10px" }}>Thank You For Your Feedback !!</Typography>


                            <Button
                                onClick={() => {
                                    this.setState({ submitfeedback: false });

                                }}
                                variant='contained' style={{ marginTop: "30px", border: "1px solid gray", color: "white", backgroundColor: "#1C352D", width: "100px" }}>
                                Close
                            </Button>
                        </center>
                    </Grid>
                </Dialog>
















            </div>
        );
    }
}

export default navbar;