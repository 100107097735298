import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../component/Emptask";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
 
  add_task,
  viewAlltaskBySpace,
  DeleteSpace,
  update_task,
  view_all_task_assign,
  stop_task,
  start_time,
  mark_as_complete,
  deleteTask
} from "../action";

export class Controller extends Component {
  render() {
    return (
      <Service {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    task: store.task,
    space: store.space,
    snackbar: store.snackbar,
    login: store.login,


  };
};
export const mapDispatchToProps = dispatch => {
  return {
    add_task:(space_id, task_name, desc, start_date, dead_line, assign_to, assign_by, total_time)  =>
       {
      dispatch(add_task(space_id, task_name, desc, start_date, dead_line, assign_to, assign_by, total_time) )
    },
    stop_task:(stop_by, task_id, space_id)  =>
       {
      dispatch(stop_task(stop_by, task_id, space_id) )
    },
    start_time:(start_by, task_id, space_id)  =>
       {
      dispatch(start_time(start_by, task_id, space_id) )
    },
    mark_as_complete:(task_id, space_id, assign_to)  =>
       {
      dispatch(mark_as_complete( task_id, space_id, assign_to) )
    },
    update_task:(title, desc, staart_date, dead_line, status_id, priority_id, assign_to, notify_when_start, task_type, attachmnets, milestone_id
      )  =>
       {
      dispatch(update_task(title, desc, staart_date, dead_line, status_id, priority_id, assign_to, notify_when_start, task_type, attachmnets, milestone_id
        ) )
    },
    view_all_task_assign:( space_id, user_id)  =>
       {
      dispatch(view_all_task_assign(space_id, user_id) )
    },
    viewAlltaskBySpace:( space_id)  =>
       {
      dispatch(viewAlltaskBySpace(space_id) )
    },
    DeleteSpace:(_id,space_id)  =>
       {
      dispatch(DeleteSpace(_id,space_id) )
    },
    deleteTask:(_id, space_id, assign_to)  =>
       {
      dispatch(deleteTask(_id, space_id, assign_to))
    },
    
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);