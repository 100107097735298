export const  VIEW_ALL_SPACE=" VIEW_ALL_SPACE";
export const  VIEW_ALL_MEMBERS=" VIEW_ALL_MEMBERS";
export const  SET_SPACE_ID=" SET_SPACE_ID";
export const  SEARCH_SPACE=" SEARCH_SPACE";
export const  SET_SPACE=" SET_SPACE";
export const  SET_MEMBER=" SET_MEMBER";
export const  SPACE_MEMBER=" SPACE_MEMBER";








