import {
    LOGIN,
    LOGOUT,
    SET_ALL_ORG,
    SET_ORG_ID,
    SET_ORG_DETAILS,
    SET_USER,
    SET_ORG,
    SET_ORG_USER_DEATILS,
    SET_USER_DETAILS,
    HANDLEDRAWER,
    SETMOBILE,
    CLEAR_LOGIN,
    SET_ALL_DATA,
    SET_ORG_USER_DETAILS,
    SET_ORG_DETAILS2,
    VIEW_ALL_STATUS,
    VIEW_ALL_PRIORITY,
    VIEW_ALL_DEPARTMENT,
    SET_DOC,
    SET_ORGANIZATIONS,
    VIEW_USER_PROFILE,
    SET_HOME_TRUE,
    SET_ORG_OWN
    // VIEW_ALL_PROJECT_TYPE
} from "./constant";
import UNIVERSAL from "../config/config";
import { setLoader, unsetLoader } from "../common/loader/action";
import { set_snack_bar } from "../common/snackbar/action";
import 'firebase/storage';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import useRazorpay from "react-razorpay";
var order_id = "";



export function login_auth(object_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch("https://sso-backend-zpicrijtna-el.a.run.app/v1/login_auth", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                object_id: object_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("otpppppppppppppppppppp", responseJson)
                if (responseJson.status) {
                    dispatch(setAdminLogin(responseJson.result));
                    var payload = {
                        type: responseJson.result.access_details.type,
                        org_id: responseJson.result.access_details.org_id
                    }
                    dispatch(setAccessResult(payload));
                    dispatch({ type: SET_ORG_DETAILS2, payload: responseJson.result.org_details });
                    dispatch(set_snack_bar(true, responseJson.message));
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function setAccessResult(payload) {
    // console.log(payload.user_name)
    localStorage.setItem("type", payload.type);
    localStorage.setItem("org_id", payload.org_id);
    return {
        type: SET_ORG,
        payload: payload,
    };
}


export function googleLogin() {
    return (dispatch) => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        // firebase
        // .auth()
        signInWithPopup(auth, provider)
            .then((res) => {
                // ;
                let payload = {
                    name: res.user.displayName,
                    email: res.user.email,
                    mobile: res.user.phoneNumber,
                    profile_pic: res.user.photoURL
                };
                dispatch(social_login(payload));
                // ;
            })
            .catch((error) => {
                ;
            });
    };
}

export function facebookLogin() {
    return (dispatch) => {
        const provider = new FacebookAuthProvider();
        const auth = getAuth();
        // firebase
        //   .auth()
        signInWithPopup(auth, provider)
            .then((res) => {
                // var credential = res.credential;

                // The signed-in user info.
                var user = res.user;
                let payload = {
                    name: user.displayName,
                    email: user.email,
                    mobile: user.phoneNumber,
                    profile_pic: user.photoURL

                };
                dispatch(social_login(payload));

            })
            .catch((error) => {
                ;
                if (error.email != null || error.email !== "") {
                    let payload = {
                        email: error.email,
                    };
                }

            });
    };
}
export function uploadOrgLogo(org_logo, org_name, org_email, org_num, org_gstno, user_id) {
    return dispatch => {
        dispatch(setLoader(true));
        if (org_logo !== "") {
            const storageRef = ref(getStorage(), "/org/" + org_logo.name + ".png")
            const uploadimage = uploadBytesResumable(storageRef, org_logo);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {

                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (org_logo) {

                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // 
                            dispatch(createOrg(org_logo, org_name, org_email, org_num, org_gstno, user_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(createOrg(org_logo, org_name, org_email, org_num, org_gstno, user_id));
        }
    }

}

export function createOrg(org_logo, org_name, org_email, org_num, org_gstno, user_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "create_organization", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                org_logo: org_logo,
                org_name: org_name,
                org_email: org_email,
                org_num: org_num,
                org_gstno: org_gstno,
                user_id: user_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAccessByUser(user_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(viewAccessByUser(user_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    }
}
export function create_new_access(user_id, org_id, product) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "create_new_access", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: user_id,
                org_id: org_id,
                product: product,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAccessByUser(user_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(viewAccessByUser(user_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    }
}

export function viewUserOrgDetails(user_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_organization_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: user_id,
                org_id: org_id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // 
                if (responseJson.status) {
                    dispatch(setUserType(responseJson.result))
                    dispatch({ type: SET_ORG_USER_DETAILS, payload: responseJson.result });
                    // dispatch({ type: SET_ORG, payload: responseJson.result.org_result });
                } else {
                    dispatch({ type: SET_ORG_USER_DETAILS, payload: [] });
                    // dispatch({ type: SET_ORG, payload: {}})
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_owned_org(user_id, product) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_owned_org", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: user_id,
                product: product,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // 
                if (responseJson.status) {
                    dispatch({ type: SET_ORG_OWN, payload: responseJson.result });
                    // dispatch({ type: SET_ORG, payload: responseJson.result.org_result });
                } else {
                    dispatch({ type: SET_ORG_OWN, payload: [] });
                    // dispatch({ type: SET_ORG, payload: {}})
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}



export function viewAccessByUser(user_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_access_by_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: user_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: SET_ORGANIZATIONS, payload: responseJson.result });
                    dispatch(set_snack_bar(true, responseJson.message));
                } else {
                    dispatch({ type: SET_ORGANIZATIONS, payload: [] });
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function social_login(login) {
    // ;
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "social_login", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: login.email,
                name: login.name,
                mobile: login.mobile,
                profile_pic: login.profile_pic,
                //   type: "U",
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                //   ;
                if (responseJson.status) {
                    dispatch(setAdminLogin(responseJson.result));
                    dispatch(set_snack_bar(true, responseJson.message));
                    // }
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function phone_login(mobile) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "phone_login", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                mobile: mobile,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    ;
                    dispatch({ type: SETMOBILE, payload: responseJson.result.mobile });
                    dispatch(set_snack_bar(true, responseJson.message));
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function payement_razorpay() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "create-order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ amount: "50000" }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(confirmOrder(responseJson.result.id));
                    dispatch({ type: SET_ORG_DETAILS2, payload: responseJson.result });
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function confirmOrder(order_id) {
    return (dispatch) => {
        // const [Razorpay] = useRazorpay();
        var option = {
            key_id: "rzp_live_ShsSitka8ryWRM", // Enter the Key ID generated from the Dashboard
            amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Acme Corp",
            description: "Test Transaction",
            // image: "https://example.com/your_logo",
            order_id: order_id, // This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            // prefill: {
            //     name: "Piyush Garg",
            //     email: "youremail@example.com",
            //     contact: "9999999999",
            // },
            notes: {
                address: "Razorpay Corporate Office",
            },
            // theme: {
            //     color: "#3399cc",
            // },
        };
        // const rzp1 = new Razorpay(option);
        var rzp1 = new window.Razorpay(option)

        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });

        rzp1.open();
    };
}
// export function setUserDetails(payload) {
//   return {
//     type: SETMOBILE,
//     payload: payload,
//   };
// }

export function verify_otp(email, otp) {
    return (dispatch) => {
        ;

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "verify_otp", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                otp: parseInt(otp),
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(setAdminLogin(responseJson.result));
                    ;
                    dispatch(set_snack_bar(true, responseJson.message));
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// export function super_login(org_id, email, password) {
//   return (dispatch) => {
//     dispatch(setLoader());
//     // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
//     return fetch(UNIVERSAL.BASEURL + "login", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         org_id: org_id,
//         email: email,
//         password: password,
//         // type: type
//       }),
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         // 
//         if (responseJson.status) {
//           // <redirect to="/"/>
//           // 
//           dispatch(setAdminLogin(responseJson.result));
//           // dispatch(setOrgId(responseJson.result.org_id));
//           // ;
//           // ;
//           dispatch(set_snack_bar(responseJson.status, "Login  Successfully"));
//           dispatch(unsetLoader());
//         } else {
//           dispatch(set_snack_bar(responseJson.status, "Invalid User"));
//         }
//         dispatch(unsetLoader());
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };
// }

export function clear_login() {
    return {
        type: CLEAR_LOGIN,
    };
}


export function setAdminLogin(payload) {



    localStorage.setItem("user_id", payload._id);
    localStorage.setItem("mobile", payload.mobile);
    localStorage.setItem("email", payload.email_id);
    localStorage.setItem("name", payload.name);

    return {
        type: LOGIN,
        payload: payload,
    };
}
export function setUserType(payload) {
    // 
    localStorage.setItem("type", payload.type);
    localStorage.setItem("org_id", payload.org_id);

    return {
        type: SET_ORG,
        payload: payload,
    };
}

export function handleDrawerToggle(mobileOpen) {
    mobileOpen = !mobileOpen;
    return {
        type: HANDLEDRAWER,
        payload: mobileOpen,
    };
}
export function onLogout() {
    localStorage.removeItem("user_id");
    localStorage.removeItem("mobile");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("type");
    localStorage.removeItem("org_id");
    return {
        type: LOGOUT,
    };
}
export function view_all_status(org_id) {
    return (dispatch) => {
        // 
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_status", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_STATUS, payload: responseJson.result })
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_STATUS, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_all_priority(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_priority", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_PRIORITY, payload: responseJson.result })
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_PRIORITY, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_all_department(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_department", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_DEPARTMENT, payload: responseJson.result })
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_DEPARTMENT, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_user_profile(user_id) {
    return (dispatch) => {
        // 
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_user_profile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                user_id: user_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {

                    dispatch({ type: VIEW_USER_PROFILE, payload: responseJson.result })
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_USER_PROFILE, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}




export function setOrgId(org_id) {
    localStorage.setItem("org_id", org_id);
    return {
        type: SET_ORG_ID,
        payload: org_id,
    };
}
export function switchHome() {
    localStorage.removeItem("type");
    localStorage.removeItem("org_id");
    return {
        type: SET_HOME_TRUE,
        payload: false,
    };
}
export function Docview(doc) {
    return {
        type: SET_DOC,
        payload: doc,
    };
}