import React, { Component } from "react";
// import firebase from "firebase";
// import { firebase_config } from "../../constants/ActionTypes";

export default class LoginController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      page: ""
    };
  }

  componentDidMount() {
    // 
    if (localStorage.getItem("user_id") !== null) {
      // 
      this.props.setAdminLogin({
        _id: localStorage.getItem("user_id"),
        mobile: localStorage.getItem("mobile"),
        email_id: localStorage.getItem("email"),
        name: localStorage.getItem("name"),
      });
      
    }
    if (localStorage.getItem("org_id")) {
      // 
      this.props.setUserType({
        type: localStorage.getItem("type"),
        org_id: localStorage.getItem("org_id"),
      });
      this.props.setAccessResult({
        type: localStorage.getItem("type"),
        org_id: localStorage.getItem("org_id"),
      }) 
    }
  }

  render() {
    return (
      <div />
    );
  }
}
