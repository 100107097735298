import {

    VIEW_ALL_SPACE,
    VIEW_ALL_MEMBERS,
    SET_SPACE_ID,
    SEARCH_SPACE,
    SET_SPACE,
    SET_MEMBER,
    SPACE_MEMBER


} from "./constant";

import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";
import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";




export function viewAllSpaces(org_id, page_number, rowper_page,) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_space", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
                page_number: page_number,
                rowper_page: rowper_page
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                
                if (responseJson.status) {

                    // dispatch(setAdminLogin(responseJson.result.userdetails));
                    dispatch({ type: VIEW_ALL_SPACE, payload: responseJson });
                    
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader(responseJson.result.item_category));
                } else {
                    dispatch({ type: VIEW_ALL_SPACE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewSpaceMember(members) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_space_members2", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                members: members
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setAdminLogin(responseJson.result.userdetails));.

                    dispatch({ type: SPACE_MEMBER, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader(responseJson.result.item_category));
                } else {
                    dispatch({ type: SPACE_MEMBER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function view_all_space_asign(user_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_space_asign", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                user_id: user_id,
            },


        })
            .then((response) => response.json())
            .then((responseJson) => {

                ;

                if (responseJson.status) {

                    // dispatch(setAdminLogin(responseJson.result.userdetails));
                    dispatch({ type: VIEW_ALL_SPACE, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader(responseJson.result.item_category));
                } else {
                    dispatch({ type: VIEW_ALL_SPACE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAllMembers(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_members", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // org_id: org_id,
            },
            body: JSON.stringify({
                org_id: org_id,



            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {

                    // dispatch(setAdminLogin(responseJson.result.userdetails));
                    dispatch({ type: VIEW_ALL_MEMBERS, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader(responseJson.result.item_category));
                } else {
                    dispatch({ type: VIEW_ALL_MEMBERS, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function addSpaces(org_id, space_name, members, desc,) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_space", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                org_id: org_id,
                space_name: space_name,
                members: members,
                desc: desc,


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // ;
                    dispatch(viewAllSpaces(org_id, 0, 5));
                } else {
                    dispatch(viewAllSpaces(org_id, 0, 5));
                }
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function addMember(org_id, name, email_id, mobile, access) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_members", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                org_id: org_id,
                name: name,
                email_id: email_id,
                mobile: mobile,
                access: access


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // ;
                    dispatch(viewAllMembers(org_id));
                } else {
                    dispatch(viewAllMembers(org_id));
                }
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function updateSpace(_id, space_name, members, desc, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_space", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
                space_name: space_name,
                members: members,
                desc: desc
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllSpaces(org_id, 0, 5));
                } else {
                    dispatch(viewAllSpaces(org_id, 0, 5));
                }
                // dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteSpace(_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_space", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                
                if (responseJson.status) {
                    dispatch(viewAllSpaces(org_id, 0, 5));
                } else {
                    dispatch(viewAllSpaces(org_id, 0, 5));
                }
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function deleteMember(_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_members", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllMembers(org_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(viewAllMembers(org_id));
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function updateMember(_id, name, email_id, mobile, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_members", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
                name: name,
                email_id: email_id,
                mobile: mobile
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllMembers(org_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function SetSpace(payload) {

    return {
        type: SET_SPACE,
        payload: payload,
    };
}

export function searchSpace(space, e) {
    return (dispatch) => {


        if (e === "") {
            dispatch({ type: SEARCH_SPACE, payload: space });

        }
        else {
            const newArray = space.filter((el) => {
                if (el.space_name !== null) {
                    return ((el.space_name).toString().toLowerCase().includes((e).toString().toLowerCase()));
                }
            })

            dispatch({ type: SEARCH_SPACE, payload: newArray })

        }
    };
}

export function add_member_space(space_id, member_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_member_space", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                space_id: space_id,
                member_id: member_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_space(space_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(view_space(space_id));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function view_space(space_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_space", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                space_id: space_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {

                    // dispatch(setAdminLogin(responseJson.result.userdetails));
                    dispatch({ type: SET_SPACE, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader(responseJson.result.item_category));
                } else {
                    dispatch({ type: SET_SPACE, payload: { user_details: [] } })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function SetMember(payload) {

    return {
        type: SET_MEMBER,
        payload: payload,
    };
}