import React, { Component } from 'react'
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineDown } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { ImCross } from "react-icons/im";
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography, DialogContent, Tooltip, MenuItem,
} from '@mui/material';
import edit from "../../image/edit.png";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
// import "../../../../pages/admin/image/edit.png";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import StopOutlinedIcon from '@mui/icons-material/StopOutlined';
// import "../Component/Header.scss"
// import "../Component/Projectpage.scss"
// import Searchbar from './Searchbar/Searchbar';
import { Link } from "react-router-dom"
// import "../../Project/component/Empproject.scss";
export default class Emptask extends Component {
    constructor(props) {
        super(props);

        this.state = {

            member: " ",
            task_name: "",
            task_startdate: "",
            end_date: "",
            total_hour: "",
            task_desc: "",
            addFormOpen: false,
            addDeleteOpen: false,
            isRunning: false,
            seconds: 0,
            minutes: 0,
            hours: 0,
            setindex: "",
        };
        this.intervalId = null;
    }
    handelOpen = () => {
        this.setState({
            addFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            addFormOpen: false,

        })
    }
    handleDeleteOpen = () => {
        this.setState({
            addDeleteOpen: true
        })
    }

    deleteModalOff = () => {
        this.setState({
            addDeleteOpen: false
        })
    }


    handleClick = () => {
        this.setState(prevState => ({
            open: !prevState.open,
        }));
    };
    handleClicks = () => {
        this.setState(prevState => ({
            opens: !prevState.opens,
        }));
    };

    handleStart() {
        if (!this.state.isRunning) {
            this.setState({
                isRunning: true,
                startTime: Date.now() - this.state.elapsedTime,
            });

            this.interval = setInterval(() => {
                this.setState({
                    elapsedTime: Date.now() - this.state.startTime,
                });
            }, 100);
        }
    }
    startTimer = () => {
        if (!this.state.isRunning) {
            this.setState({ isRunning: true });
            this.intervalId = setInterval(this.updateTime, 1000); // Update every 1 second (1000ms)
        }
    };

    stopTimer = () => {
        if (this.state.isRunning) {
            this.setState({ isRunning: false });
            clearInterval(this.intervalId);
        }
    };
    updateTime = () => {
        const { seconds, minutes, hours } = this.state;
        if (seconds === 59) {
            if (minutes === 59) {
                this.setState({
                    seconds: 0,
                    minutes: 0,
                    hours: hours + 1,
                });
            } else {
                this.setState({
                    seconds: 0,
                    minutes: minutes + 1,
                });
            }
        } else {
            this.setState({
                seconds: seconds + 1,
            });
        }
    };
    componentDidMount() {
        if (this.props.login.type === "Viewer") {
            this.props.view_all_task_assign(this.props.space.space_id._id, this.props.login.user_id)
        }
        else {
            this.props.viewAlltaskBySpace(this.props.space.space_id._id);
        }
        // 
    }

    render() {
        // 
        const { open } = this.state;
        const { opens } = this.state;
        const { isRunning, seconds, minutes, hours } = this.state;
        return (
            <div style={{ backgroundColor: "#001e00", height: "100vh" }}>

                <div style={{ backgroundColor: "black" }}>

                    {/* <Paper
                        component="form"
                        style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none" }}
                    >
                        <IconButton
                            type="button"
                            style={{ p: "15px" }}
                            aria-label="search"
                        >
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            style={{ ml: 5, flex: 1, fontSize: "15px" }}
                            placeholder="Search here"
                            inputProps={{ "aria-label": "Search here" }}
                        />
                    </Paper> */}

                </div>


                <CardContent style={{ marginLeft: "0%" }}>
                    <TableContainer component={Paper} >
                        <div style={{ display: "flex", flexdirection: "row", padding: 10, backgroundColor: "#001e00", border: "1px solid white" }}>

                            <h2 style={{ marginLeft: "2%", color: "white" }}>  Task</h2>
                            {this.props.login.type !== "Viewer" &&
                                <Button

                                    onClick={() => {
                                        this.setState({
                                            addTask: true,

                                        })
                                    }}
                                    style={{ marginLeft: "auto", height: "40px", color: "white", marginTop: "1.5%", border: "1px solid white" }} variant="outlined" > Add Task </Button>
                            }
                        </div>




                        <Table style={{ minWidth: 600, marginTop: "2%" }} size="small" aria-label="a dense table">
                            <TableHead >
                                <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                    <TableCell style={{ fontSize: "16px" }}>Sl No.</TableCell>
                                    <TableCell style={{ fontSize: "16px" }}>Task Name</TableCell>
                                    <TableCell style={{ fontSize: "16px" }}>Start Date</TableCell>
                                    <TableCell style={{ fontSize: "16px" }}>Total Hours</TableCell>
                                    <TableCell style={{ fontSize: "16px" }}>Given By</TableCell>
                                    <TableCell style={{ fontSize: "16px" }}>Deadline</TableCell>
                                    <TableCell style={{ fontSize: "16px" }}>Status</TableCell>
                                    <TableCell style={{ fontSize: "16px" }}></TableCell>
                                    {/* <TableCell style={{ fontSize: "16px" }}></TableCell> */}
                                </TableRow>

                            </TableHead>
                            {Array.isArray(this.props.task.all_task) && this.props.task.all_task.map((item, index) => {
                                return (
                                    <TableRow style={{}} >
                                        <TableCell style={{ fontSize: "16px" }}>{index + 1}</TableCell>
                                        <TableCell style={{ fontSize: "16px" }}
                                            onClick={() => {
                                                this.setState({ tsk_details: item.task_name, viewTask: true })
                                            }}
                                        >{item.task_name.substring(0, 20) + "......."}</TableCell>
                                        <TableCell style={{ fontSize: "16px" }}>{item.start_date}</TableCell>
                                        <TableCell style={{ fontSize: "16px" }}>{item.total_time}</TableCell>
                                        <TableCell style={{ fontSize: "16px" }}>{item.assign_by_detail != undefined && item.assign_by_detail.name}</TableCell>

                                        <TableCell style={{ fontSize: "16px" }}>{item.dead_line}</TableCell>
                                        <TableCell style={{ fontSize: "16px" }}>
                                            <Button style={{
                                                backgroundColor: item.status === 'notstarted' ? 'rgba(255, 84, 84, 0.40)' : item.status === 'start' ? 'rgba(252, 255, 84, 0.40)' : item.status === 'stop' ? 'rgba(84, 140, 255, 0.40)': 'rgba(255, 84, 217, 0.40)' ,
                                                border: item.status === 'notstarted' ? '0.4px solid #C91E1E' : item.status === 'start' ? '0.4px solid #C9B81E' :item.status === 'stop'? '0.4px solid #1E57C9':'0.4px solid #C91EA3' ,
                                                color: item.status === 'notstarted' ? '#C91E1E' : item.status === 'start' ? '#C9B81E' : item.status === 'stop'?'#1E57C9':'#C91EA3'
                                            }}
                                                variant="outlined" >
                                                <RxDotFilled />
                                                {item.status === 'notstarted' ? "Not-Start":item.status === 'start'?"Start": item.status === 'stop'? "Stop":"Completed"}
                                            </Button>

                                        </TableCell>
                                        <TableCell style={{ fontSize: "16px" }}>

                                            {open ? <ExpandLess
                                                onClick={(e) => {
                                                    
                                                    this.handleClick("panel1", e, !this.state.open,)
                                                    this.setState({ setindex: index, click_count: this.state.click_count + 1, })
                                                    // this.viewSomething(this.state.click_count, index);
                                                    // this.props.view_lession_by_subject_id(item._id)
                                                }
                                                }
                                                // onClick={this.handleClick} 
                                                style={{ color: "black", fontSize: "30px", }} /> : <ExpandMore style={{ color: "black", fontSize: "30px", }}
                                                    //  onClick={this.handleClick} 
                                                    onClick={(e) => {
                                                        
                                                        this.handleClick("panel1", e, !this.state.open,)
                                                        this.setState({ setindex: index, click_count: this.state.click_count + 1, })
                                                        // this.viewSomething(this.state.click_count, index);
                                                        // this.props.view_lession_by_subject_id(item._id)
                                                    }
                                                    }
                                            />}
                                            <Button style={{ marginTop: -15 }} onClick={() => {
                                                this.setState({
                                                    editTask: true,

                                                })
                                            }}
                                            >
                                                <img style={{ color: "green", width: 25 }} src={edit} /> </Button>
                                            <Tooltip title="delete" style={{ marginTop: -15 }}>
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            delete: true,
                                                            task_id: item._id

                                                        })
                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon style={{ color: "red" }} />

                                                </IconButton>
                                            </Tooltip>
                                            {open && this.state.setindex === index && <Grid style={{ marginBottom: "2%", display: "flex", flexDirection: "row" }} gap={10}>



                                                <FormControl


                                                    sx={{
                                                        '& > :not(style)': { width: '253px', height: '33px', marginLeft: "5%", marginTop: "10px" },
                                                    }}>
                                                    {/* <OutlinedInput


            type="number"
            value={Math.floor(this.state.elapsedTime / 1000)}

        /> */}
                                                    <div style={{ marginBottom: "5%" }}>
                                                        <p style={{ border: "1px solid black", padding: 10, borderRadius: 4 }}>
                                                            {hours < 10 ? `0${hours}` : hours}:
                                                            {minutes < 10 ? `0${minutes}` : minutes}:
                                                            {seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    </div>


                                                </FormControl>
                                                <Grid style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>

                                                    <Button style={{ marginTop: "10px", backgroundColor: "#3CE72E", width: '93px', height: '33px', }} variant="contained" startIcon={<PlayArrowOutlinedIcon />} onClick={() => {
                                                        this.props.start_time(this.props.login.user_id, item._id,this.props.space.space_id._id )
                                                        this.startTimer()
                                                    }} disabled={isRunning || item.status === "completed" || item.status === 'start'} >Start</Button>


                                                    <Button style={{ marginLeft: "2%", marginTop: "10px", backgroundColor: "#F81414", width: '93px', height: '33px', }} variant="contained" startIcon={<StopOutlinedIcon />} onClick={() => {
                                                        this.props.stop_task(this.props.login.user_id, item._id, this.props.space.space_id._id)
                                                        this.stopTimer()
                                                    }} disabled={isRunning || item.status === "completed" || item.status === 'stop'}>Stop</Button>
                                                    <Button style={{ marginLeft: "2%", marginTop: "10px", backgroundColor: "#1E57C9", width: '193px', height: '33px' }} variant="contained" onClick={() => {
                                                        this.props.mark_as_complete(item._id, this.props.space.space_id._id, this.props.login.user_id)
                                                    }} disabled={item.status === "completed"} >Mark as complete</Button>

                                                </Grid>







                                            </Grid>
                                            } {open ? '' : ''}

                                        </TableCell>
                                    </TableRow>
                                )
                            })}



                        </Table>
                    </TableContainer>

                </CardContent>






                {/* <Dialog
                    open={this.state.addTask}
                    fullWidth
                    maxWidth="xs"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{

                        backdropFilter: "blur(5px) sepia(5%)",
                        // 👇 Another option to style Paper
                        "& .MuiDialog-paper": {
                            borderRadius: "17.782px",
                        },
                    }}
                >
                    <Grid>
                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                            <Grid container>
                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Task</Typography></Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => {
                                        this.setState({ addTask: false });
                                    }}>
                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={8}></Grid>
                                        <Grid item xs={8}>
                                            <Typography style={{ fontSize: 'large' }}>
                                                Task Name<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                size='small'
                                                fullWidth
                                                onChange={(e) => { this.setState({ name: e.target.value }) }}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>




                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>

                            <Button
                                disabled={(this.state.name === '') || (this.state.comp_no === '') || (this.state.contact_no === '') || (this.state.email === '') || (this.state.fix_Amount === '')}
                                onClick={() => {
                                    this.setState({ addTask: false, name: "", owner_name: "", company_shortid: "", contact_no: "", email: "", gst_num: "", fix_Amount: "", address: "", attachment: "" });

                                    this.props.add_upload_company(this.props.login.org_details2._id, this.state.name, this.state.owner_name, this.state.comp_no, this.state.contact_no, this.state.email, this.state.gst_num, this.state.fix_Amount, this.state.address, this.state.attachment);
                                }}
                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                Add Task
                            </Button>
                        </DialogActions>

                    </Grid>
                </Dialog > */}
                <Dialog
                    open={this.state.viewTask}
                    fullWidth
                    maxWidth="xs"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{

                        backdropFilter: "blur(5px) sepia(5%)",
                        // 👇 Another option to style Paper
                        "& .MuiDialog-paper": {
                            borderRadius: "17.782px",
                        },
                    }}
                >
                    <Grid>
                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                            <Grid container>
                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Task Details</Typography></Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => {
                                        this.setState({ viewTask: false });
                                    }}>
                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Typography>
                                                {this.state.tsk_details}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>

                    </Grid>
                </Dialog >

                <Dialog
                    open={this.state.editTask}
                    fullWidth
                    maxWidth="xs"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{

                        backdropFilter: "blur(5px) sepia(5%)",
                        // 👇 Another option to style Paper
                        "& .MuiDialog-paper": {
                            borderRadius: "17.782px",
                        },
                    }}
                >
                    <Grid>
                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                            <Grid container>
                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Chnage Task</Typography></Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => {
                                        this.setState({ editTask: false });
                                    }}>
                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={8}></Grid>
                                        <Grid item xs={8}>
                                            <Typography style={{ fontSize: 'large' }}>
                                                Task Name<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                size='small'
                                                fullWidth
                                                onChange={(e) => { this.setState({ name: e.target.value }) }}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>




                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>

                            <Button
                                disabled={(this.state.name === '') || (this.state.comp_no === '') || (this.state.contact_no === '') || (this.state.email === '') || (this.state.fix_Amount === '')}
                                onClick={() => {
                                    this.setState({ editTask: false, name: "", owner_name: "", company_shortid: "", contact_no: "", email: "", gst_num: "", fix_Amount: "", address: "", attachment: "" });

                                    this.props.add_upload_company(this.props.login.org_details2._id, this.state.name, this.state.owner_name, this.state.comp_no, this.state.contact_no, this.state.email, this.state.gst_num, this.state.fix_Amount, this.state.address, this.state.attachment);
                                }}
                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                Edit Task
                            </Button>
                        </DialogActions>

                    </Grid>
                </Dialog >


                <Dialog
                    open={this.state.delete}
                    onClose={() => {
                        this.setState({ delete: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%" }}
                >


                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                        <center>
                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                        </center>
                        <DialogActions style={{ marginTop: "40px" }}>

                            <Button variant="contained"
                                style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}

                                onClick={() => { this.setState({ delete: false }) }}>

                                Cancel
                            </Button>
                            <Button variant="contained"
                                style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                onClick={() => {
                                    this.props.deleteSpace(this.state.space_id, this.props.login.org_details2._id)

                                    this.setState({ delete: false });
                                }}

                            >

                                Confirm
                            </Button>


                        </DialogActions>
                    </Grid>






                </Dialog>


                <Dialog
                    open={this.state.addTask}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{

                        backdropFilter: "blur(5px) sepia(5%)",
                        // 👇 Another option to style Paper
                        "& .MuiDialog-paper": {
                            borderRadius: "17.782px",
                        },
                    }}
                >
                    <Grid>
                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                            <Grid container>
                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Task</Typography></Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => {
                                        this.setState({ addTask: false });
                                    }}>
                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={0.5}></Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{ fontSize: 'large' }}>
                                                Task Name<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size='small'
                                                fullWidth
                                                onChange={(e) => { this.setState({ task_name: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{ fontSize: 'large' }}>
                                                Member<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                size='small'
                                                fullWidth
                                                select
                                                placeholder='Select Priority'
                                                value={this.state.member}
                                                onChange={(e) => {
                                                    this.setState({ member: e.target.value })
                                                    
                                                }}
                                            >
                                                <MenuItem value={" "}>{"Select Members"}</MenuItem>
                                                {Array.isArray(this.props.space.space_id.users) && this.props.space.space_id.users.map((e) => (
                                                    <MenuItem value={e._id}>{e.name}</MenuItem>
                                                ))}


                                            </TextField>
                                        </Grid>
                                        <Grid item xs={0.5}></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction={"column"} spacing={2} mt={0.5}>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={0.5}></Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{ fontSize: 'large' }}>
                                                Start Date<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size='small'
                                                fullWidth
                                                type='date'
                                                onChange={(e) => this.setState({
                                                    task_startdate: e.target.value
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{ fontSize: 'large' }}>
                                                Dead Line<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size='small'
                                                fullWidth
                                                type='date'
                                                onChange={(e) => this.setState({
                                                    end_date: e.target.value
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={0.5}></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={0.5}></Grid>
                                        {/* <Grid item xs={2}>
                                            <Typography style={{ fontSize: 'large' }}>
                                                Maximum Hours :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size='small'
                                                fullWidth
                                                onChange={(e) => this.setState({
                                                    project_maxhr: e.target.value
                                                })}
                                            />
                                        </Grid> */}
                                        {/* <Grid item xs={1}></Grid> */}
                                        <Grid item xs={2}>
                                            <Typography style={{ fontSize: 'large' }}>
                                                Total Hour:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size='small'
                                                placeholder="In hours"
                                                fullWidth
                                                onChange={(e) => this.setState({
                                                    total_hour: e.target.value
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={0.5}></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={0.5}></Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{ fontSize: 'large' }}>
                                                Description :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField
                                                placeholder='Description'
                                                size='small'
                                                fullWidth
                                                multiline
                                                onChange={(e) => this.setState({
                                                    task_desc: e.target.value
                                                })}
                                                inputProps={{
                                                    style: {
                                                        height: 50,
                                                    },
                                                }}

                                            />
                                        </Grid>
                                        <Grid item xs={0.5}></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button
                                onClick={() => { this.setState({ addTask: false }) }}
                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                Cancel
                            </Button>
                            {this.props.login.type !== "Viewer" &&
                                <Button
                                    onClick={() => {
                                        this.props.add_task(this.props.space.space_id._id, this.state.task_name, this.state.task_desc, this.state.task_startdate, this.state.end_date, this.state.member, this.props.login.user_id, this.state.total_hour)
                                        this.setState({ addTask: false, task_name: "", task_desc: "", task_startdate: "", end_date: "", member: "", total_hour: "" })
                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Add Task
                                </Button>
                            }
                        </DialogActions>

                    </Grid>
                </Dialog >



            </div>
        )
    }
}

