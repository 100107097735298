import { Box, Button, Typography, Grid, Card, IconButton } from "@mui/material";
import React, { Component } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import InputBase from "@mui/material/InputBase";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
// import Search from '@mui/icons-material/Search';
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
// import Avatar from '@mui/material/Avatar';
import AvatarGroup from "@mui/material/AvatarGroup";
import fac from "../../../pages/image/facebook.png";
import clock from "../../../pages/image/clock.png";
import overdue from "../../../pages/image/overdue.png";
import pending from "../../../pages/image/pending.png";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from "moment";
import "../../../pages/User/Components/user.css";
import Loader from "../../../common/loader/containers/loader_cont";

const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  width: "80%",
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#4C6FFF" : "#4C6FFF",
  },
}));
const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  width: "80%",
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#2F5247" : "#2F5247",
  },
}));

// function createData(name, User_ID	, Status, Due_Tasks, protein) {
//   return { name, User_ID	, Status, Due_Tasks, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159,"active", 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      rows: [
        {
          Task_name: "Frozen yoghurt",
          Assignby: "Roji",
          AssignTo: "Soumya",
          Status: "Active",
          Remark: "Over due",
        },
        {
          Task_name: "Frozen yoghurt",
          Assignby: "Roji",
          AssignTo: "Soumya",
          Status: "Active",
          Remark: "Over due",
        },
        {
          Task_name: "Frozen yoghurt",
          Assignby: "Roji",
          AssignTo: "Soumya",
          Status: "Active",
          Remark: " Over due",
        },
        {
          Task_name: "Frozen yoghurt",
          Assignby: "Roji",
          AssignTo: "Soumya",
          Status: "Active",
          Remark: " Over due",
        },
        {
          Task_name: "Frozen yoghurt",
          Assignby: "Roji",
          AssignTo: "Soumya",
          Status: "Active",
          Remark: " Over due",
        },
        {
          Task_name: "Frozen yoghurt",
          Assignby: "Roji",
          AssignTo: "Soumya",
          Status: "Active",
          Remark: " Over due",
        },
        {
          Task_name: "Frozen yoghurt",
          Assignby: "Roji",
          AssignTo: "Soumya",
          Status: "Active",
          Remark: " Over due",
        },
      ],
    };
  }
  componentDidMount() {
    this.props.viewAlltaskOrg(this.props.login.org_id)
    this.props.view_all_org_space(this.props.login.org_id)

  }

  render() {
    const { rows } = this.state;
    const today = moment().format('YYYY-MM-DD');
    const taskArray = Array.isArray(this.props.task.all_task) && this.props.task.all_task.filter(item => item.start_date === today).slice(0, 5)
    return (
      <div className="bg-d" style={{ overflowX: "hidden" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "2em",
            gap: "10px",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "2em",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "210px",
                height: "107px",
                flexShrink: 0,
                backgroundColor: "white",
                borderRadius: " 8px",
              }}
            >
              {" "}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <img src={clock} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="In Progress" secondary={this.props.task.all_task.filter(e => (e.status === "start")).length} />
              </ListItem>
            </Box>
            <Box
              sx={{
                width: "210px",
                height: "107px",
                flexShrink: 0,
                backgroundColor: "white",
                borderRadius: " 8px",
              }}
            >
              {" "}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <img src={pending} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Pending" secondary={this.props.task.all_task.filter(e => (e.status === "notstarted")).length} />
              </ListItem>
            </Box>
            <Box
              sx={{
                width: "210px",
                height: "107px",
                flexShrink: 0,
                backgroundColor: "white",
                borderRadius: " 8px",
              }}
            >
              {" "}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <img src={overdue} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Overdue" secondary={this.props.task.all_task.filter(e => (e.status !== "completed" && moment().isAfter(e.dead_line))).length} />
              </ListItem>
            </Box>
          </Box>

          <Box
            sx={{
              padding: "40px",
              display: "flex",
              borderRadius: " 8px",
              backgroundColor: "white",
              gap: "3em",
              flexDirection: "column",
              width: "70%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "66em",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <Typography variant="h6" component="h2">
                  Today’s Task
                </Typography>

                {/* <Search style={{ border: "1px solid gray", width: "300px" }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search user.."
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search> */}
              </div>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <Button
                  style={{ color: "#2F5247", borderColor: "#2F5247" }}
                  variant="outlined"
                >
                  Daily
                </Button>
                <Button
                  style={{ color: "#2F5247", borderColor: "#2F5247" }}
                  variant="outlined"
                >
                  Weekly
                </Button>
                <Button
                  style={{ color: "#2F5247", borderColor: "#2F5247" }}
                  variant="outlined"
                >
                  Monthly
                </Button>
              </div> */}
            </Box>

            <Box>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 50, borderRadius: " 8px" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead
                    sx={{
                      padding: "24px 16px",
                      backgroundColor: "#B5E4D5",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "24px 45px",
                        }}
                        align="left"
                      >
                        Task
                      </TableCell>
                      <TableCell align="center">Assign By</TableCell>
                      <TableCell align="center">Assign To</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Remark</TableCell>
                      {/* <TableCell align="right">Due Tasks</TableCell> */}
                      {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskArray.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                      .map((row, index) => (
                        //  <Link to="/Dasdmodal">

                        <TableRow
                          key={row.Task_name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 == 0 ? "rgba(213, 213, 213, 0.25)" : null,
                          }}
                        >
                          <Link
                            to="/Dasdmodal"
                            style={{
                              textDecoration: "none",
                              display: "contents",
                            }}
                            onClick={() => {
                              this.props.SetMember(row.assign_to_details)
                            }}
                          >
                            <TableCell
                              sx={
                                {
                                  // padding:"11px 16px"
                                }
                              }
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <ListItem>
                                {/* <ListItemAvatar>
                                <Avatar>
                                  <ImageIcon />
                                </Avatar>
                              </ListItemAvatar> */}
                                <ListItemText primary={row.task_name} />
                              </ListItem>
                            </TableCell>

                            <TableCell
                              sx={
                                {
                                  // padding:"11px 16px"
                                }
                              }
                              align="center"
                            >
                              {row.assign_by_details && row.assign_by_details.name}
                            </TableCell>
                            <TableCell align="center">
                              {row.assign_to_details && row.assign_to_details.name}
                            </TableCell>
                            <TableCell
                              sx={
                                {
                                  // padding:"11px 16px"
                                }
                              }
                              align="center"
                            >
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#B5E4D5",
                                  borderColor: "black",
                                  color: "black",
                                }}
                              >
                                {row.status === "start" ? "Active" : "In Active"}
                              </Button>
                            </TableCell>


                            <TableCell
                              sx={
                                {
                                  // padding:"11px 16px"
                                }
                              }
                              align="center"

                            >
                              {/* <ListItemAvatar align="center">
                              <Avatar className="blink-animate"
                                style={{
                                  backgroundColor: "#FCBCA8",
                                  color: "#8A2100", fontWeight: 800,
                                  fontSize:"20px"
                                }}
                              >
                                Due
                              </Avatar>

                              
                            </ListItemAvatar> */}
                              {row.status !== "completed" && moment().isAfter(row.dead_line, 'day') && 
                                <Box
                                  className="blink-animate"
                                  style={{
                                    backgroundColor: "#B5E4D5",
                                    color: "red", fontWeight: 800,
                                    fontSize: "20px",
                                    borderRadius: "2px",
                                    width: "70%"
                                  }}>
                                  <Typography>Over Due</Typography>
                                </Box>
                              }
                            </TableCell>

                            {/* <TableCell sx={{
                    padding:"11px 16px"
                  }} align="right">{row.protein}</TableCell> */}
                          </Link>
                        </TableRow>

                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </div>

        <Grid
          style={{
            width: "93%",
            marginLeft: "2%",
            marginRight: "2%",
            marginTop: "30px",
            paddingBottom: "30px",
          }}
        >
          <Box
            sx={{
              marginLeft: "30px",

              borderRadius: " 8px",
              backgroundColor: "white",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "20px",
                marginRight: "4%"
              }}
            >
              <Typography
                style={{ marginTop: "30px", paddingTop: "20px" }}
                className="Tak"
              >
                Hello, Team Lead
              </Typography>
              <Link style={{ textDecoration: "none", cursor: 'pointer' }} to="/Spacemain"  >
                <IconButton size="small" style={{ marginTop: "30px" }} >
                  <KeyboardArrowRightIcon style={{ fontSize: '40px' }} />
                </IconButton>
              </Link>
            </Grid>
            <Typography className="Team">
              Explore the spaces you've created, Team Lead – the foundation for
              seamless task management and collaboration awaits.
            </Typography>

            <Grid style={{ padding: "30px" }}>
              <center>
                <Grid
                  style={{
                    // alignItems: "center",
                    // marginLeft: "2%",
                    // marginRight: "4%",

                    // display: "flex",
                    // gap: "19px",
                    // flexDirection: "row",
                    // justifyContent: "space-around",
                    // marginTop: "20px",
                    // width: "83%",
                    display: "flex",
                    flexDirection: "row",
                    // flexWrap:"wrap",
                    justifyContent: "space-evenly",
                    marginTop: "20px",
                  }}

                // className="bottom-part"
                >



                  {Array.isArray(this.props.task.all_space) && this.props.task.all_space.map((item) => {

                    return (
                      <Grid
                        style={{ paddingBottom: "30px", }}
                        item
                        xs={4}
                        display={"flex"}
                      >
                        <Card
                          className="card111"

                          style={{
                            //   '@media (min-width: 1050px)': {
                            //   width: "240px", // Adjust this value for larger screens
                            // },width: "280px",
                            marginTop: "2%",
                            marginLeft: "2%",
                            padding: "3px"

                          }}
                        // style={{ width: "280px", marginTop: "2%", marginLeft: "2%" }}
                        >
                          <center>
                            <Grid className=" blink-animate">
                              <Typography
                                style={{ paddingTop: "20px", }}
                                className=" animated-border-shadow   Pending  "

                              >
                                Pending task - {Array.isArray(item.task_details) && item.task_details.filter(e => (e.status !== "completed")).length}
                              </Typography>
                            </Grid>
                            <div
                              style={{
                                backgroundColor: "#F3E5AB",
                                borderBottom: "20px",
                                height: "160px",
                              }}
                            >
                              <Typography
                                style={{ paddingTop: "20px", fontWeight: 600 }}
                                className="dev"
                              >
                                {item.space_name}
                              </Typography>
                              <Typography
                                style={{ paddingTop: "20px", fontWeight: 200 }}
                                className="dev"
                              >
                                Total Member {item.total_member}
                              </Typography>

                              <Typography
                                style={{ marginRight: "50px", marginTop: "10px" }}
                                className="Tasks"
                              >
                                {Array.isArray(item.task_details) && item.task_details.length} Tasks | {parseInt((parseInt(Array.isArray(item.task_details) && item.task_details.filter(e => (e.status === "completed")).length) /
                                  parseInt(Array.isArray(item.task_details) && item.task_details.length)) * 100)
                                }%
                              </Typography>
                              <BorderLinearProgress2
                                variant="determinate"
                                style={{
                                  width: "150px",
                                  // marginLeft: "13%",
                                  color: "#2F5247",
                                  marginTop: "2%",
                                  borderBottom: "20px",
                                }}
                              />
                            </div>
                          </center>
                          <Link style={{ textDecoration: "none" }} to="/Spacenew" >
                            <Button
                              style={{ border: "none", cursor: "pointer" }}
                              className="View"
                              onClick={() => {
                                this.props.SetSpace(item)
                              }}
                            >
                              View Details
                            </Button>
                          </Link>

                          <div></div>
                        </Card>
                      </Grid>
                    )
                  })}


                  {/* <Grid
                style={{ paddingBottom: "30px" }}
                item
                xs={4}
                display={"flex"}
              >
                {Array.isArray(this.props.task.all_space) && this.props.task.all_space.map((item) => {
                  <Card
                    className="card111"
                    style={{ width: "280px", marginTop: "2%", marginLeft: "2%" }}
                  >
                    <center>
                      <div className="blink">
                        <Typography
                          style={{ paddingTop: "20px" }}
                          className="Pending"
                        >
                          Pending task - 02
                        </Typography>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#F3E5AB",
                          borderBottom: "20px",
                          height: "160px",
                        }}
                      >
                        <Typography
                          style={{ paddingTop: "20px" }}
                          className="dev"
                        >
                          Development Team
                        </Typography>

                        <AvatarGroup
                          style={{ marginTop: "10px", marginRight: "100px" }}
                        >
                          <Avatar
                            style={{ height: "32px", width: "32px" }}
                            alt="fac "
                            src="fac"
                          />
                          <Avatar
                            style={{ height: "32px", width: "32px" }}
                            alt="fac "
                            src="fac"
                          />
                          <Avatar
                            style={{ height: "32px", width: "32px" }}
                            alt="fac "
                            src="fac"
                          />
                          <Avatar
                            style={{ height: "32px", width: "32px" }}
                            alt="fac "
                            src="fac"
                          />
                        </AvatarGroup>

                        <Typography
                          style={{ marginRight: "50px", marginTop: "10px" }}
                          className="Tasks"
                        >
                          10 Tasks | 60%
                        </Typography>
                        <BorderLinearProgress2
                          variant="determinate"
                          style={{
                            width: "150px",
                            // marginLeft: "13%",
                            color: "#2F5247",
                            marginTop: "2%",
                            borderBottom: "20px",
                          }}
                        />
                      </div>
                    </center>
                    <Link style={{ textDecoration: "none" }} to="/Space" >
                      <button
                        style={{ border: "none", cursor: "pointer" }}
                        className="View"
                      >
                        View Details
                      </button>
                    </Link>

                    <div></div>
                  </Card>
                })
                }
              </Grid> */}

                  {/* <Grid
                style={{ paddingBottom: "30px" }}
                item
                xs={4}
                display={"flex"}
              >
                <Card
                  className="card111"
                  style={{ width: "280px", marginTop: "2%", marginLeft: "2%" }}
                >
                  <center>
                    <Typography
                      style={{ paddingTop: "20px" }}
                      className="Pending"
                    >
                      Pending task - 02
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#F3E5AB",
                        borderBottom: "20px",
                        height: "160px",
                      }}
                    >
                      <Typography
                        style={{ paddingTop: "20px" }}
                        className="dev"
                      >
                        Development Team
                      </Typography>

                      <AvatarGroup
                        style={{ marginTop: "10px", marginRight: "100px" }}
                      >
                        <Avatar
                          style={{ height: "32px", width: "32px" }}
                          alt="fac "
                          src="fac"
                        />
                        <Avatar
                          style={{ height: "32px", width: "32px" }}
                          alt="fac "
                          src="fac"
                        />
                        <Avatar
                          style={{ height: "32px", width: "32px" }}
                          alt="fac "
                          src="fac"
                        />
                        <Avatar
                          style={{ height: "32px", width: "32px" }}
                          alt="fac "
                          src="fac"
                        />
                      </AvatarGroup>

                      <Typography
                        style={{ marginRight: "50px", marginTop: "10px" }}
                        className="Tasks"
                      >
                        10 Tasks | 60%
                      </Typography>
                      <BorderLinearProgress2
                        variant="determinate"
                        style={{
                          width: "150px",
                          // marginLeft: "13%",
                          color: "#2F5247",
                          marginTop: "2%",
                          borderBottom: "20px",
                        }}
                      />
                    </div>
                  </center>
                  <Link style={{ textDecoration: "none" }} to="/Space" >

                    <button
                      style={{ border: "none", cursor: "pointer" }}
                      className="View"
                    >
                      View Details
                    </button>
                  </Link>

                  <div></div>
                </Card>
              </Grid> */}

                  {/* <Grid
                style={{ paddingBottom: "30px" }}
                item
                xs={4}
                display={"flex"}
              >
                <Card
                  className="card111"
                  style={{ width: "280px", marginTop: "2%", marginLeft: "2%" }}
                >
                  <center>
                    <Typography
                      style={{ paddingTop: "20px" }}
                      className="Pending"
                    >
                      Pending task - 02
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#F3E5AB",
                        borderBottom: "20px",
                        height: "160px",
                      }}
                    >
                      <Typography
                        style={{ paddingTop: "20px" }}
                        className="dev"
                      >
                        Development Team
                      </Typography>

                      <AvatarGroup
                        style={{ marginTop: "10px", marginRight: "100px" }}
                      >
                        <Avatar
                          style={{ height: "32px", width: "32px" }}
                          alt="fac "
                          src="fac"
                        />
                        <Avatar
                          style={{ height: "32px", width: "32px" }}
                          alt="fac "
                          src="fac"
                        />
                        <Avatar
                          style={{ height: "32px", width: "32px" }}
                          alt="fac "
                          src="fac"
                        />
                        <Avatar
                          style={{ height: "32px", width: "32px" }}
                          alt="fac "
                          src="fac"
                        />
                      </AvatarGroup>

                      <Typography
                        style={{ marginRight: "50px", marginTop: "10px" }}
                        className="Tasks"
                      >
                        10 Tasks | 60%
                      </Typography>
                      <BorderLinearProgress2
                        variant="determinate"
                        style={{
                          width: "150px",
                          // marginLeft: "13%",
                          color: "#2F5247",
                          marginTop: "2%",
                          borderBottom: "20px",
                        }}
                      />
                    </div>
                  </center>
                  <Link style={{ textDecoration: "none" }} to="/Space" >

                    <button
                      style={{ border: "none", cursor: "pointer" }}
                      className="View"
                    >
                      View Details
                    </button>
                  </Link>

                  <div></div>
                </Card>
              </Grid> */}




                </Grid>

              </center>

            </Grid>
          </Box>
        </Grid>
        <Loader />
      </div >
    );
  }
}
