import React, { Component } from 'react'
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import TableBody from '@mui/material/TableBody';
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography, Card, DialogContent, Select, MenuItem, FormControl, OutlinedInput, Tooltip, Box
} from '@mui/material';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import "../../image/edit.png";
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import edit from "../../image/edit.png";
import "../styles/company.css";
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Chip from "@mui/material/Chip";

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {

            addCompany: false,
            editCompany: false,
            delete: false,
            test: true,
            company_name: "",
            owner_name: "",
            company_id: "",
            contact_no: "",
            email: "",
            gst_num: "",
            fix_Amount: "",
            comp_address: "",
            invoice: "",
            name: "",
            company_shortid: "",
            address: "",
            attachment: '',
            company_shortid: '',
            attachmnet: "",
            attachmnet1: "",
            editFormOpen: false,
            loading: true,
            _id: '',
            edit_show: false,
            edit_show_id: "",
            month: "0" + (new Date().getMonth() + 1),
            year: new Date().getFullYear(),
            year2: "",
            uniqueNo: Math.floor(Math.random() * (99 - 100) + 100),
            comp_no: "",
            credit_limit: "",
            personName: [],
            member_id:[]

        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.login.type === "Viewer") {
            this.props.view_all_space_asign(this.props.login.user_id);
        }
        else {
            this.props.viewAllSpaces(this.props.login.org_details2._id);
            this.props.viewAllMembers(this.props.login.org_details2._id);
        }
        
    }

    handleChange = (event) => {
        const { value } = event.target;
        
        this.setState({
            personName: typeof value === 'string' ? value.split(',') : value,
        });
    };
    handleChangesss = (event) => {
        this.setState({ test: event.target.value });
    };
    addMemberid(new_value) {
        // Get the current valueArray from state
        const { member_id } = this.state;

        // Define the new value
        const newValue = new_value;

        // Check if the new value already exists in the array
        if (member_id.includes(newValue)) {
            // If it exists, remove it from the array
            const newValueArray = member_id.filter((value) => value !== newValue);
            this.setState({ member_id: newValueArray });
        } else {
            // If it doesn't exist, add it to the array
            const newValueArray = [...member_id, newValue];
            this.setState({ member_id: newValueArray });
        }
    };
    render() {
        const { company } = this.props

        if (this.props.loader.open) {
            return (
                <Grid>

                    <CardContent style={{ marginLeft: "1.5%", marginRight: "1.5%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginRight: "3%", marginLeft: "1%", }} />
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >

                                        <TableCell>
                                            <Skeleton variant="rectangular" animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>

                            </Table>
                        </TableContainer>

                    </CardContent>
                    {this.props.login.type !== "Viewer" && <CardContent style={{ width: "40vw" }}>

                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row", padding: 10 }}>
                                <h2 style={{}}>Member</h2>
                                {this.props.login.type !== "Viewer" &&
                                    <Button

                                        onClick={() => {
                                            this.setState({
                                                addMember: true,

                                            })
                                        }}
                                        style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" > Add Member </Button>
                                }

                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#F1F1F1" }} >
                                        <TableCell style={{ fontSize: "13px" }}>Name</TableCell>

                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Action</TableCell>


                                    </TableRow>
                                </TableHead>
                                {Array.isArray(this.props.space.all_members) && this.props.space.all_members.map((item, index) => {
                                    return (
                                        <TableRow

                                        >
                                            <TableCell style={{ fontSize: "13px" }} >{item.name}</TableCell>
                                            {/* <TableCell style={{ fontSize: "13px" }} onClick={() => { this.props.navigate('/task'); this.props.SetSpaceId(item) }}>{Array.isArray(item.users) && item.users[0].name}</TableCell>
                                            <TableCell style={{ fontSize: "13px" }} onClick={() => { this.props.navigate('/task'); this.props.SetSpaceId(item) }}>{item.desc}</TableCell> */}


                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}  >
                                                <Tooltip title="edit">
                                                    <IconButton onClick={() => {
                                                        this.setState({
                                                            editmember: true,
                                                            member_id: item._id,
                                                            membername: item.name,
                                                            number: item.mobile,
                                                            email: item.email_id

                                                        })
                                                    }}
                                                    >
                                                        <img style={{ color: "green", width: 25 }} src={edit} /> </IconButton>
                                                </Tooltip>

                                                <Tooltip title="delete">
                                                    <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                memberdelete: true,
                                                                member_id: item._id

                                                            })
                                                        }}
                                                    >
                                                        <DeleteOutlineOutlinedIcon style={{ color: "red" }} />

                                                    </IconButton>
                                                </Tooltip>

                                            </TableCell>
                                        </TableRow>
                                    )
                                })}


                            </Table>
                        </TableContainer>


                    </CardContent>}

                </Grid>
            )
        }
        else
            return (
                <Grid style={{ display: "flex", flexDirection: "row", width: "100vw" }}>

                    <CardContent style={this.props.login.type !== "Viewer" ? { width: "75vw" } : { width: "100vw" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row", padding: 10 }}>
                                <h2 style={{}}>Space</h2>
                                {this.props.login.type !== "Viewer" &&
                                    <Button

                                        onClick={() => {
                                            this.setState({
                                                addCompany: true,

                                            })
                                        }}
                                        style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" > Add Space </Button>
                                }

                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#F1F1F1" }} >
                                        <TableCell style={{ fontSize: "13px" }}>Name</TableCell>
                                        <TableCell style={{ fontSize: "13px" }}>Members</TableCell>
                                        <TableCell style={{ fontSize: "13px" }}>Description</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Action</TableCell>
                                        {/*                                        
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Projects</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Tickets </TableCell> */}

                                    </TableRow>
                                </TableHead>
                                {Array.isArray(this.props.space.spaces) && this.props.space.spaces.map((item, index) => {
                                    return (
                                        <TableRow

                                        >
                                            <TableCell style={{ fontSize: "13px" }} >{item.space_name}</TableCell>
                                            <TableCell style={{ fontSize: "13px" }} onClick={() => { this.props.navigate('/task'); this.props.SetSpaceId(item) }}>
                                                {Array.isArray(item.users) && item.users.map((item1, index) => {
                                                    return (
                                                        <div>
                                                            {item1.name}
                                                        </div>)

                                                })}</TableCell>
                                            <TableCell style={{ fontSize: "13px" }} onClick={() => { this.props.navigate('/task'); this.props.SetSpaceId(item) }}>{item.desc}</TableCell>


                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}  >
                                                <Tooltip title="edit">
                                                    <IconButton onClick={() => {
                                                        this.setState({
                                                            editCompany: true,
                                                            name: item.space_name,
                                                            space_id: item._id,
                                                            des: item.desc,
                                                            personName: item.members

                                                        })
                                                    }}
                                                    >
                                                        <img style={{ color: "green", width: 25 }} src={edit} /> </IconButton>
                                                </Tooltip>

                                                <Tooltip title="delete">
                                                    <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                delete: true,
                                                                space_id: item._id

                                                            })
                                                        }}
                                                    >
                                                        <DeleteOutlineOutlinedIcon style={{ color: "red" }} />

                                                    </IconButton>
                                                </Tooltip>

                                            </TableCell>
                                        </TableRow>
                                    )
                                })}


                            </Table>
                        </TableContainer>

                    </CardContent>
                    {this.props.login.type !== "Viewer" && <CardContent style={{ width: "40vw" }}>
                        <Paper sx={{ width: '100%' }}>
                            <TableContainer component={Paper}>
                                <div style={{ display: "flex", flexdirection: "row", padding: 10 }}>
                                    <h2 style={{}}>Member</h2>
                                    {this.props.login.type !== "Viewer" &&
                                        <Button

                                            onClick={() => {
                                                this.setState({
                                                    addMember: true,

                                                })
                                            }}
                                            style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" > Add Member </Button>
                                    }

                                </div>
                                <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" stickyHeader aria-label="sticky table">
                                    <TableHead >
                                        <TableRow style={{ backgroundColor: "#F1F1F1" }} >
                                            <TableCell style={{ fontSize: "13px" }}>Name</TableCell>

                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Action</TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(this.props.space.all_members) && this.props.space.all_members.map((item, index) => {
                                            return (



                                                <TableRow

                                                >
                                                    <TableCell style={{ fontSize: "13px" }} >{item.name}</TableCell>
                                                    {/* <TableCell style={{ fontSize: "13px" }} onClick={() => { this.props.navigate('/task'); this.props.SetSpaceId(item) }}>{Array.isArray(item.users) && item.users[0].name}</TableCell>
                                            <TableCell style={{ fontSize: "13px" }} onClick={() => { this.props.navigate('/task'); this.props.SetSpaceId(item) }}>{item.desc}</TableCell> */}


                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}  >
                                                        <Tooltip title="edit">
                                                            <IconButton onClick={() => {
                                                                this.setState({
                                                                    editmember: true,
                                                                    member_id: item._id,
                                                                    membername: item.name,
                                                                    number: item.mobile,
                                                                    email: item.email_id

                                                                })
                                                            }}
                                                            >
                                                                <img style={{ color: "green", width: 25 }} src={edit} /> </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        memberdelete: true,
                                                                        member_id: item._id

                                                                    })
                                                                }}
                                                            >
                                                                <DeleteOutlineOutlinedIcon style={{ color: "red" }} />

                                                            </IconButton>
                                                        </Tooltip>

                                                    </TableCell>
                                                </TableRow>

                                            )
                                        })}
                                    </TableBody>

                                </Table>
                            </TableContainer>

                        </Paper>
                    </CardContent>}








                    {/* ------------------------------------------------------------Add Company --------------------------- */}
                    <Dialog
                        open={this.state.addCompany}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                <Grid container>
                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Space</Typography></Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ addCompany: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>

                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Space Name<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    onChange={(e) => { this.setState({ name: e.target.value }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"} mt={2}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Members <strong style={{ color: "red" }}>*</strong>:
                                                </Typography>


                                                {/* <Select
                                                  
                                                    fullWidth

                                                   
                                      
                                                    onChange={(e) => {
                                                        this.setState({ user_id: e.target.value });
                                                        // this.props.viewModelVarient(e.target.value);
                                                    }}
                                                >



                                                    <MenuItem value="Select Model" >

                                                      
                                                        Select Members

                                                    </MenuItem>
                                                    {this.props.space.all_members.map((e) => (
                                                        <>
                                                            <MenuItem value={e._id}>{e.name}</MenuItem>
                                                        </>
                                                    ))}
                                                </Select> */}






                                                <FormControl sx={{ m: 1, width: 800 }}>
                                                    <Select
                                                        style={{ width: "300px", marginLeft: "24%" }}
                                                        labelId="demo-multiple-chip-label"
                                                        id="demo-multiple-chip"
                                                        multiple
                                                        value={this.state.personName}

                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                            
                                                        }}
                                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                        renderValue={(selected) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {selected.map((value) => (
                                                                    <Chip key={value} label={value} />
                                                                ))}
                                                            </Box>
                                                        )}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {/* {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                    >
                      {name}
                    
                    </MenuItem>
                  ))} */}
                                                        <MenuItem value={"All Batch"}>{"All Batch"}</MenuItem>
                                                        {Array.isArray(this.props.space.all_members) && this.props.space.all_members.map((item) => (
                                                            <MenuItem value={item.name}
                                                                onClick={() => {
                                                                    this.addMemberid(item._id)
                                                                }}
                                                            >{item.name}</MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>


                                            </Grid>
                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"} mt={2}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    onChange={(e) => { this.setState({ des: e.target.value }) }}
                                                />
                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Grid>




                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'center' }}>

                                <Button

                                    onClick={() => {
                                        this.setState({ addCompany: false, });

                                        this.props.addSpaces(this.props.login.org_details2._id, this.state.name, this.state.member_id, this.state.des,);
                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Add Phase
                                </Button>
                            </DialogActions>

                        </Grid>
                    </Dialog >
                    <Dialog
                        open={this.state.editCompany}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                <Grid container>
                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Edit Space</Typography></Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ editCompany: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>

                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Space Name<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    value={this.state.name}
                                                    onChange={(e) => { this.setState({ name: e.target.value }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"} mt={2}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Members <strong style={{ color: "red" }}>*</strong>:
                                                </Typography>


                                                {/* <Select
                                                  
                                                    fullWidth

                                                   
                                      
                                                    onChange={(e) => {
                                                        this.setState({ user_id: e.target.value });
                                                        // this.props.viewModelVarient(e.target.value);
                                                    }}
                                                >



                                                    <MenuItem value="Select Model" >

                                                      
                                                        Select Members

                                                    </MenuItem>
                                                    {this.props.space.all_members.map((e) => (
                                                        <>
                                                            <MenuItem value={e._id}>{e.name}</MenuItem>
                                                        </>
                                                    ))}
                                                </Select> */}






                                                <FormControl sx={{ m: 1, width: 800 }}>

                                                    <Select


                                                        fullWidth
                                                        multiple
                                                        value={this.state.personName}
                                                        // onChange={this.handleChange}

                                                        MenuProps={MenuProps}
                                                    >
                                                        {this.props.space.all_members.map((e) => (
                                                            <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex" }}>

                                                                <MenuItem
                                                                    style={{ width: "100%" }}
                                                                    key={e.name}
                                                                    value={e._id}

                                                                >

                                                                    <Typography>

                                                                        {e.name}

                                                                    </Typography>
                                                                    <Tooltip title="edit">
                                                                        <IconButton onClick={() => {
                                                                            this.setState({
                                                                                editmember: true,
                                                                                member_id: e._id,
                                                                                membername: e.name,
                                                                                number: e.mobile,
                                                                                email: e.email_id



                                                                            })
                                                                        }}
                                                                        >
                                                                            <img style={{ color: "green", width: 25 }} src={edit} /> </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="delete">
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    memberdelete: true,
                                                                                    member_id: e._id,



                                                                                })
                                                                            }}
                                                                        >
                                                                            <DeleteOutlineOutlinedIcon style={{ color: "red" }} />

                                                                        </IconButton>
                                                                    </Tooltip>

                                                                </MenuItem>


                                                            </div>
                                                        ))}
                                                        <Button style={{ border: "1px dotted blue", padding: 15, width: "20%", margin: 10 }} onClick={() => {
                                                            this.setState({ addMember: true });
                                                        }}>Add Member</Button>
                                                    </Select>
                                                </FormControl>


                                            </Grid>
                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"} mt={2}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    value={this.state.des}
                                                    onChange={(e) => { this.setState({ des: e.target.value }) }}
                                                />
                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Grid>




                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'center' }}>

                                <Button

                                    onClick={() => {
                                        this.setState({ editCompany: false, });

                                        this.props.updateSpace(this.state.space_id, this.state.name, this.state.personName, this.state.des, this.props.login.org_details2._id);
                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Chnage Space
                                </Button>
                            </DialogActions>

                        </Grid>
                    </Dialog >


                    <Dialog
                        open={this.state.delete}
                        onClose={() => {
                            this.setState({ delete: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100%", height: "100%" }}
                    >


                        <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                            <center>
                                <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                            </center>
                            <DialogActions style={{ marginTop: "40px" }}>

                                <Button variant="contained"
                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}

                                    onClick={() => { this.setState({ delete: false }) }}>

                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                    onClick={() => {
                                        this.props.deleteSpace(this.state.space_id, this.props.login.org_details2._id)

                                        this.setState({ delete: false });
                                    }}

                                >

                                    Confirm
                                </Button>


                            </DialogActions>
                        </Grid>






                    </Dialog>
                    <Dialog
                        open={this.state.memberdelete}
                        onClose={() => {
                            this.setState({ memberdelete: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100%", height: "100%" }}
                    >


                        <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                            <center>
                                <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                            </center>
                            <DialogActions style={{ marginTop: "40px" }}>

                                <Button variant="contained"
                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}

                                    onClick={() => { this.setState({ memberdelete: false }) }}>

                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                    onClick={() => {
                                        this.props.deleteMember(this.state.member_id, this.props.login.org_details2._id)

                                        this.setState({ delete: false });
                                    }}

                                >

                                    Confirm
                                </Button>


                            </DialogActions>
                        </Grid>






                    </Dialog>
                    <Dialog
                        open={this.state.addMember}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                <Grid container>
                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Memeber</Typography></Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ addMember: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>

                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Name<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    onChange={(e) => { this.setState({ membername: e.target.value }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Email<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Number<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    type='number'
                                                    onChange={(e) => { this.setState({ number: e.target.value }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Access Type <strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <Select
                                                    fullWidth
                                                    value={this.state.test}

                                                    onChange={this.handleChangesss}>
                                                    <MenuItem value={"Editior"}>Editior</MenuItem>
                                                    <MenuItem value={"Owner"}>Owner</MenuItem>
                                                    <MenuItem value={"Viewer"}>Viewer</MenuItem>

                                                </Select>
                                            </Grid>



                                        </Grid>
                                    </Grid>
                                </Grid>




                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'center' }}>

                                <Button

                                    onClick={() => {
                                        this.setState({ addMember: false, });

                                        this.props.addMember(this.props.login.org_details2._id, this.state.membername, this.state.email, this.state.number, this.state.test);
                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Add Members
                                </Button>
                            </DialogActions>

                        </Grid>
                    </Dialog >
                    <Dialog
                        open={this.state.editmember}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                <Grid container>
                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Memeber</Typography></Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ editmember: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>

                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Name<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    value={this.state.membername}
                                                    onChange={(e) => { this.setState({ membername: e.target.value }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Email<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    value={this.state.email}
                                                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Number<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    type='number'
                                                    value={this.state.number}
                                                    onChange={(e) => { this.setState({ number: e.target.value }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={8} direction={"row"} display={"flex"} flexDirection={"row"}>
                                                <Typography style={{ fontSize: 'large', whiteSpace: "nowrap" }}>
                                                    Access Type <strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                                <Select
                                                    fullWidth
                                                    value={this.state.test}

                                                    onChange={this.handleChangesss}>
                                                    <MenuItem value={"Editior"}>Editior</MenuItem>
                                                    <MenuItem value={"Owner"}>Owner</MenuItem>
                                                    <MenuItem value={"Viewer"}>Viewer</MenuItem>

                                                </Select>
                                            </Grid>



                                        </Grid>
                                    </Grid>
                                </Grid>




                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'center' }}>

                                <Button

                                    onClick={() => {
                                        this.setState({ editmember: false, });

                                        this.props.updateMember(this.props.login.org_details2._id, this.state.membername, this.state.email, this.state.number, this.state.test);
                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Chnages Members
                                </Button>
                            </DialogActions>

                        </Grid>
                    </Dialog >

                </Grid >

            )
    }
}
export default withRouter(Company);
