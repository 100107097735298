import { Typography, Box, Grid, IconButton, TextField } from '@mui/material';
import React, { Component } from 'react';
import Myprofilepic from "../image/myprofile-pic.png";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Camera from '../../pages/image/camera.png';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Lg_Cont from '../../router/containers/Lg_Cont';
import LoopIcon from '@mui/icons-material/Loop';
import { Link } from "react-router-dom";
import Loader from '../../common/loader/containers/loader_cont';
// import "./myProfile.css"
class myprofile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            setPage: 0,
            rowsPerPage: 3,
            setRowsPerPage: "",
            profile_pic: "",
            name: "",
            email: "",
            phone: "",
            emp_id: "",
            editGrid: false,
            profile_pic2: "",
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = 'rgb(251, 248, 255)';
        this.props.view_user_profile(this.props.login.user_id)
        
        
    }
    render() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                    <Grid style={{
                        marginLeft: "7%", marginTop: "19%", boxShadow: "0px 0px 8px 0px #00000040", background: "#EFFFFA", width: "400px",height:"350px", padding: "17px", borderRadius: "2px"
                    }}>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                            <Typography style={{ fontWeight: "600", fontSize: "20px", color: "#151215", marginBottom: "5%" }}>User Profile</Typography>
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        editGrid: true,
                                        profile_pic2: this.props.login.user_profile.profile_pic,
                                        name: this.props.login.user_profile.name,
                                        email: this.props.login.user_profile.email_id,
                                        emp_id:this.props.login.user_profile.emp_id,
                                      
                                    })
                                }}
                            > <BorderColorIcon style={{color:"rgb(28, 53, 45)",fontSize:"20px"}} /></IconButton>
                        </Box>
                        <Box style={{ display: "flex", gap: "18px", background: "none" }}>
                            <Avatar src={this.props.login.user_profile.profile_pic} style={{  height: "60px",
                                                        width: "60px", }} />
                            <Box style={{ background: "none" }}>
                                <Typography style={{ fontWeight: "600", fontSize: "16px", color: "#151215" }}>{this.props.login.user_profile.name}</Typography>
                                <Typography style={{ fontWeight: "400", fontSize: "16px", color: "#9D979D",marginTop:"5px" }}>{this.props.login.user_profile.email_id}</Typography>
                                <Typography style={{ fontWeight: "400", fontSize: "14px", color: "rgb(0, 85, 85)",marginTop:"5px"  }}>{this.props.login.user_profile.emp_id}</Typography>
                            </Box>
                        </Box>
                        <div >
                            <center style={{ marginTop:"45px" }}>
                                <Lg_Cont />
                            </center>
                            <center>
                                <Link to="/">
                                    <Button style={{ marginTop: "10px", color: "#1C352D" }}
                                        onClick={() => {
                                            this.props.switchHome()
                                        }}
                                    >
                                        <LoopIcon /> Switch Organization
                                    </Button>
                                </Link>

                            </center>
                        </div>
                    </Grid>

                </Grid>
                {
                    this.state.editGrid &&
                    <Grid style={{ marginLeft: "5%", display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Grid style={{
                            marginLeft: "7%", marginTop: "20%", boxShadow: "0px 0px 8px 0px #00000040", background: "#EFFFFA", width: "400px",height:"350px", padding: "17px", borderRadius: "2px"
                        }}>
                            <Box style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                                <Typography style={{ fontWeight: "600", fontSize: "20px", color: "#151215", marginBottom: "5%" }}> Edit User Profile</Typography>
                            </Box>
                            <Box style={{ display: "flex", gap: "18px", background: "none" }}>
                                {/* <img src={this.props.login.user_profile.profile_pic} style={{ height: "80%" }} /> */}
                                <Box style={{ background: "none" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                                        {/* <Avatar variant="soft"
                                            style={{
                                                height: "60px",
                                                width: "60px",
                                                borderRadius: "50%"
                                            }}
                                            src={this.state.profile_pic !== "" && this.state.profile_pic2 !== "" ? URL.createObjectURL(this.state.profile_pic) : this.state.profile_pic2}
                                        > */}
                                        <center>
                                            <input style={{ display: "none", cursor: "pointer" }} type="file" id="file"
                                                accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"
                                                onChange={(e) => {
                                                    this.setState({
                                                        profile_pic: e.target.files[0]
                                                    })
                                                }}
                                            />
                                            <label htmlFor="file">
                                                {/* <CameraAltIcon /> */}
                                                <Avatar
                                                    src={this.state.profile_pic !== "" && this.state.profile_pic2 !== "" ? URL.createObjectURL(this.state.profile_pic) : this.state.profile_pic2}
                                                    style={{
                                                        height: "60px",
                                                        width: "60px",
                                                        alignItems: "center",
                                                        borderRadius: "50%"
                                                    }}
                                                />
                                            </label>
                                        </center>
                                        {/* </Avatar> */}
                                        <span style={{ color: "var(--primery, #006)", fontSize: "0.85rem" }}>
                                            {this.state.profile_pic !== "" ? (this.state.profile_pic).name : "Upload"}
                                        </span>
                                    </div>
                                    <TextField id="standard-basic" variant="standard" placeholder='Enter email address ' style={{ width: "100%", margin: "10px",marginTop:"20px" }}
                                        value={this.state.email}
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                    <TextField id="standard-basic" variant="standard" placeholder='Enter Name' style={{ width: "100%", margin: "10px" }}
                                        value={this.state.name}
                                        onChange={(e) => {
                                            this.setState({ name: e.target.value })
                                        }}
                                    />
                                    {/* <TextField id="standard-basic" variant="standard" placeholder='Enter Emp id' style={{ width: "100%", margin: "10px" }}

                                        onChange={(e) => {
                                            this.setState({ emp_id: e.target.value })
                                        }}
                                    /> */}
                                    <Button style={{ margin: "10px",borderColor:"rgb(28, 53, 45)",color:"rgb(28, 53, 45)" }} variant="outlined"
                                        onClick={() => {
                                            this.setState({ editGrid: false })                                                                             
                                        }}
                                    >Cancel</Button>
                                    <Button style={{ margin: "10px",backgroundColor:"rgb(28, 53, 45)" }} variant="contained"
                                        onClick={() => {
                                            this.setState({ editGrid: false })
                                            this.props.updateUploadUserProfile(this.props.login.user_id, this.state.profile_pic, this.state.profile_pic2, this.state.name, this.state.phone, this.state.email,)
                                            this.setState({ profile_pic: "", name: "", email: "", access: "", emp_id: "", phone: "" })
                                        }}
                                    >Submit</Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>}
                <Loader />
            </div>
        );
    }
}

export default myprofile;