import React, { Component } from 'react'
import { Box, Button, Grid, Typography, Card, DialogTitle } from '@mui/material'
import Dialog from "@mui/material/Dialog";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import React, { Component } from 'react'
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
// import Avatar from '@mui/material/Avatar';
// import ImageIcon from '@mui/icons-material/Image';
// import InputBase from '@mui/material/InputBase';
// import WorkIcon from '@mui/icons-material/Work';
// import BeachAccessIcon from '@mui/icons-material/BeachAccess';
// import Search from '@mui/icons-material/Search';
// import SearchIcon from '@mui/icons-material/Search';
// import { styled, alpha } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SearchIcon from "@mui/icons-material/Search";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import InputBase from "@mui/material/InputBase";

import Multiselect from 'multiselect-react-dropdown';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';

import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import moment from 'moment';
import "../../User/Components/user.css"
import Loader from '../../../common/loader/containers/loader_cont';



export default class Dasdmodal extends Component {


  constructor(props) {
    super(props);
    this.state = {
      age: '',
      deletedash: false,
      right: false,
      calicon: false,
      calicon1: false,
      addtask: false,
      value: new Date(),
      value1: new Date(),
      rows: [
        {
          Taskname: 'Frozen yoghurt',
          TotalHours: 159,
          AssignBy: 6.0,
          Status: "ongoing",
          Action: 4.0,
        },
        {
          Taskname: 'Frozen yoghurt',
          TotalHours: 159,
          AssignBy: 6.0,
          Status: "Pending",
          Action: 4.0,
        },
        {
          Taskname: 'Frozen yoghurt',
          TotalHours: 159,
          AssignBy: 6.0,
          Status: "ongoing",
          Action: 4.0,
        },
        {
          Taskname: 'Frozen yoghurt',
          TotalHours: 159,
          AssignBy: 6.0,
          Status: "Overdue",
          Action: 4.0,
        },
        {
          Taskname: 'Frozen yoghurt',
          TotalHours: 159,
          AssignBy: 6.0,
          Status: "ongoing",
          Action: 4.0,
        },
        {
          Taskname: 'Frozen yoghurt',
          TotalHours: 159,
          AssignBy: 6.0,
          Status: "Overdue",
          Action: 4.0,
        },


      ],
      task_name: "",
      task_desc: "",
      end_date: "",
      start_date: "",
      total_hrs: "",
      task_details: {},
      task_id: "",
    };

    this.toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      this.setState({ ...this.state, right: open });
    };

    this.list = () => (
      <div style={{ width: "500px" }}>

        {/* <h1> helloooooooooooooooooooo</h1> */}


        <NavigateBeforeIcon

          onClick={() => {
            this.setState({ right: false });
          }}

          style={{ marginTop: "7%", fontSize: 30, marginLeft: "30px", cursor: "pointer" }} />



        <Card style={{
          width: "438px", marginTop: "7%", borderRadius: "8px", marginLeft: "30px", paddingBottom: "20px",
          background: "var(--shades-of-primary-s-9, #EFFFFA)",
          boxShadow: "0px 0px 4px 0px rgba(135, 135, 135, 0.50)",
        }} variant="outlined">


          <center>
            <Typography className="word">
              Task Name: {this.state.task_details.task_name}
            </Typography>

            <Typography className="Redesign">
              {this.state.task_details.desc}

            </Typography>
            <Grid style={{ display: "flex", justifyContent: "space-evenly", flexDirection: "row" }}>
              <Typography className="word1">
                Given By
              </Typography>
              <Typography className="jane1">
                {this.state.task_details.assign_by_detail && this.state.task_details.assign_by_detail.name}
              </Typography>
            </Grid>

            <Grid style={{ display: "flex", justifyContent: "space-evenly", flexDirection: "row" }}>
              <Typography className="word1">
                Total hours
              </Typography>
              <Typography className="jane1">
                {this.state.task_details.total_time}hrs

              </Typography>
            </Grid>

            <Grid className="Time1" style={{
              backgroundColor: this.state.task_details.status == "start" ? "#2196F3" : this.state.task_details.status == "stop" ? "#FF5733" : this.state.task_details.status == "notstarted" ? "#FFC107" : this.state.task_details.status == "completed" ? "#4CAF50" : null,
            }}>
              <Typography className="Time" style={{}}>
                {this.state.task_details.status === "completed" ? "Completed" : this.state.task_details.status === "start" ? "Start" : this.state.task_details.status === "stop" ? "Stop" : "Pending"}
                <br />
                {this.state.task_details.status === "completed" && this.state.task_details.complete_hours + 'h' + ":" + this.state.task_details.complete_minutes + 'm' + ":" + this.state.task_details.complete_second + 's'}
              </Typography>

            </Grid>
          </center>

        </Card>
      </div>
    )




    this.handleChange = (event) => {
      this.setState({ age: event.target.value });
    };
  }
  handleChangeCal = (calvalue) => {
    ;
    this.setState({ calicon: false });
    const formattedDate = calvalue.toISOString().split('T')[0];
    this.setState({ calvalue: formattedDate });
  };
  handleChangeCal1 = (calvalue1) => {
    ;
    this.setState({ calicon1: false });
    const formattedDate = calvalue1.toISOString().split('T')[0];
    this.setState({ calvalue1: formattedDate });
  };
  componentDidMount() {
    if (this.props.user.page_type === "U") {
      this.props.view_all_task_by_emp_id(this.props.space.member_details._id)
    }
    else {
      this.props.view_all_task_assign(this.props.space.space_details._id, this.props.space.member_details._id)
    }

  }

  render() {
    const { calvalue, calvalue1 } = this.state;
    const { rows } = this.state;
    const { age, right } = this.state;
    const today = moment().format('YYYY-MM-DD')
    const due_task = this.props.task.all_task.filter(e => (e.status !== "completed"))

    const total_completed = this.props.task.all_task.filter(e => (e.status === "completed"))
    return (
      <Grid>
        <div style={{

          height: "89vh",
          // width:"210vh",
          backgroundSize: "cover",
          // backgroundColor:"red"
          backgroundImage: `url("https://s3-alpha-sig.figma.com/img/bbcd/0f82/7216f2f9b62400375f856aba1311be0b?Expires=1699228800&Signature=OtbTqQwIP2l70yVmDiywrOLuMXFhZ-FXxpavAWdATari4rQ8iptnwzGYl48Y3i6XxfFZTMRpwsXBn3pojk3ufE0mva7XZxtakw0lS6Qb-G8hCB1zHLcrv4gJoPxNsvHMvW8ATVQKXD0K0y~lVmHGD2VLeyNK3RXQSflDXIcjiC3la2VxkI56uDBWM1CGM~0KPEAdjCOLLm3fX9ejGzPh4T6LO3dNTVlJMTfq1vh36XIgsfLUVRmYJU5atQlO8Z7~hfnrCCGxsAXBSz30Dkvj~8aDSS2KUDL37T0XcCl9bZlj9WVF0AAZhpD~6eoOMlGQXGWbw3xiLVAx0GD5ykTryg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4")`
        }}>
          <div style={{
            padding: "5em",
          }}>
            <Box style={{
              display: "flex",
              flexDirection: "row",
              gap: "6px",
              justifyContent: "space-between",
              // padding: " 0em 5em"
            }}>

              <div>

                <span style={{ color: "var(--grey-g-2, #8097B1)", fontFamily: "Poppins", fontSize: "16px", fontWeight: 400, }}>Name
                  <h3 style={{ marginLeft: "1.8px", color: "#2E384D", fontFamily: "Poppins", fontSize: "18px", fontWeight: 600, display: "inline", whiteSpace: "nowrap" }}> {this.props.space.member_details.name}</h3>  </span> <br />
                <span style={{ color: "var(--grey-g-2, #8097B1)", fontFamily: "Poppins", fontSize: "16px", fontWeight: 400 }}>Email_ID
                  <h3 style={{ marginLeft: "1.8px", color: "#2E384D", fontFamily: "Poppins", fontSize: "18px", fontWeight: 600, display: "inline", whiteSpace: "nowrap" }}> {this.props.space.member_details.email_id}</h3>  </span>

              </div>
              <div style={{
                display: "flex",
                gap: "10px",
                flexDirection: "row"
              }}>



                <h4 style={{ color: "var(--grey-g-2, #8097B1)", fontFamily: "Poppins", fontSize: "16px", fontWeight: 400, marginLeft: "0px" }} > Due task</h4> <h3 className="blink-animate" style={{ textAlign: "center", height: "25px", fontSize: "14px", padding: "2px 12px", borderRadius: "50%", backgroundColor: "#FCBCA8", border: "1px solid red" }}>{due_task.length}</h3> <br />

                <h4 style={{ color: "var(--grey-g-2, #8097B1)", fontFamily: "Poppins", fontSize: "16px", fontWeight: 400, marginLeft: "30px" }}>  Total Assign Tasks</h4> <h3 style={{
                  textAlign: "center", padding: "3px 12px", height: "25px", fontSize: "14px", borderRadius: "50%", backgroundColor: "#FFF2CB", boxShadow: "0px 2px 4px 0px #BDBDBD",
                }}>{this.props.task.all_task.length} </h3> <br />
                <h4 style={{ color: "var(--grey-g-2, #8097B1)", fontFamily: "Poppins", fontSize: "16px", fontWeight: 400, marginLeft: "30px" }}> Total Completed Task</h4> <h3 style={{ textAlign: "center", padding: "3px 12px", borderRadius: "50%", height: "25px", fontSize: "14px", backgroundColor: "#E3FFE3", boxShadow: "0px 2px 4px 0px #BDBDBD" }}>{total_completed.length} </h3> <br />



              </div>

              {/* <FormControl style={{
                width: "8em",
                marginRight: "7%"
              }}>
                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Filter"
                  onChange={() => this.handleChange()}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl> */}

              <Grid>

                <Button
                  onClick={() => {
                    this.setState({ addtask: true });
                  }}
                  size='small' variant='contained' style={{ backgroundColor: "#1C352D", padding: "8px", marginRight: "5%", width: "150px" }} startIcon={<AddIcon />}>Add Task</Button>

              </Grid>
            </Box>

            <Box>

              <Drawer
                anchor="right"
                role="presentation"
                open={this.state.right}
                onClose={() => this.toggleDrawer(false)}
              >
                {this.list()}
              </Drawer>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead sx={{
                    padding: "24px 16px",
                    backgroundColor: "#B5E4D5"
                  }}>
                    <TableRow >
                      <TableCell align="Center" sx={{
                        padding: "24px 16px"
                      }}>Sr. NO.</TableCell>
                      <TableCell align="Center">Task Name</TableCell>
                      <TableCell align="Center">Total Hours</TableCell>
                      <TableCell align="Center">Assign by</TableCell>
                      <TableCell align="Center">Status</TableCell>
                      <TableCell align="Center">Action</TableCell>
                      {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(this.props.task.all_task) && this.props.task.all_task.
                      sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                      .map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", backgroundColor: row.Status == "ongoing" ? "#E1F2FF" : row.Status == "Overdue" ? "#FFEDE8" : row.Status == "Pending" ? "#FFF2CB" : row.Status == "TimeTaken" ? "#E3FFE3" : null }}
                        >
                          <TableCell
                            onClick={(e) => {
                              this.toggleDrawer(true, e)
                              this.setState({ task_details: row, right: true })
                            }} sx={{
                              // padding:"16px 16px"
                            }} component="th" scope="row" align="Center">
                            {index + 1}
                          </TableCell>
                          <TableCell onClick={(e) => {
                            this.toggleDrawer(true, e)
                            this.setState({ task_details: row, right: true })
                          }} sx={{
                            // padding:"16px 16px"
                          }} component="th" scope="row" align="Center">
                            {row.task_name}
                          </TableCell>
                          <TableCell onClick={(e) => {
                            this.toggleDrawer(true, e)
                            this.setState({ task_details: row, right: true })
                          }} sx={{
                            padding: "16px 16px"
                          }} align="Center">{row.total_time}</TableCell>
                          <TableCell onClick={(e) => {
                            this.toggleDrawer(true, e)
                            this.setState({ task_details: row, right: true })
                          }} sx={{
                            padding: "16px 16px"
                          }} align="Center">{row.assign_by_detail && row.assign_by_detail.name}</TableCell>
                          <TableCell onClick={(e) => {
                            this.toggleDrawer(true.e)
                            this.setState({ task_details: row, right: true })
                          }} sx={{
                            padding: "16px 16px"

                          }} align="Center">

                            <Button onClick={() => {
                              this.toggleDrawer(true)
                              this.setState({ task_details: row, right: true })
                            }} variant='contained' style={{
                              color: "#000",
                              wordWrap: "break-word",
                              // textWrap:"wrap",
                              backgroundColor: row.status == "start" ? "#2196F3" : row.status == "stop" ? "#FF5733" : row.status == "notstarted" ? "#FFC107" : row.status == "completed" ? "#4CAF50" : null,
                            }}>
                              <Typography>
                                {row.status === "completed" ? "Completed" : row.status === "start" ? "Started" : row.status === "notstarted" ? "Pending" : "Stop"}<br />
                                {row.status === "completed" &&
                                  row.complete_hours + 'h' + ":" + row.complete_minutes + 'm' + ":" + row.complete_second + 's'
                                }
                              </Typography>
                            </Button>



                            {/* <span style={{
                    backgroundColor:row.Status=="ongoing" ?"#2196F3":row.Status=="Overdue"?"#FF5733":row.Status=="Pending"?"#FFC107":row.Status=="TimeTaken"?"#4CAF50":null,
                    padding:".5em 1em",
                    borderRadius:"5px",
                    color:"white"
                    // border:"1px solid"
                  }}></span> */}

                          </TableCell>
                          <TableCell
                            align="Center"
                            sx={{
                              padding: "16px 39px"
                            }} onClick={() => {
                              this.setState({ deletedash: true, task_id: row._id });
                            }}>
                            <DeleteOutlineOutlinedIcon style={{ color: "red" }} /></TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

          </div>


        </div>




        <Dialog
          open={this.state.deletedash}
          onClose={() => {
            this.setState({ deletedash: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%", }}
        >


          <Grid style={{ backgroundColor: "#F8F8F8", minWidth: "350px", borderRadius: "20px" }}>


            <center>
              <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "80px", color: "#FF0000" }} />
              <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>

            </center>


            <Button variant="contained"
              style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", height: "68px", width: "182px", color: "black", fontSize: "18px", marginTop: "6%" }}
              onClick={() => { this.setState({ deletedash: false }) }}>

              Cancel
            </Button>
            <Button variant="contained"
              style={{ backgroundColor: "#ED3A3A", height: "68px", width: "182px", fontSize: "18px", marginTop: "6%" }}
              onClick={() => {
                this.setState({ deletedash: false })
                this.props.deleteTask(this.state.task_id, this.props.space.space_details._id, this.props.space.member_details._id, this.props.user.page_type)
                // this.props.deleteCategory(this.state.category_id)
              }}>

              Confirm
            </Button>



          </Grid>
        </Dialog>


        <Dialog
          open={this.state.addtask} style={{}}>
          <Grid style={{ height: "350px", width: "550px", padding: 25 }}>


            <Grid style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}>
              <Typography style={{ fontSize: "18px", fontWeight: "500" }}>Assign Task</Typography>
              <CloseIcon onClick={() => {
                this.setState({ addtask: false });
              }} />
            </Grid>


            <Grid style={{ display: "flex", flexDirection: "row", gap: 100 }}>

              <TextField id="standard-basic" label="Task Name " variant="standard"
                onChange={(e) => {
                  this.setState({ task_name: e.target.value })
                }}
              ></TextField>
              <TextField id="standard-basic" label="Notes" variant="standard" style={{ color: "black" }}
                onChange={(e) => {
                  this.setState({ task_desc: e.target.value })
                }}
              > </TextField>
            </Grid>

            <Grid style={{ display: "flex", flexDirection: "row", gap: 100, marginTop: "5%", width: "500px" }}>
              <TextField
                style={{ width: "185px" }}
                id="input-with-icon-textfield"
                value={calvalue}
                type='date'
                // label="Start Date"
                inputProps={{ min: today }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <CalendarMonthIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => { this.setState({ calicon: true }); }}
                      /> */}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={(e) => {
                  this.setState({ start_date: e.target.value })
                }}
              ></TextField>


              <TextField
                style={{ width: "185px" }}
                inputProps={{ min: today }}
                id="input-with-icon-textfield"
                // label="Deadline Date"
                type='date'
                value={calvalue1}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={(e) => {
                  this.setState({ end_date: e.target.value })
                }}
              />




            </Grid>

            <Grid style={{ display: "flex", flexDirection: "row", gap: 70, marginTop: "5%", width: "500px" }}>
              <TextField id="standard-basic" label="Total Hours" variant="standard"
                type='number'
                onChange={(e) => {
                  this.setState({ total_hrs: e.target.value })
                }}
              />

              {/* <input type='date'  className='calnd' /> */}




            </Grid>

            <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "14%", width: "500px" }}>
              <span></span>
              <Button
                onClick={() => {
                  this.setState({ addtask: false });
                  this.props.add_task(this.props.space.space_details._id, this.state.task_name, this.state.task_desc, this.state.start_date, this.state.end_date, this.props.space.member_details._id, this.props.login.user_id, this.state.total_hrs, this.props.user.page_type)
                }}
                size='small' variant='contained' style={{ backgroundColor: "#1C352D", fontSize: "14px", marginRight: "2%", textTransform: "capitalize" }}

              >Submit</Button>
            </Grid>






          </Grid>
        </Dialog>

        <Dialog
          open={this.state.calicon}
        >
          <Calendar
            onChange={this.handleChangeCal} value={calvalue} />
        </Dialog>

        <Dialog
          open={this.state.calicon1}

        >
          <Calendar
            onChange={this.handleChangeCal1} value={calvalue1} />
        </Dialog>
        <Loader />
      </Grid>
    )
  }
}
