import {


    VIEW_ALL_TASK_BY_SPACE,
    VIEW_ALL_SPACE_ORG

} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";
import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { view_all_space_task } from "../../pages/Assigntask/action"
export function add_task(space_id, task_name, desc, start_date, dead_line, assign_to, assign_by, total_time, page_type) {
    return (dispatch) => {
        
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_tasK", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                space_id: space_id,
                task_name: task_name,
                desc: desc,
                start_date: start_date,
                dead_line: dead_line,
                assign_to: assign_to,
                assign_by: assign_by,
                total_time: total_time,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (page_type === "U") {
                        dispatch(view_all_task_by_emp_id(assign_to))
                    }
                    else if (page_type === "E") {
                        dispatch(view_all_space_task(space_id));
                    }
                    else {
                        dispatch(view_all_task_assign(space_id, assign_to))

                    }
                } else {
                    if (page_type === "U") {
                        dispatch(view_all_task_by_emp_id(assign_to))
                    }
                    else if (page_type === "E") {
                        dispatch(view_all_space_task(space_id));
                    }
                    else {
                        dispatch(view_all_task_assign(space_id, assign_to))

                    }
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                // dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function stop_task(stop_by, task_id, space_id) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "stop_task", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                stop_by: stop_by
            },
            body: JSON.stringify({

                task_id: task_id,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(view_all_task_assign(space_id, stop_by))
                    // dispatch(unsetLoader());
                } else {
                    dispatch(view_all_task_assign(space_id, stop_by))
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                // dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function mark_as_complete(task_id, space_id, assign_to) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "mark_as_complete", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

                task_id: task_id,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(view_all_task_assign(space_id, assign_to))
                    dispatch(unsetLoader())
                } else {
                    dispatch(view_all_task_assign(space_id, assign_to))
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                // dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function start_time(start_by, task_id, space_id) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "start_time", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                start_by: start_by,
                task_id: task_id,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(view_all_task_assign(space_id, start_by))
                    dispatch(unsetLoader());
                } else {
                    dispatch(view_all_task_assign(space_id, start_by))
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                // dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function update_task(milestone_id, _id, title, desc, start_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_task", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
                title: title,
                desc: desc,
                start_date: start_date,
                dead_line: dead_line,
                status_id: status_id,
                priority_id: priority_id,
                assign_to: assign_to,
                total_time: total_time,
                task_type: task_type,
                attachmnets: attachmnets

            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                // dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}

export function viewAlltaskBySpace(space_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task_by_space", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                space_id: space_id,

            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch({ type: VIEW_ALL_TASK_BY_SPACE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_TASK_BY_SPACE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });


    };
}
export function view_all_task_assign(space_id, assign_to) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task_assign", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                space_id: space_id,
                assign_to: assign_to

            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch({ type: VIEW_ALL_TASK_BY_SPACE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_TASK_BY_SPACE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });


    };
}
export function DeleteSpace(_id, space_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_space", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAlltaskBySpace(space_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(viewAlltaskBySpace(space_id));
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_all_task_by_emp_id(emp_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task_by_emp_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                emp_id: emp_id,

            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch({ type: VIEW_ALL_TASK_BY_SPACE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_TASK_BY_SPACE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });


    };
}

export function viewAlltaskOrg(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task_of_org", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                org_id: org_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch({ type: VIEW_ALL_TASK_BY_SPACE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_TASK_BY_SPACE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });


    };
}

export function view_all_org_space(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_organisation_space", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,

            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch({ type: VIEW_ALL_SPACE_ORG, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_SPACE_ORG, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });


    };
}


export function deleteTask(_id, space_id, assign_to, page_type) {
    return (dispatch) => {
        
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_task", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    if (page_type !== "U") {
                        dispatch(view_all_task_assign(space_id, assign_to))
                    }
                    else {
                        dispatch(view_all_task_by_emp_id(assign_to))
                    }
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    if (page_type !== "U") {
                        dispatch(view_all_task_assign(space_id, assign_to))
                    }
                    else {
                        dispatch(view_all_task_by_emp_id(assign_to))
                    }
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                // dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

