import {
  LOGIN,
  VIEW_ALL_MEMBERS,
  SET_PAGE
} from "./constant";
const initial_state = {
  user_id: "",
  user:[],
  page_type:""
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case VIEW_ALL_MEMBERS:
      
    return state = { ...state, user: action.payload.data, user_length: action.payload.length};
    case SET_PAGE:
      
    return state = { ...state, page_type: action.payload};
    default:
      return state;
  }
}
