import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../containers/controller_cont";
import Login from "../../auth/container/logincont";
// import Dashboard from "../../pages/admin/dashboard/container/dashbordCont";
import Loginotp from "../../auth/container/loginotpcont";

import Space from "../../pages/Space/container/SpaceCont";
import Task from "../../pages/Task/container/taskCont";

// import Task from "../../pages/Task/container/taskCont"
import Pay from "../../../src/auth/components/payment";
import Dashboard from "../../pages/Task/container/DashboardCont";
import Dasdmodal from "../../pages/Task/container/DashModalCont";
import Navbar from "../../pages/navbar/navbarCont";
import Analysis from "../../pages/Analysis/Components/analysis";
import Spacenew from "../../pages/Space/container/spacenewCont";
import Spacemain from "../../pages/Space/container/spacemainCont";
import User from "../../pages/User/Container/UserCont";
import Emplogin from "../../auth/components/Emplogin";
// import Organizationss from "../../auth/components/Organizationss";
import Taskorg from "../../auth/container/VieworgCont";

import Drawer_option_con from "../../router/containers/drawer_cont";

// import Sidebar from "../../pages/Sidebar/Sidebar";
// import Myprofile from "../../pages/Myprofile/myprofile";

import Assigntask from "../../pages/Assigntask/taskCont";
import Completetask from "../../pages/Completetask/completetask";
import Frontpage from "../../pages/Frontpage/frontpage";
import Myprofile from "../../pages/Myprofile/myProfileCont";

import Myspace from "../../pages/Myspace/mySpaceCont";

class Routers extends Component {
  componentDidMount() {
    if (window.location.pathname === "/") {
      localStorage.setItem('newNumber', 0);
    }
  }
  render() {
    const { login, onLogout } = this.props;
    let body;


    if (login.isHome) {
      body = (
        <div>
          <main
            style={{
              flexGrow: 1,
            }}
          >
            <Controller />
            <Routes>

              <Route exact path="/" element={<Login />} />
              <Route exact path="/verify/:id" element={<Login />} />
              {/* <Route exact path="/" element={<Emplogin />} /> */}
              {/* <Route exact path="/loginotp" element={<Loginotp />} /> */}
              {/* <Route exact path="*" element={<Error />} /> */}

            </Routes>

          </main>
        </div>
      );
    }
    else if (login.orgHome) {
      if (login.type === "Owner" || login.type === "A") {
        body = (
          <div style={{ background: "#FBF8FF" }}>
            <Navbar />
            <main
              style={{
                flexGrow: 1,
              }}
            >
              <Controller />
              <Routes>

                <Route exact path="/Dashboard" element={<div><Dashboard /></div>} />

                <Route exact path="/" element={<div> <Dashboard /></div>} />
                <Route exact path="/verify/:id" element={<div> <Dashboard /></div>} />
                <Route exact path="/Navbar" element={<Navbar />} />
                {/* <Route exact path="/Dashboard" element={<Dashboard />} /> */}

                <Route exact path="/Dasdmodal" element={<div> <Dasdmodal /></div>} />
                <Route exact path="/Space" element={<div><Space /> </div>} />
                <Route exact path="/Spacenew" element={<div><Spacenew /></div>} />
                <Route exact path="/analysis" element={<div><Analysis /> </div>} />
                <Route exact path="/spacemain" element={<div><Spacemain /> </div>} />
                <Route exact path="/user" element={<div><User /> </div>} />
                <Route exact path="/pay" element={<Pay />} />

                <Route exact path="/Myprofile" element={<div><Myprofile /> </div>} />



                {/* <Route exact path="/completetask" element={<Completetask />} /> */}
                {/* <Route exact path="/assigntask" element={<><Assigntask /></>} /> */}



                {/* <Route exact path="/checkstatus" element={<OrderStatus />} />
              <Route exact path="/checkstatusdownload" element={< OrderStatusdown />} /> */}

                {/* <Route exact path="/loginotp" element={<Loginotp />} /> */}

                {/* <Route exact path="*" element={<Error />} /> */}
              </Routes>
            </main>
          </div>
        );
      }

      else if (login.type === "Viewer" || login.type === "Editor") {
        body = (
          <div>
            <Drawer_option_con
              type={login.type}
              onLogout={onLogout}
              name={login.name}
            />
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                // marginTop: 20,
                // marginLeft: 250
              }}
            >
              <Routes>

                <Route exact path="/" element={<Myspace />} />
                <Route exact path="/verify/:id" element={<Myspace />} />
                <Route exact path="/myspace" element={<><Myspace /></>} />

                <Route exact path="/assigntask" element={<><Assigntask /></>} />
                <Route exact path="/completetask" element={<Completetask />} />
                <Route exact path="/frontpage" element={<Frontpage />} />
                {/* <Route exact path="/assigntask" element={<><Assigntask /></>} /> */}
                <Route exact path="/myprofile" element={<Myprofile />} />

              </Routes>
            </main>


          </div>
        );
      }
    }
    else {
      body = (
        <div
        >
          <main>
            <Controller />
            <Routes>
              <Route exact path="/" element={<Taskorg />} />

            </Routes>
          </main>
        </div>
      );
    }
    return <Router>{body}</Router>;
  }
}

export default Routers;
