import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../component/Dashboard";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  viewAlltaskOrg,
  view_all_org_space
} from "../action";
import {
  SetSpace,
  SetMember
} from "../../Space/action"
export class Controller extends Component {
  render() {
    return (
      <Service {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    task: store.task,
    space: store.space,
    snackbar: store.snackbar,
    login: store.login,
    user: store.user


  };
};
export const mapDispatchToProps = dispatch => {
  return {

    viewAlltaskOrg: (org_id) => {
      dispatch(viewAlltaskOrg(org_id))
    },
    view_all_org_space: (org_id) => {
      dispatch(view_all_org_space(org_id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    SetSpace: (payload) => {
      dispatch(SetSpace(payload));
    },
    SetMember: (payload) => {
      dispatch(SetMember(payload))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);