import React, { Component } from "react";
import { connect } from "react-redux";
import Company from '../Components/user';
import {
  close_snack_bar
} from "../../../common/snackbar/action";
import {
  onLogout,
  Docview,
} from "../../../auth/actions";
import {
  send_email_verfication,
  viewAllMembersPagination,
  SetPage,
  uploadUserProfile,
} from "../actions";
import {
  SetMember
} from "../../Space/action";
// import{add_upload_project} from "../../Project/action"

export class Controller extends Component {
  render() {
    return (
      <Company {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    user: store.user,
    snackbar: store.snackbar,
    login: store.login,
    space: store.space,
    loader: store.loader
  };
};
export const mapDispatchToProps = dispatch => {
  return {


    send_email_verfication: (email, org_id) => {
      dispatch(send_email_verfication(email, org_id))
    },
    viewAllMembersPagination: (org_id, page_number, rowper_page,) => {
      dispatch(viewAllMembersPagination(org_id, page_number, rowper_page))
    },
    uploadUserProfile: (org_id, profile_pic, name, phone, email, emp_id, access,) => {
      dispatch(uploadUserProfile(org_id, profile_pic, name, phone, email, emp_id, access))
    },
    SetMember: (payload) => {
      dispatch(SetMember(payload))
    },
    SetPage: (payload) => {
      dispatch(SetPage(payload))
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);