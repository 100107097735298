import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../Myprofile/myprofile";
import {
  close_snack_bar
} from "../../common/snackbar/action";

import {
  view_user_profile,
  switchHome

} from "../../auth/actions";
import { updateUploadUserProfile } from "../../pages/User/actions"

export class Controller extends Component {
  render() {
    return (
      <Service {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    task: store.task,
    space: store.space,
    snackbar: store.snackbar,
    login: store.login,
    assgn_tsk: store.assgn_tsk,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    view_user_profile: (user_id) => {
      dispatch(view_user_profile(user_id))
    },
    updateUploadUserProfile: (_id, profile_pic,profile_pic2, name, phone, email) => {
      dispatch(updateUploadUserProfile(_id, profile_pic, profile_pic2, name, phone, email))
    },
    switchHome: () => {
      dispatch(switchHome());
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);