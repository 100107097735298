import React, { Component } from 'react';
import Style from "../Assigntask/assigntask.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { TextField, FormControl, MenuItem, Menu, Select, DialogTitle, Link } from "@mui/material";
import { Typography, Button } from '@mui/material';
import Paper from "@mui/material/Paper";
// import TableCell from '@mui/material/TableCell';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import BackgroundImg from "../../pages/image/BackgroundTaskplay 1.png";
import Startico from "../image/lucide_play-circle.png";
import { TableHeaderCell } from 'semantic-ui-react';
import CompleteTaskImg from "../image/completeImg.png";
import { styled } from '@mui/material/styles';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.#95C7B7,
      backgroundColor:"#95C7B7",

      color: "#2E384D",
    },
   
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "white",
    },
    '&:nth-of-type(even)': {
      backgroundColor: "#B5E4D5",
    },
  }));




class completetask extends Component {


    constructor(props) {
        super(props);

        this.state = {
            createSpace: false,
            isHidden: true,
            showAttempt: false,
            copied: false,
            spacedate: [

                {
                    taskname: "UI Design",
                    totalHour: "5hr",

                },
                {
                    taskname: "UI Design complete and another page start",
                    totalHour: "5hr",

                },
                {
                    taskname: "UI Design complete and another page start and finish",
                    totalHour: "5hr",

                },


            ]

        }

    }


    toggleElement = () => {
        this.setState((prevState) => ({
            isHidden: !prevState.isHidden,
        }));
    };
    render() {
        return (
            <div className='bg-img'>
                <Grid style={{ marginLeft:"10%",display: "flex", justifyContent: "space-between" }}>


                    <Grid style={{ marginLeft:"5%",paddingTop: "3%", width: "100%" }}>
                        <Grid style={{
                            marginLeft: "7%", width: "80%", borderRadius: "4px", boxShadow: "0px 0px 16px 0px #878787"
                        }}>
                            <Grid style={{ padding: "10px", background: "#1C352D", marginBottom: "0%", borderRadius: "4px 4px 0px 0px" }}>
                                <Typography style={{ color: "white", fontSize: "20px", fontWeight: "500" }}>
                                    Today's Task
                                </Typography>
                            </Grid>
                            <Grid style={{padding:"15px",background:"white",borderRadius: " 0px 0px 4px 4px "}}>

                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">

                                        <TableHead style={{ background: "#B5E4D5" ,height:"10px"}}>
                                            <TableRow>

                                                <StyledTableCell align='center'>Sr. No.</StyledTableCell>
                                                <StyledTableCell align='center'> Task Name</StyledTableCell>
                                                <StyledTableCell align='center' style={{ whiteSpace: "nowrap" }}>Total Hours</StyledTableCell>
                                                <StyledTableCell align='center'>Timeline</StyledTableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {/* timetaken row here  */}
                                            <TableRow>
                                                <StyledTableCell>1</StyledTableCell>
                                                <StyledTableCell>UI Design Complete and Research</StyledTableCell>
                                                <StyledTableCell>5hr</StyledTableCell>
                                                <StyledTableCell>
                                                    <button style={{ boxShadow: "0px 4px 4px 0px #00000040", border: "1px solid gray", outline: "none",textAlign:"center",display:"flex",flexDirection:"column", color: "black", background: "none", fontWeight: "bold", padding: "10px 7px",borderRadius:"3px" }}>
                                                        Time Taken
                                                        <button style={{ border: "none", outline: "none", color: "black",paddingTop:"8px", background: "none", fontWeight: "600" }}>
                                                            00.24.50
                                                        </button>
                                                    </button>
                                                </StyledTableCell>
                                            </TableRow>


                                            {this.state.spacedate.map((item, index) => {
                                                return (


                                                    // <TableRow  style={{background:"#EFFFFA",border:"1px solid lightgray"}}>
                                                    <StyledTableRow>

                                                        {/* <Link to="/user" className="table-row" style={{ display: "contents", textDecoration: "none" }}> */}
                                                        <StyledTableCell> {index + 1}</StyledTableCell>
                                                        <StyledTableCell> {item.taskname.substring(0, 13)+"....."}</StyledTableCell>
                                                        <StyledTableCell> {item.totalHour}</StyledTableCell>

                                                        <StyledTableCell>
                                                            <Button
                                                                onClick={this.handleButtonClick}
                                                                style={{ gap: "10px", textTransform: "capitalize", background: "#1C352D", padding: "12px", color: "white" }}>
                                                                <img src={Startico}
                                                                    className='img-focus'
                                                                    onClick={this.toggleElement} />
                                                                Start
                                                                {this.state.isHidden ? null :
                                                                    <button style={{ border: "none", outline: "none", color: "white", background: "none", fontWeight: "bold" }}>
                                                                        00.24.50
                                                                    </button>
                                                                }
                                                            </Button>
                                                        </StyledTableCell>


                                                        {/* </Link> */}
                                            </StyledTableRow>
                                                    


                                                )
                                            })}

                                        </TableBody>

                                    </Table>

                                </TableContainer>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/* right side part here  */}
                    <Grid style={{ width: "45%", height: "100vh", background: "white", padding: "12px" }}>

                        <Grid style={{  }}>
                            <Grid style={{ marginBottom: "9%",paddingTop: "19%" }}>
                                <img src={CompleteTaskImg} />
                            </Grid>
                            <Grid style={{padding:"17px",marginTop:"30px"}}>
                                <Typography  style={{textAlign:"center",padding:"17px"}}>
                                    Congratulations on completing the task!
                                    Your hard work and dedication have made a significant impact.
                                    Well done!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </div>
        );
    }
}

export default completetask;