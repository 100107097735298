import React, { Component } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import reactangle from "../image/Rectangle 8.png";
import CachedIcon from '@mui/icons-material/Cached';



class frontpage extends Component {
    render() {
        return (
            <div className='bg-img'>
                <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <Grid style={{ borderRadius: "2px", background: "white", height: '600px', display: 'flex', flexDirection: 'column', gap: '40px', justifyContent: 'center', boxShadow: '0px 0px 16px 0px #878787', alignItems: 'center', width: '600px', padding: '80px 100px' }}>
                        <img style={{ width: '403px', height: '285px' }} src={reactangle} alt="" />
                        <Typography style={{ color: '#2E384D', textAlign: 'center', fontSize: '16px', fontWeight: 400, lineHeight: '24px', }}>We haven't assigned a task to you yet. Stay tuned for new tasks in your queue!</Typography>

                        <a href='/assigntask' style={{ textDecoration: "none" }}>
                            <Typography style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                <CachedIcon style={{ height: '24px', width: '24px', padding: '3px' }} />
                                <Typography>Refresh</Typography>
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default frontpage;