import { InlineIcon } from '@iconify/react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Assets from "../pages/image/assest.png"
import Employee from "../pages/image/employee.png";
import logout from "../pages/image/logout.png"
import Project from "../pages/image/project.png";
// import Project2 from "../pages/image/receipt-2-b.png";
import Bprofile from "../pages/image/profile-2user-b.png";
import Breceipt from "../pages/image/receipt-2-b.png";
import Tickets from "../pages/image/ticket.png"
import Services from "../pages/image/service.png"
import Company from "../pages/image/companies.png";
import Profile from "../pages/image/profile-2user.png";
import { Style } from '@material-ui/icons';


export const all_admin_option = [
    // { id: 0, name: "Dashboard"  , icon: <DashboardOutlinedIcon />, link: "/" },
    { id: 1, name: "Companies", icon: <img src={Company} />, link: "/" },
    { id: 2, name: "Project", icon: <img src={Project} />, link: "/project" },
    { id: 3, name: "Services", icon: <img src={Services} />, link: "/service" },
    { id: 4, name: "Assets", icon: <img src={Assets} />, link: "/assets" },
    { id: 5, name: "Tickets", icon: <img src={Tickets} />, link: "/tickets" },
    { id: 6, name: "Employee", icon: <img src={Employee} />, link: "/Employee" },


];


export const all_emplyee_option = [
    // { id: 0, name: "Dashboard"  , icon: <DashboardOutlinedIcon />, link: "/" },
    // { id: 1, name: "Companies"  , icon: <img src={Company} />, link: "/" },
    { id: 2, name: "My space", icon: { i1: <img src={Project} />, i2: <img src={Breceipt} /> }, link: "/myspace" },

    // { id: 2, name: "My task", icon: { i1: <img src={Project} />, i2: <img src={Breceipt} /> }, link: "/assigntask" },
    { id: 3, name: "My profile", icon: { i1: <img src={Profile} />, i2: <img src={Bprofile} /> }, link: "/myprofile" },


];


// airport_shuttle

export const all_super_admin_options = [
    // { id: 0, name: "Dashboard", icon: "home", link: "/" },
    // { id: 6, name: "Admins", icon: "admin_panel_settings", link: "/adminuser" },
    // { id: 7, name: "Employees", icon: "people", link: "/employee" },
    // {id: 1, name: "Departments", icon: "meeting_room", link: "/department"},
    // {id: 2, name: "Event", icon: "event", link: "/event"},
    // {id: 3, name: "Speaker", icon: "speaker", link: "/speaker"},
    // {id: 3, name: "Applicants", icon: "person", link: "/applicants"},
    // {id: 4, name: "Participants", icon: "people", link: "/"},
];
export const all_customer_options = [
    // {id: 0, name: "Dashboard", icon: "home", link: "/"},
    // {id: 1, name: "Departments", icon: "meeting_room", link: "/department"},
    // {id: 2, name: "Event", icon: "event", link: "/event"},
    // {id: 3, name: "Dashboard", icon: "home", link: "/"},
    // {id: 4, name: "Participants", icon: "people", link: "/"},
];


