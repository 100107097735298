import React from "react";
// import Icon from "@mui/material/Icon";
import {
  // Link,
  Navigate,
} from "react-router-dom";
// import IconButton from "@mui/material/IconButton";
import "../../common/navbar/index.css";
// import { NavLink } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import {
  Button, Typography, DialogActions,
  DialogTitle, Grid
} from "@mui/material";
import "../styles/drawer.css";
import { Link } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import logout from "../../pages/image/logout.png"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
// import LogoutIcon from '@mui/icons-material/Logout';

export default class LG extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      view: false
    };
  }
  render() {
    const { login } = this.props;

    if (this.state.redirect === true) {
      return <Navigate to="/" />;
    }
    return (
      <Grid>
        {/* <IconButton */}
        {/* //   onClick={() => {
      //     localStorage.removeItem("qubi7_type");
      //     localStorage.removeItem("qubi7_name");
      //     localStorage.removeItem("qubi7_profile_pic");
      //     localStorage.removeItem("qubi7_user_id");
      //     localStorage.removeItem("qubi7_company_id");
      //     // localStorage.removeItem("taxopliance_organization_id");
      //     this.setState({redirect:true})
      //     this.props.onLogout()
      //   }}>
      //   <Icon style={{ color: "white" }}>power_settings_new</Icon>
      // </IconButton>

      <Link> */}
        <Button
         style={{ color: "white", fontSize: "12px", background: "#1C352D" }}
          onClick={() => {
            this.setState({ view: true });
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", justifyContent:'center', alignItems:"center" }}>
            <div>
              <LogoutIcon style={{ color: "white", fontSize: "20px", }} />
            </div>
            <div style={{marginLeft:"5px"}}>
              Log Out
            </div>
          </div>
        </Button>
        <Dialog
          open={this.state.view}
          onClose={() => {
            this.setState({ view: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%" }}
        >
          <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


            <center>
              <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
              <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
              <Typography style={{ fontSize: "18px" }}>You want to Logout</Typography >

            </center>
            <DialogActions style={{ marginTop: "40px" }}>

              <Button variant="contained"
                style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}

                onClick={() => { this.setState({ view: false }) }}>

                Cancel
              </Button>
              <Link to="/">

                <Button variant="contained"
                  style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                  onClick={() => {
                    localStorage.removeItem("mobile");
                    localStorage.removeItem("user_id");
                    localStorage.removeItem("email");
                    localStorage.removeItem("name");
                    localStorage.removeItem("org_id");
                    localStorage.removeItem("type");
                    this.props.onLogout();
                    this.setState({ view: false });
                  }}

                >
                  Confirm
                </Button>

              </Link>


            </DialogActions>
          </Grid>


          {/* <DialogActions
                style={{ marginTop: "12px" }}
              >
                <center>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#00A913",
                      marginRight: "30px",
                      // marginLeft: "-170%"
                    }}
                    onClick={() => {
                      // this.props.deleteModel(this.props.login.org._id, this.state.id);
                      this.setState({ delete: false });
                      this.props.onLogout();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#D82726", fontSize: "15px" }}
                    onClick={() => {
                      this.setState({ view: false });
                    }}
                  >
                    No
                  </Button>
                </center>
              </DialogActions>  */}
        </Dialog>
      </Grid>




    );
  }
}
