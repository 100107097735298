import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../Assigntask/Assigntask";
import {
  close_snack_bar
} from "../../common/snackbar/action";

import {
  view_all_task_assign,
  start_time,
  stop_task,
  view_task_by_id,
  mark_as_complete,
  view_all_task_assign2,
  view_all_space_task
} from "../Assigntask/action";
import {
  add_task
} from '../../pages/Task/action'
export class Controller extends Component {
  render() {
    return (
      <Service {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    task: store.task,
    space: store.space,
    snackbar: store.snackbar,
    login: store.login,
    assgn_tsk: store.assgn_tsk,
    myspace: store.myspace,
  };
};
export const mapDispatchToProps = dispatch => {
  return {

    add_task: (space_id, task_name, desc, start_date, dead_line, assign_to, assign_by, total_time, page_type) => {
      dispatch(add_task(space_id, task_name, desc, start_date, dead_line, assign_to, assign_by, total_time, page_type))
    },
    view_all_task_assign: (user_id) => {
      dispatch(view_all_task_assign(user_id))
    },
    view_all_task_assign2: (space_id, assign_to) => {
      dispatch(view_all_task_assign2(space_id, assign_to))
    },
    view_all_space_task: (space_id) => {
      dispatch(view_all_space_task(space_id))
    },

    stop_task: (stop_by, task_id, type, space_id) => {
      dispatch(stop_task(stop_by, task_id, type, space_id))
    },
    start_time: (start_by, task_id, type, space_id) => {
      dispatch(start_time(start_by, task_id, type, space_id))
    },
    mark_as_complete: (task_id, assign_to, type, space_id) => {
      dispatch(mark_as_complete(task_id, assign_to, type, space_id))
    },
    view_task_by_id: (task_id) => {
      dispatch(view_task_by_id(task_id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);