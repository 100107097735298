import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { green, pink } from '@mui/material/colors';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Drawer_option_con from "../containers/drawer_cont";
import LG_Cont from "../../router/containers/Lg_Cont";
// import "../styles/drawer.css";
import { useLocation } from 'react-router-dom'
import AdNav from "../../common/navbar/adNav";
import PMS from "../../pages/image/PMS.png";
import TMS from "../../pages/image/TMS.svg";
import "../../router/components/drawer2.css";
// import Logo from "../../pages/admin/images/logo.png"
import {
  all_admin_option,
  all_emplyee_option,
  all_super_admin_options,
  // all_corporate_options,
  // all_tourism_options
  // all_manager_options,

} from "../constant";
import { red } from '@mui/material/colors';
import { Avatar, Badge, Grid } from '@mui/material';
const drawerWidth = 170;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 50,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    // backgroundColor:'red',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const useStyles = (theme) => ({
  root: {
    display: "flex",

  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "#FF7000"
    background: "#fadde1"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    backgroundColor: "#11101d",

  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#11101d",


    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    // backgroundColor: '#FF7000',
    backgroundColor: "#11101d",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  iconStyle: {
    position: "relative",
    left: 8,
    top: 3,
    fontSize: 12,
    marginRight: 5,
    //   color:'red'
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  grow: {
    flexGrow: 1,
  },
  Icon: {
    //   color:"red",
    position: "relative",
    left: "10px"
  },

  ListItem: {
    border: "1px solid red",

    "&:hover": {
      borderRadius: "3px",
      backgroundColor: "#08019f",
      // color:'red'
    }
  }



});


export default function MiniDrawer(props) {
  const classes = useTheme();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  function sideBar(type, open) {
    if (type === "Owner") {
      return (
        all_admin_option.map((option) => (

          location.pathname === option.link ? <center
           style={{ background: 'rgb(255,255,255)', background: 'linear-gradient(102deg, rgba(255,255,255,1) 0%, rgba(205,159,255,1) 1%, rgba(122,0,255,1) 99%, rgba(252,248,255,1) 99%, rgba(252,248,255,1) 100%)', verticalAlign: 'middle' }}>
            <ListItemIcon >
              <Icon className="drawer_icon">{option.icon}</Icon>
            </ListItemIcon>

            <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

              <Typography style={{ fontSize: "12px", marginTop: "-30px" }}>
                {option.name}
              </Typography>

            </ListItem>
          </center>

            :
            <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}   >
              <center ><ListItemIcon >
                <Icon className="drawer_icon">{option.icon}</Icon>
              </ListItemIcon>

                <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

                  <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                    {option.name}
                  </Typography>

                </ListItem>
              </center>
            </Link>
        ))

      );
    }
    else if (type === "Viewer"|| type === "Editor") {
      return (
        <div style={{ marginTop: 1 }}>
          {all_emplyee_option.map((option) => (
            location.pathname === option.link ?
            <center style={{background:"white",borderRadius:"50px"}}>
            <Link key={option.link} to={option.link} style={{ textDecoration: "none", marginTop: "10px" }}   >
              <ListItem  style={{color:"#005555"}} button key={option.name} className="drawer_text">

                <ListItemIcon ><Icon className="drawer_icon_white">{option.icon.i2}</Icon></ListItemIcon>

                <span style={{color:"#005555"}} >{option.name}</span>
              </ListItem>
            </Link>
            </center>
            :
            <center>
              <Link key={option.link} to={option.link} style={{ textDecoration: "none", marginTop: "-50px" }}   >
              <ListItem style={{color:"white",marginTop: "19%"}} button key={option.name} className="drawer_text">

                <ListItemIcon ><Icon className="drawer_icon">{option.icon.i1}</Icon></ListItemIcon>

                <span style={{color:"white"}} >{option.name}</span>
              </ListItem>
            </Link>
            </center>
          ))}
        </div>
      );
    }
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar style={{ backgroundColor: "#005555",position: "fixed" ,}}>
        {/* <Toolbar style={{justifyContent:"right"}}>
          
          <img className='logo' style={{ height: "40px" }} src={PMS} />
        </Toolbar> */}
        <Drawer variant="permanent" open={true} >
        <Grid style={{ backgroundColor: '#005555', height: '100%', flexDirection: 'column', justifyContent: 'space-around', display: 'flex', alignItems: 'center' }}>
          {/* <center>
            <Avatar
              alt={localStorage.getItem("name")} src={props.login.user_details.name} 
              style={{ width: 60, height: 60 }} >
              {localStorage.getItem("name") == null ? "" : localStorage.getItem("name").substring(0, 1).toUpperCase()}
            </Avatar>
          </center> */}
          <img src={TMS}/>
          <List style={{paddingBottom:"0%"}}>
            {sideBar(props.type, open)}
          </List>
          {/* <center>
            <LG_Cont />
          </center> */}
          <center ></center>
        </Grid>
      </Drawer>
      </AppBar>
      
    </Box>
  );
}
