import React, { Component } from 'react'

import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
// import Paper from "@mui/material/Paper";
// import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
import PMS from "../../pages/image/PMS.png"
import "../../common/navbar/index.css"


export default class adNav extends Component {
    render() {
        return (
            <div>

                <AppBar className="navbar" style={{ backgroundColor: "white", width: "95%" }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>

                        
                        <img className='logo' style={{
                            height: "40px",
                            
                        }} src={PMS} />



                    </Toolbar>
                </AppBar>



            </div>
        )
    }
}
