import {
  
 VIEW_ALL_TASK,
    VIEW_ALL_TASK_BY_ID,
    VIEW_ALL_TASK_BY_EMP_ID,
    VIEW_ALL_TASK_BY_STATUS  ,
    SEARCH_TASK,
    VIEW_ALL_TASK_BY_SPACE,
    VIEW_ALL_TASK_BY_ORG,
    VIEW_ALL_SPACE_ORG
} from "./constant";
const initial_state = {
  view_task: [],
  view_task_by_id:[],
  view_task_by_emp_id:[],
  all_task:[],
  search_task:[],
  all_space:[],

 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
      case VIEW_ALL_TASK:
       
      return state = { ...state, view_task: action.payload };
        case VIEW_ALL_TASK_BY_ID:
      return state = { ...state, view_task_by_id: action.payload };
        case VIEW_ALL_TASK_BY_EMP_ID:
      return state = { ...state, view_task_by_emp_id: action.payload };
        case VIEW_ALL_TASK_BY_SPACE:
          
      return state = { ...state, all_task: action.payload };
        case VIEW_ALL_TASK_BY_ORG:
      return state = { ...state, all_task: action.payload };
        case VIEW_ALL_SPACE_ORG:
      return state = { ...state, all_space: action.payload };

      case SEARCH_TASK:
        return state = { ...state, search_task: action.payload };

    default:
      return state;
  }
}
