import React, { Component } from 'react';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import DoneIcon from '@mui/icons-material/Done';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Link } from 'react-router-dom';
import Pagination from "@mui/material/Pagination";

import { TextField, FormControl, MenuItem, Menu, Select, DialogTitle, OutlinedInput, Chip, Tooltip, } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from '@mui/icons-material/Add';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import InputLabel from '@mui/material/InputLabel';
import TableRow from '@mui/material/TableRow';
// import Backgroundtask from "../../pages/image/BackgroundTaskplay 1.png";
// import Style from "../../pages/User/user.css";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import profilePic from "../../../pages/image/Ellipse 4.svg";
import profilePic2 from "../../../pages/image/ellipse-boy.svg";
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
// import Dialog from "@mui/material/Dialog";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Avatar from '@mui/material/Avatar';
// import AddIcon from '@mui/icons-material/Add';
import AvatarGroup from '@mui/material/AvatarGroup';
import CloseIcon from '@mui/icons-material/Close';
import "../../../pages/Space/component/spacemain.css"
import Loader from '../../../common/loader/containers/loader_cont';
import InputAdornment from '@mui/material/InputAdornment';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default class spacemain extends Component {
    constructor(props) {
        super(props);

        this.state = {
            createSpace: false,
            copied: false,
            deletespace: false,
            isHidden: true,
            // selectedRow: null,
            spacedate: [

                {
                    spacename: "UI /UX Design",
                    description: "Our creative UI/UX experts, armed with top design tools, craft user-centric experiences, enhancing usability and aesthetics.",
                    email: "abc@technoboot.co",
                },
                {
                    spacename: "UI /UX Design",
                    description: "Our creative UI/UX experts, armed with top design tools, craft user-centric experiences, enhancing usability and aesthetics.",
                    email: "abc@technoboot.co",
                },
                {
                    spacename: "UI /UX Design",
                    description: "Our creative UI/UX experts, armed with top design tools, craft user-centric experiences, enhancing usability and aesthetics.",
                    email: "abc@technoboot.co",
                },

            ],
            page: 0,
            setPage: 0,
            rowsPerPage: 5,
            setRowsPerPage: "",
            personName: [],
            member_id: [],
            memebrId: "",
            space_id: "",
            task_name: "",
            task_desc: "",
            start_date: "",
            end_date: "",
            space_desc: ""

        }
    }

    toggleElement = () => {
        this.setState((prevState) => ({
            isHidden: !prevState.isHidden,
        }));
    };
    handleChange = (event) => {
        const { value } = event.target;

        this.setState({
            personName: typeof value === 'string' ? value.split(',') : value,
        });
    };
    handleChangeCal = (calvalue) => {
        ;
        this.setState({ calicon: false });
        const formattedDate = calvalue.toISOString().split('T')[0];
        this.setState({ calvalue: formattedDate });
    };
    handleChangeCal1 = (calvalue1) => {
        ;
        this.setState({ calicon1: false });
        const formattedDate = calvalue1.toISOString().split('T')[0];
        this.setState({ calvalue1: formattedDate });
    };
    addMemberid(new_value) {
        // Get the current valueArray from state
        const { member_id } = this.state;

        // Define the new value
        const newValue = new_value;

        // Check if the new value already exists in the array
        if (member_id.includes(newValue)) {
            // If it exists, remove it from the array
            const newValueArray = member_id.filter((value) => value !== newValue);
            this.setState({ member_id: newValueArray });
        } else {
            // If it doesn't exist, add it to the array
            const newValueArray = [...member_id, newValue];
            this.setState({ member_id: newValueArray });
        }
    };
    handleRowClick = (row) => {
        this.setState({ selectedRow: row });
    };
    componentDidMount() {
        // 
        // if (this.props.login.type === "Viewer") {
        //     this.props.view_all_space_asign(this.props.login.user_id);
        // }
        this.props.viewAllSpaces(this.props.login.org_id, 0, 5);
        this.props.viewAllMembers(this.props.login.org_id);
        

    }
    render() {
        const { calvalue, calvalue1 } = this.state;
        const today = moment().format('YYYY-MM-DD')
        const handleChangePage = (event, newPage) => {
            this.props.viewAllSpaces(this.props.login.org_id, newPage - 1, this.state.rowsPerPage);

            this.setState({
                page: newPage,
            });
        };
        // const rows = this.props.rows;
        // const { selectedRow } = this.state;
        return (
            <>
                <div className='bg'>
                    <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingBottom: 35 }} >
                        <Grid style={{ marginTop: "5%", padding: "30px", width: "80%", height: "auto", background: "white" }}>


                            <Grid style={{ display: "flex", marginBottom: "2%", flexDirection: "row", justifyContent: "space-between" }}>
                                <Grid >
                                    <Typography className='userrr' style={{}}>Spaces</Typography>
                                    {/* <Paper style={{ border: "1px solid gray" }}
                                        component="form"
                                        className='search-bar'
                                    >
                                        <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                                            <SearchIcon style={{ fontSize: "25px" }} />
                                        </IconButton>
                                        <InputBase
                                            style={{ ml: 5, flex: 1, fontSize: "19px" }}
                                            placeholder="Search User"
                                            inputProps={{ "aria-label": "Search User" }}
                                            onChange={(e) => {
                                                this.props.searchSpace(this.props.space.spaces, e.target.value)
                                            }}

                                        />
                                    </Paper> */}

                                </Grid>

                                <Grid>


                                    {/* <Button style={{ display: "flex", padding: "10px", borderRadius: "4px", background: "#1C352D", color: "white", }}
                                        onClick={() => { this.setState({ createSpace: true }); }}
                                    >
                                        <AddIcon style={{ color: "white" }} />
                                        <Typography  >Add Task</Typography>
                                    </Button> */}
                                    <Button style={{ borderRadius: "4px", background: "#1C352D", color: "white", }} variant="contained" startIcon={<AddIcon />}
                                        onClick={() => { this.setState({ addtask: true }); }}

                                    >
                                        Add Task
                                    </Button>

                                    <Button style={{ marginLeft: "20px", borderRadius: "4px", background: "#1C352D", color: "white", }} variant="contained" startIcon={<AddIcon />}
                                        onClick={() => { this.setState({ createSpace: true }); }}

                                    >
                                        Create Spaces
                                    </Button>

                                    {/* <Button style={{ display: "flex", padding: "10px", borderRadius: "4px", background: "#1C352D", color: "white", }}
                                        onClick={() => { this.setState({ createSpace: true }); }}
                                    >
                                        <AddIcon style={{ color: "white" }} />
                                        <Typography  >Create Spaces</Typography>
                                    </Button> */}
                                </Grid>
                            </Grid>

                            <Grid>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">

                                        <TableHead style={{ background: "#B5E4D5" }}>
                                            <TableRow >

                                                <TableCell align='center' style={{ color: "#2E384D", fontSize: "16px" }}>Space Name</TableCell>
                                                <TableCell align='center' style={{ color: "#2E384D", fontSize: "16px" }}> Description</TableCell>
                                                <TableCell align='center' style={{ color: "#2E384D", fontSize: "16px" }}>Team Members</TableCell>
                                                <TableCell align='center' style={{ color: "#2E384D", fontSize: "16px" }}>Action</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(this.props.space.serach_spaces) && this.props.space.serach_spaces.
                                                sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                                .map((item, index) => {
                                                    return (


                                                        <TableRow >
                                                            <Link to="/Spacenew" style={{ display: "contents", textDecoration: "none", cursor: "pointer" }}
                                                                onClick={(e) => {
                                                                    this.props.SetSpace(item)
                                                                }}
                                                            >
                                                                <TableCell align='center' className='userrr' style={{ whiteSpace: "nowrap" }}> {item.space_name}</TableCell>
                                                                <TableCell align='center' className="des">{item.desc}</TableCell>
                                                                <TableCell style={{ alignItems: "center", marginLeft: "-400px" }}>
                                                                    <center>
                                                                        <AvatarGroup
                                                                            renderSurplus={(surplus) => <span>+{surplus.toString()[0]}k</span>}
                                                                            total={Array.isArray(item.members) && item.members.length}
                                                                            align="center"
                                                                            max={Array.isArray(item.users) ? item.users.length > 2 ? item.users.length - 2 : item.users.length : 0} >
                                                                            {Array.isArray(item.users) && item.users.map((item1, index) => {

                                                                                return (

                                                                                    <Avatar src={item1?.profile_pic} />
                                                                                )
                                                                            })}
                                                                        </AvatarGroup>

                                                                    </center>
                                                                </TableCell>
                                                            </Link>

                                                            <TableCell align='center'>

                                                                <DeleteOutlineOutlinedIcon
                                                                    style={{ cursor: "pointer" }}

                                                                    onClick={() => {
                                                                        this.setState({
                                                                            space_id: item._id,
                                                                            deletespace: true,

                                                                        })
                                                                    }}



                                                                />
                                                            </TableCell>

                                                        </TableRow>


                                                    )
                                                })}

                                        </TableBody>

                                    </Table>

                                </TableContainer>
                            </Grid>
                        </Grid>



                    </Grid>
                    <Grid className="page-button">
                        <Pagination
                            count={parseInt(this.props.space.total_space / parseInt(this.state.rowsPerPage)) + 1}
                            siblingCount={0}
                            size="small"
                            className='pagination'
                            onChange={handleChangePage}

                        />
                    </Grid>

                    {/* --------------------user invite------------------ */}
                    <Dialog
                        // onClose={()=>this.setState({ editcat: false })}
                        open={this.state.createSpace} style={{}}>
                        <Grid style={{ width: "500px", marginBottom: "2%", padding: "10px" }}>


                            <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>


                                <Grid style={{ display: "flex", justifyContent: "space-between", paddingBottom: "3%" }}>
                                    <span></span>
                                    <CloseOutlinedIcon
                                        onClick={() => {
                                            this.setState({ createSpace: false });
                                        }}



                                    />
                                </Grid>

                                <Grid >
                                    <Typography style={{ fontSize: "20px", fontWeight: "500", paddingBottom: "10px" }}>
                                        Create Spaces
                                    </Typography>

                                </Grid>

                                <TextField id="standard-basic" variant="standard" placeholder='Space Name' style={{ width: "100%" }}
                                    onChange={(e) => {
                                        this.setState({ space_name: e.target.value })
                                    }}
                                />

                                <Grid style={{ marginTop: "3%", marginBottom: "2%" }}>
                                    <Typography style={{ fontSize: "17px", color: "lightgrey", fontWeight: "400", paddingBottom: "10px" }}>
                                        Description
                                    </Typography>
                                    <TextField id="standard-basic" variant="standard" style={{ width: "100%" }}
                                        onChange={(e) => {
                                            this.setState({ space_desc: e.target.value })
                                        }}
                                    />
                                </Grid>



                                <Grid style={{ marginTop: "3%", display: "flex" }}>
                                    <FormControl sx={{ m: 1, width: 800 }}>
                                        <InputLabel
                                            labelId="demo-multiple-chip-label" >Add Member</InputLabel>
                                        <Select
                                            style={{ width: "450px", }}


                                            labelId="demo-multiple-chip-label"
                                            id="demo-multiple-chip"
                                            multiple
                                            // variant='outlined'
                                            value={this.state.personName}
                                            onChange={(e) => {
                                                this.handleChange(e)

                                            }}
                                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {/* <MenuItem value={"All Batch"}>{"All Batch"}</MenuItem> */}
                                            {Array.isArray(this.props.space.all_members) && this.props.space.all_members.map((item) => (
                                                <MenuItem value={item.name} style={{ justifyContent: "space-between" }}
                                                    onClick={() => {
                                                        this.addMemberid(item._id)
                                                    }}
                                                >{item.name} {item.type === "Editor" ?


                                                    <Tooltip>
                                                        <IconButton>
                                                            <EditRoadIcon fontSize='20px' style={{ marginLeft: "5%", color: "green" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    :


                                                    <Tooltip>
                                                        <IconButton>

                                                            <VisibilityIcon fontSize='20px' style={{ color: "green" }} /> </IconButton>
                                                    </Tooltip>}

                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                    {/* <TextField id="standard-basic" variant="standard" placeholder='Add Member' style={{ width: "100%" }} >

                                    </TextField>
                                    <AddIcon onClick={this.toggleElement} style={{ cursor: "pointer" }} /> */}
                                </Grid>


                                {/* search member here  */}


                                <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "6%", marginBottom: "2%" }}>
                                    <span></span>
                                    <button
                                        disabled={this.state.space_name === "" || this.state.member_id.length === 0 || this.state.personName.length === 0 || this.state.space_desc === ""}
                                        style={{ fontSize: "18px", background: "#1C352D", color: 'white', padding: '10px', border: "none", outline: "none", borderRadius: "4px", cursor: "pointer" }}
                                        onClick={() => {

                                            this.setState({ createSpace: false, personName: [], member_id: [], space_name: "", space_desc: "" });
                                            this.props.addSpaces(this.props.login.org_id, this.state.space_name, this.state.member_id, this.state.space_desc,);
                                        }}
                                    >Create</button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Dialog>

                    {/* -------------------------Link copied------------------------------ */}

                    <Dialog
                        // onClose={()=>this.setState({ editcat: false })}
                        open={this.state.copied} style={{}}>
                        <Grid style={{}}>

                            <Typography style={{ border: "1.4px solid green", color: "green", background: "lightgreen", opacity: "1" }}
                                onClick={() => {
                                    this.setState({ copied: false });
                                }}
                            ><DoneIcon style={{ color: "green" }} />Link to copied</Typography>


                        </Grid>
                    </Dialog>
                    <Loader />
                </div>
                <Dialog
                    open={this.state.deletespace}
                    onClose={() => {
                        this.setState({ deletespace: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%", }}
                >


                    <Grid style={{ backgroundColor: "#F8F8F8", minWidth: "350px", borderRadius: "20px" }}>


                        <center>
                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "80px", color: "#FF0000" }} />
                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>

                        </center>


                        <Button variant="contained"
                            style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", height: "68px", width: "182px", color: "black", fontSize: "18px", marginTop: "6%" }}
                            onClick={() => { this.setState({ deletespace: false }) }}>

                            Cancel
                        </Button>
                        <Button variant="contained"
                            style={{ backgroundColor: "#ED3A3A", height: "68px", width: "182px", fontSize: "18px", marginTop: "6%" }}
                            onClick={() => {
                                this.setState({ deletespace: false })
                                this.props.deleteSpace(this.state.space_id, this.props.login.org_id)
                                // this.props.deleteCategory(this.state.category_id)
                            }}>

                            Confirm
                        </Button>



                    </Grid>
                </Dialog>
                <Dialog
                    open={this.state.addtask} style={{}}>
                    <Grid style={{ height: "450px", width: "600px", padding: 25 }}>


                        <Grid style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}>
                            <Typography style={{ fontSize: "18px", fontWeight: "500" }}>Assign Task</Typography>
                            <CloseIcon onClick={() => {
                                this.setState({ addtask: false });
                            }} />
                        </Grid>


                        <Grid style={{ display: "flex", flexDirection: "row", gap: 100 }}>

                            <TextField id="standard-basic" label="Task Name " variant="standard"
                                onChange={(e) => {
                                    this.setState({ task_name: e.target.value })
                                }}
                            ></TextField>
                            <TextField id="standard-basic" label="Notes" variant="standard" style={{ color: "black" }}
                                onChange={(e) => {
                                    this.setState({ task_desc: e.target.value })
                                }}
                            > </TextField>
                        </Grid>

                        <Grid style={{ display: "flex", flexDirection: "row", gap: 100, marginTop: "5%", width: "500px" }}>
                            <TextField
                                style={{ width: "185px" }}
                                id="input-with-icon-textfield"
                                value={calvalue}
                                type='date'
                                inputProps={{ min: today }}
                                // label="Start Date"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {/* <CalendarMonthIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => { this.setState({ calicon: true }); }}
                      /> */}
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={(e) => {
                                    this.setState({ start_date: e.target.value })
                                }}
                            ></TextField>


                            <TextField
                                style={{ width: "185px" }}

                                id="input-with-icon-textfield"
                                // label="Deadline Date"
                                type='date'
                                value={calvalue1}
                                inputProps={{ min: today }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={(e) => {
                                    this.setState({ end_date: e.target.value })
                                }}
                            />




                        </Grid>

                        <Grid style={{ display: "flex", flexDirection: "row", gap: 100, marginTop: "5%", width: "500px" }}>
                            <TextField id="standard-basic" label="Total Hours" variant="standard"
                                type='number'
                                onChange={(e) => {
                                    this.setState({ total_hrs: e.target.value })
                                }}
                            />
                            <TextField id="standard-basic" label="Select Space " variant="standard"
                             style={{ width: "185px" }}
                                select
                                value={this.state.space_id}
                                onChange={(e) => {
                                    this.setState({ space_id: e.target.value })
                                    this.props.viewSpaceMember(this.state.members)
                                }}
                            >
                                {Array.isArray(this.props.space.serach_spaces) && this.props.space.serach_spaces.map((item) => {
                                    return (
                                        <MenuItem value={item._id}
                                            onClick={() => {
                                                this.props.viewSpaceMember(item.members)
                                            }}
                                        >{item.space_name}</MenuItem>
                                    )
                                })
                                }
                            </TextField>
                        </Grid>
                        <Grid style={{ display: "flex", flexDirection: "row", gap: 100, marginTop: "5%", width: "500px" }}>
                            <TextField id="standard-basic" label="Select Member " variant="standard"
                              style={{ width: "185px" }}
                                select
                                value={this.state.memebrId}
                                onChange={(e) => {
                                    this.setState({ memebrId: e.target.value })
                                }}
                            >
                                {Array.isArray(this.props.space.space_member) && this.props.space.space_member.map((item) => {
                                    return (
                                        <MenuItem style={{ width: "200px" }} value={item._id}
                                        >{item.name}</MenuItem>
                                    )
                                })
                                }
                            </TextField>
                            {/* <TextField id="standard-basic" label="Total Hours" variant="standard"
                                onChange={(e) => {
                                    this.setState({ total_hrs: e.target.value })
                                }}
                            /> */}
                        </Grid>

                        <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "14%", width: "500px" }}>
                            <span></span>
                            <Button
                                disabled={this.state.memebrId === "" || this.state.space_id === "" || this.state.task_name === "" || this.state.task_desc === "" || this.state.start_date === "" || this.state.end_date === "" || this.state.total_hrs === ""}
                                onClick={() => {
                                    this.setState({ addtask: false, space_id: "", task_name: "", task_desc: "", start_date: "", end_date: "", memebrId: "", total_hrs: "", space_id: "" });
                                    this.props.add_task(this.state.space_id, this.state.task_name, this.state.task_desc, this.state.start_date, this.state.end_date, this.state.memebrId, this.props.login.user_id, this.state.total_hrs)
                                }}
                                size='small' variant='contained' style={{ backgroundColor: "#1C352D", fontSize: "14px", marginRight: "2%", textTransform: "capitalize", color: "white" }}

                            >Submit</Button>
                        </Grid>






                    </Grid>
                </Dialog>

            </>

        )
    }
}
