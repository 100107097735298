import React, { Component } from "react";
import { connect } from "react-redux";
import Company from './myspace';
import {
  close_snack_bar
} from "../../common/snackbar/action";

import {

  viewAllSpaces,
  viewAllMembers,
  addMember,
  addSpaces,
  deleteSpace,
  updateSpace,
  deleteMember,
  updateMember,
  view_all_space_asign,
  SetSpace
} from "./action";
import {
  viewSpaceMember,
}
  from "../../pages/Space/action"
  import {
    add_task
  } from '../../pages/Task/action'
// import{add_upload_project} from "../../Project/action"

export class Controller extends Component {
  render() {
    return (
      <Company {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {


    snackbar: store.snackbar,
    login: store.login,
    space: store.space,
    loader: store.loader,
    myspace: store.myspace,
  };
};
export const mapDispatchToProps = dispatch => {
  return {

    add_task: (space_id, task_name, desc, start_date, dead_line, assign_to, assign_by, total_time, page_type) => {
      dispatch(add_task(space_id, task_name, desc, start_date, dead_line, assign_to, assign_by, total_time, page_type))
    },
    viewAllSpaces: (org_id) => {
      dispatch(viewAllSpaces(org_id))
    },

    deleteSpace: (_id, org_id) => {
      dispatch(deleteSpace(_id, org_id))
    },
    deleteMember: (_id, org_id) => {
      dispatch(deleteMember(_id, org_id))
    },

    viewAllMembers: (org_id) => {
      dispatch(viewAllMembers(org_id))
    },
    addMember: (org_id, name, email_id, mobile, access) => {
      dispatch(addMember(org_id, name, email_id, mobile, access))
    },
    addSpaces: (org_id, space_name, members, desc) => {
      dispatch(addSpaces(org_id, space_name, members, desc))
    },
    updateSpace: (_id, space_name, members, desc, org_id) => {
      dispatch(updateSpace(_id, space_name, members, desc, org_id))
    },
    updateMember: (_id, name, email_id, mobile, org_id) => {
      dispatch(updateMember(_id, name, email_id, mobile, org_id))
    },
    view_all_space_asign: (user_id) => {
      dispatch(view_all_space_asign(user_id))
    },
    SetSpace: (payload) => {
      dispatch(SetSpace(payload))
    },
    viewSpaceMember: (members) => {
      dispatch(viewSpaceMember(members))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);