import {
    LOGIN,
    VIEW_ALL_MEMBERS,
    SET_PAGE,
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";
import 'firebase/storage';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import useRazorpay from "react-razorpay";
import {
    view_user_profile
} from "../../auth/actions";
var order_id = "";


export function uploadUserProfile(org_id, profile_pic, name, phone, email, emp_id, access) {
    return dispatch => {
        
        dispatch(setLoader(true));
        if (profile_pic !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + profile_pic + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, profile_pic);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (profile_pic) {
                        
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // 
                            dispatch(addMember(org_id, profile_pic, name, phone, email, emp_id, access));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(addMember(org_id, profile_pic, name, phone, email, emp_id, access));
        }
    }

}

export function updateUploadUserProfile(_id, profile_pic,profile_pic2, name, phone, email,) {
    return dispatch => {
        
        dispatch(setLoader(true));
        if (profile_pic !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + profile_pic + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, profile_pic);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (profile_pic) {
                        
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // 
                            dispatch(updateMember(_id, profile_pic, name, phone, email, ));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(updateMember(_id, profile_pic2, name, phone, email, ));
        }
    }

}
export function send_email_verfication(email, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "send-verification-email", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
                email: email,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message))
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message))
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllMembersPagination(org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_members_pagination", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // org_id: org_id,
            },
            body: JSON.stringify({
                org_id: org_id,
                rowper_page: rowper_page,
                page_number: page_number


            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                ;

                if (responseJson.status) {

                    // dispatch(setAdminLogin(responseJson.result.userdetails));
                    dispatch({ type: VIEW_ALL_MEMBERS, payload: { data: responseJson.result, length: responseJson.length } });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader(responseJson.result.item_category));
                } else {
                    dispatch({ type: VIEW_ALL_MEMBERS, payload: { data: [], length: 0 } })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function SetPage(payload) {
    return {
        type: SET_PAGE,
        payload: payload,
    };
}

export function addMember(org_id, profile_pic, name, phone, email, emp_id, access) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_members", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                org_id: org_id,
                name: name,
                email_id: email,
                mobile: phone,
                access: access,
                emp_id: emp_id,
                profile_pic: profile_pic


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllMembersPagination(org_id, 0, 10));
                } else {
                    dispatch(viewAllMembersPagination(org_id, 0, 10));
                }
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateMember(_id, profile_pic,  name, phone, email) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_members", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                _id: _id,
                name: name,
                email_id: email,
                mobile: phone,
                profile_pic: profile_pic


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_user_profile(_id));
                } else {
                    dispatch(view_user_profile(_id));
                }
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}