import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import Reducer from './src/screens/redux/Reducers';
import login from './auth/reducer';
import snackbar from './common/snackbar/reducer';
import loader from './common/loader/reducer';

import space from './pages/Space/reducer';
import task from "./pages/Task/reducer"
import user from "./pages/User/reducer";
import assgn_tsk from "./pages/Assigntask/reducer";
import myspace from "./pages/Myspace/reducer";

// import authperson from "./pages/Phase/Auth_Person/reducer";
// import testcase from "./pages/admin/Test_Case/reducer";







const persistConfig = {
  key: 'root',
  storage,
}

//import banner from "./src/screens/Home/Reducers";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
  user,
  snackbar,
  loader,
  login,
  space,
  task,
  assgn_tsk,
  myspace,

})

const persistedReducer = persistReducer(persistConfig, reducer)
const configureStore = (initialState) => createStoreWithMiddleware(persistedReducer, initialState);
const store = configureStore();
// const persistor = persistStore(store)
// let store = createStore(persistedReducer)
let persistor = persistStore(store)
export default { store, persistor }

