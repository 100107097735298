import React, { Component } from 'react';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import DoneIcon from '@mui/icons-material/Done';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Pagination from "@mui/material/Pagination";
import { TextField, FormControl, MenuItem, Menu, Select, DialogTitle, DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Backgroundtask from "../../pages/image/BackgroundTaskplay 1.png";
import Style from "../../User/Components/user.css";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import profilePic from "../../../pages/image/Ellipse 4.svg";
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ModeEditOutlineOutlined } from "@mui/icons-material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import { Link } from "react-router-dom";
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Camera from '../../image/camera.png';
import Loder from '../../../common/loader/containers/loader_cont'
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import InputBase from "@mui/material/InputBase";

class user extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editUser: false,
            inviteUser: false,
            copied: false,
            access: "",
            userdelete: false,
            rows: [
                {
                    name: "Frozen yoghurt",
                    User_ID: 159,
                    Status: "Active",
                    Due_Tasks: 24,
                },

                {
                    name: "Eclair",
                    User_ID: 262,
                    Status: "Active",
                    Due_Tasks: 24,
                },
                {
                    name: "Cupcake",
                    User_ID: 305,
                    Status: "InActive",
                    Due_Tasks: 67,
                },

            ],
            userdata: [

                {
                    username: "Nibedita Rana",
                    nickname: "TB230404009",
                    email: "abc@technoboot.co",
                },
                {
                    username: "Swetalin Pradhan",
                    nickname: "TB230404008",
                    email: "abc@technoboot.co",
                },
                {
                    username: "Subhashree Sahu",
                    nickname: "TB230404007",
                    email: "abc@technoboot.co",
                },

            ],
            page: 0,
            setPage: 0,
            rowsPerPage: 10,
            setRowsPerPage: "",
            profile_pic: "",
            name: "",
            email: "",
            phone: "",
            emp_id: "",
            pstatus: true,
            emailValid: false,

        }
    }
    componentDidMount() {
        this.props.viewAllMembersPagination(this.props.login.org_id, 0, 10);
        ;

    }
    handleChange(event) {
        this.setState({ access: event.target.value }); // Update the 'age' state
    }
    handleEmailChange = (event) => {
        const email = event.target.value;
        // Basic email pattern matching (you can use a more complex regex for validation)
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        this.setState({ emailValid: isValid });
    };
    render() {

        const handleChangePage = (event, newPage) => {
            this.props.viewAllMembersPagination(this.props.login.org_id, newPage - 1, this.state.rowsPerPage);

            this.setState({
                page: newPage,
            });
        };
        const { rows } = this.state;
        return (
            <>
                <div className='bg'>
                    <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: 45 }} >
                        <Grid style={{ marginTop: "5%", padding: "30px", width: "80%", height: "auto", background: "white" }}>





                            {/* <Typography className='userrr1'>Past Users:-</Typography>
                        <Typography className='userrr1'>Present Users:-</Typography> */}
                            <Grid style={{ display: "flex", justifyContent: "space-between", marginBottom: "2%", marginTop: "20px" }}>
                                <Grid style={{ display: "flex", justifyContent: "space-around", alignItems: "center", gap: "20px" }}>
                                    <Typography className='userrr'>Users</Typography>
                                    {/* <Paper style={{ border: "1px solid gray" }}
                                        component="form"
                                        className='search-bar'
                                    >
                                        <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                                            <SearchIcon style={{ fontSize: "25px" }} />
                                        </IconButton>
                                        <InputBase
                                            style={{ ml: 5, flex: 1, fontSize: "19px" }}
                                            placeholder="Search User"
                                            inputProps={{ "aria-label": "Search User" }}

                                        />
                                    </Paper> */}

                                </Grid>

                                {this.state.pstatus && <Box style={{ display: "flex", padding: "10px", background: "#1C352D", borderRadius: "4px", color: "white", cursor: "pointer" }}
                                    onClick={() => { this.setState({ inviteUser: true }); }}
                                >
                                    <PermIdentityOutlinedIcon style={{ color: "white" }} />
                                    <Typography  >Add Users</Typography>
                                </Box>}



                            </Grid>




                            <Tabs>
                                <TabList style={{ backgroundColor: '#B5E4D5', width: "26%", whiteSpace: "nowrap", marginLeft: "auto", marginRight: "auto", borderRadius: "5px" }}>
                                    <Tab style={{ fontWeight: 600, fontSize: "18px" }}
                                        onClick={() => {
                                            this.setState({ pstatus: true })
                                        }}
                                    >Present Users</Tab>
                                    <Tab style={{ fontWeight: 600, fontSize: "18px", paddingleft: "10px" }}
                                        onClick={() => {
                                            this.setState({ pstatus: false })
                                        }}
                                    >Past Users</Tab>
                                </TabList>
                                <TabPanel>

                                    <Box>
                                        <TableContainer component={Paper}>
                                            <Table
                                                sx={{ minWidth: 650, borderRadius: " 8px", marginTop: "2%" }}
                                                size="small"
                                                aria-label="a dense table"
                                            >
                                                <TableHead
                                                    sx={{
                                                        padding: "24px 16px",
                                                        // textAlign:"center",
                                                        backgroundColor: "#B5E4D5",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            // align="left"
                                                            sx={{
                                                                // padding: "24px 90px",
                                                            }}
                                                        >
                                                            <ListItem>
                                                                <ListItemAvatar>
                                                                    {/* <Avatar/> */}
                                                                </ListItemAvatar>
                                                                <ListItemText>User</ListItemText>
                                                            </ListItem>
                                                            {/* User */}
                                                        </TableCell>
                                                        <TableCell align="center">Email ID</TableCell>
                                                        <TableCell align="center">Status</TableCell>
                                                        <TableCell align="center">Due Tasks</TableCell>
                                                        {/* <TableCell align="center">Actions</TableCell> */}
                                                        {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Array.isArray(this.props.user.user) && this.props.user.user.filter(item => item.access).map((row, index) => (
                                                        //  <Link to="/Dasdmodal">

                                                        <TableRow
                                                            key={row.name}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": { border: 0 },
                                                                cursor: "pointer",
                                                                textAlign: "center",
                                                                backgroundColor:
                                                                    index % 2 == 0 ? "rgba(213, 213, 213, 0.25)" : null,
                                                            }}
                                                        >
                                                            <Link
                                                                to="/Dasdmodal"
                                                                style={{
                                                                    textDecoration: "none",
                                                                    display: "contents",
                                                                }}
                                                                onClick={() => {
                                                                    this.props.SetMember(row)
                                                                    this.props.SetPage("U")
                                                                }}
                                                            >
                                                                <TableCell
                                                                    // align="center"
                                                                    sx={
                                                                        {

                                                                            // padding:"11px 16px"
                                                                        }
                                                                    }
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <ListItem>
                                                                        <ListItemAvatar>
                                                                            <Avatar src={row.profile_pic} />
                                                                            {/* <ImageIcon /> */}
                                                                            {/* </Avatar> */}
                                                                        </ListItemAvatar>
                                                                        <ListItemText primary={row.name} />
                                                                    </ListItem>
                                                                </TableCell>

                                                                <TableCell
                                                                    sx={
                                                                        {
                                                                            // padding:"11px 16px"
                                                                        }
                                                                    }
                                                                    align="center"
                                                                >
                                                                    {row.email_id}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={
                                                                        {
                                                                            // padding:"11px 16px"
                                                                        }
                                                                    }
                                                                    align="center"
                                                                >
                                                                    <Button
                                                                        variant="contained"
                                                                        style={{
                                                                            backgroundColor: "#B5E4D5",
                                                                            borderColor: "black",
                                                                            color: "black",
                                                                        }}
                                                                    >
                                                                        {Array.isArray(row.task) ? (row.task.filter(item => item.status === "start").length > 0 ? "Active" : "In-Active") : "In-Active"}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={
                                                                        {
                                                                            // padding:"11px 16px"
                                                                        }
                                                                    }
                                                                >
                                                                    <ListItemAvatar align="center">

                                                                        <Avatar
                                                                            className='blink-animate'
                                                                            style={{
                                                                                backgroundColor: "#B5E4D5",
                                                                                color: "red",
                                                                                fontWeight: 700,
                                                                                fontSize: '20px',
                                                                                animation: 'blinker 1s linear infinit'
                                                                            }}
                                                                        >
                                                                            {row.task ? (row.task.filter(e => (e.status !== "completed"))).length : 0}
                                                                        </Avatar>

                                                                    </ListItemAvatar>
                                                                </TableCell> </Link>
                                                            {/* <Link
                                                                to=""
                                                                style={{
                                                                    textDecoration: "none",
                                                                    display: "contents",
                                                                }}>
                                                                <TableCell sx={{
                                                                    
                                                                }} align="right">
                                                                    
                                                                    <ModeEditOutlineOutlined
                                                                        style={{
                                                                            color: "green",
                                                                            // border: "1px solid green",
                                                                            // borderRadius: "50px",
                                                                            // padding: "5px",
                                                                            fontSize: "25px",
                                                                        }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                editUser: true,

                                                                            });
                                                                        }}
                                                                    />
                                                                     <DeleteOutlineOutlinedIcon
                                                                        style={{
                                                                            color: "red",
                                                                            // border: "1px solid red",
                                                                            // borderRadius: "50px",
                                                                            // padding: "5px",
                                                                            fontSize: "25px",
                                                                            marginLeft:"10px",
                                                                        }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                userdelete: true,

                                                                            });
                                                                        }}
                                                                    />
                                                                    
                                                                    
                                                                    
                                                                    
                                                                    </TableCell></Link> */}

                                                        </TableRow>

                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>

                                </TabPanel>
                                <TabPanel>

                                    <Box>
                                        <TableContainer component={Paper}>
                                            <Table
                                                sx={{ minWidth: 650, borderRadius: " 8px", marginTop: "2%" }}
                                                size="small"
                                                aria-label="a dense table"
                                            >
                                                <TableHead
                                                    sx={{
                                                        padding: "24px 16px",
                                                        // textAlign:"center",
                                                        backgroundColor: "#B5E4D5",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            // align="left"
                                                            sx={{
                                                                // padding: "24px 90px",
                                                            }}
                                                        >
                                                            <ListItem>
                                                                <ListItemAvatar>
                                                                    {/* <Avatar/> */}
                                                                </ListItemAvatar>
                                                                <ListItemText>User</ListItemText>
                                                            </ListItem>
                                                            {/* User */}
                                                        </TableCell>
                                                        <TableCell align="center">Email ID</TableCell>
                                                        <TableCell align="center">Status</TableCell>
                                                        <TableCell align="center">Due Tasks</TableCell>
                                                        {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Array.isArray(this.props.user.user) && this.props.user.user.filter(item => item.access === false).
                                                        sort((a, b) => parseInt((b.task.filter(e => (e.status !== "completed" && moment().isAfter(e.dead_line)))).length) - parseInt((a.task.filter(e => (e.status !== "completed" && moment().isAfter(e.dead_line)))).length))
                                                        .map((row, index) => (
                                                            //  <Link to="/Dasdmodal">

                                                            <TableRow
                                                                key={row.name}
                                                                sx={{
                                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                                    cursor: "pointer",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        index % 2 == 0 ? "rgba(213, 213, 213, 0.25)" : null,
                                                                }}
                                                            >
                                                                <Link
                                                                    to="/Dasdmodal"
                                                                    style={{
                                                                        textDecoration: "none",
                                                                        display: "contents",
                                                                    }}
                                                                    onClick={() => {
                                                                        this.props.SetMember(row)
                                                                        this.props.SetPage("U")

                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        // align="center"
                                                                        sx={
                                                                            {

                                                                                // padding:"11px 16px"
                                                                            }
                                                                        }
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <ListItem>
                                                                            <ListItemAvatar>
                                                                                <Avatar src={row.profile_pic} />
                                                                                {/* <ImageIcon /> */}
                                                                                {/* </Avatar> */}
                                                                            </ListItemAvatar>
                                                                            <ListItemText primary={row.name} />
                                                                        </ListItem>
                                                                    </TableCell>

                                                                    <TableCell
                                                                        sx={
                                                                            {
                                                                                // padding:"11px 16px"
                                                                            }
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        {row.email_id}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        sx={
                                                                            {
                                                                                // padding:"11px 16px"
                                                                            }
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        <Button
                                                                            variant="contained"
                                                                            style={{
                                                                                backgroundColor: "#B5E4D5",
                                                                                borderColor: "black",
                                                                                color: "black",
                                                                            }}
                                                                        >
                                                                            {Array.isArray(row.task) ? (row.task.filter(item => item.status === "start").length > 0 ? "Active" : "In-Active") : "In-Active"}                                                                        </Button>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        sx={
                                                                            {
                                                                                // padding:"11px 16px"
                                                                            }
                                                                        }
                                                                    >
                                                                        <ListItemAvatar align="center">

                                                                            <Avatar
                                                                                className='blink-animate'
                                                                                style={{
                                                                                    backgroundColor: "#B5E4D5",
                                                                                    color: "red",
                                                                                    fontWeight: 700,
                                                                                    fontSize: '20px',
                                                                                    animation: 'blinker 1s linear infinit'
                                                                                }}
                                                                            >
                                                                                {row.task ? (row.task.filter(e => (e.status !== "completed" && moment().isAfter(e.dead_line)))).length : 0}
                                                                            </Avatar>

                                                                        </ListItemAvatar>
                                                                    </TableCell>
                                                                    {/* <TableCell sx={{
padding:"11px 16px"
}} align="right">{row.protein}</TableCell> */}
                                                                </Link>
                                                            </TableRow>

                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>

                                </TabPanel>
                            </Tabs>
                        </Grid>


                    </Grid>

                    <Grid className="page-button">
                        <Pagination
                            count={parseInt(this.props.user.user_length / parseInt(this.state.rowsPerPage))}
                            siblingCount={0}
                            size="small"
                            className='pagination'
                            onChange={handleChangePage}

                        />
                    </Grid>

                    {/* --------------------user invite------------------ */}
                    <Dialog
                        // onClose={()=>this.setState({ editcat: false })}
                        open={this.state.inviteUser} style={{}}>
                        <Grid style={{ width: "500px", marginBottom: "2%", padding: "10px" }}>


                            <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>


                                <Grid style={{ display: "flex", justifyContent: "space-between", paddingBottom: "3%" }}>
                                    <span></span>
                                    <CloseOutlinedIcon
                                        onClick={() => {
                                            this.setState({ inviteUser: false });
                                        }}

                                        className="eventcross-icon"

                                    />
                                </Grid>

                                <Grid >
                                    <Typography style={{ fontSize: "17px", fontWeight: "500", paddingBottom: "10px" }}>
                                        Add to Workspaces
                                    </Typography>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                                        <Avatar variant="soft"
                                            style={{
                                                height: "60px",
                                                width: "60px",
                                                borderRadius: "50%"
                                            }}
                                        >
                                            <center>
                                                <input style={{ display: "none", cursor: "pointer" }} type="file" id="file"
                                                    accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            profile_pic: e.target.files[0]
                                                        })
                                                    }}
                                                />
                                                <label htmlFor="file">
                                                    {/* <CameraAltIcon /> */}
                                                    <img
                                                        src={this.state.profile_pic !== "" ? URL.createObjectURL(this.state.profile_pic) : Camera}
                                                        style={{
                                                            height: "60px",
                                                            width: "60px",
                                                            alignItems: "center",
                                                            borderRadius: "50%"
                                                        }}
                                                    />
                                                </label>
                                            </center>
                                        </Avatar>
                                        <span style={{ color: "var(--primery, #006)", fontSize: "0.85rem" }}>
                                            {this.state.profile_pic !== "" ? (this.state.profile_pic).name : "Upload"}
                                        </span>
                                    </div>
                                    <TextField id="standard-basic" variant="standard" placeholder='Enter email address ' style={{ width: "100%", margin: "10px" }}

                                        onChange={(e) => {
                                            this.handleEmailChange(e)
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                    <TextField id="standard-basic" variant="standard" placeholder='Enter Name' style={{ width: "100%", margin: "10px" }}

                                        onChange={(e) => {
                                            this.setState({ name: e.target.value })
                                        }}
                                    />
                                    <TextField id="standard-basic" variant="standard" placeholder='Enter Emp id' style={{ width: "100%", margin: "10px" }}

                                        onChange={(e) => {
                                            this.setState({ emp_id: e.target.value })
                                        }}
                                    />

                                    <FormControl style={{ margin: "10px" }} fullWidth>
                                        <InputLabel id="demo-simple-select-label">access</InputLabel>
                                        <Select
                                            Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.access}
                                            label="access"
                                            onChange={(e) => {
                                                this.setState({ access: e.target.value })
                                            }}
                                        // onChange={this.handleChange}
                                        >
                                            <MenuItem value="Viewer">Viewer</MenuItem>
                                            <MenuItem value="Editor">Editor</MenuItem>
                                            {/* <MenuItem value={30}>Thirty</MenuItem> */}
                                        </Select>
                                    </FormControl>

                                    <Grid style={{ padding: "19px", display: "flex", justifyContent: "space-between" }}>

                                        <Button style={{ margin: "10px", color: "#1C352D", border: "1px solid #1C352D" }} variant="outlined"
                                            onClick={() => {
                                                this.setState({ inviteUser: false })
                                            }}
                                        >Cancel</Button>
                                        <Button style={{ margin: "10px", backgroundColor: "#1C352D" }} variant="contained"
                                            disabled={this.state.email === "" || this.state.name == "" || this.state.access === ""}
                                            onClick={() => {
                                                if (this.state.emailValid) {
                                                    this.setState({ inviteUser: false })
                                                    this.props.uploadUserProfile(this.props.login.org_id, this.state.profile_pic, this.state.name, this.state.phone, this.state.email, this.state.emp_id, this.state.access)
                                                    this.setState({ profile_pic: "", name: "", email: "", access: "", emp_id: "", phone: "" })
                                                }
                                                else {
                                                    alert('Please enter a valid email!')
                                                }
                                            }}
                                        >Submit</Button>
                                    </Grid>
                                </Grid>

                                {/* <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "3%", paddingBottom: "3%" }}>
                                    <Typography>Invite someone to this Workspace with a link:</Typography>
                                    <Box style={{ display: "flex", padding: "10px", background: "#DCDEDC", color: "#2F5247", cursor: "pointer", borderRadius: "5px" }}>
                                        <ContentCopyOutlinedIcon />
                                        <Typography onClick={() => {
                                            this.setState({ inviteUser: false, email: "" })
                                            this.props.send_email_verfication(this.state.email, this.props.login.org_id)
                                        }}>Send Link</Typography>
                                    </Box>
                                </Grid> */}




                            </Grid>

                        </Grid>
                    </Dialog>

                    {/* -------------------------Link copied------------------------------ */}

                    <Dialog
                        // onClose={()=>this.setState({ editcat: false })}
                        open={this.state.copied} style={{}}>
                        <Grid style={{}}>

                            <Typography style={{ border: "1.4px solid green", color: "green", background: "lightgreen", opacity: "1" }}
                                onClick={() => {
                                    this.setState({ copied: false });
                                }}
                            ><DoneIcon style={{ color: "green" }} />Link to copied</Typography>


                        </Grid>
                    </Dialog>
                    <Loder />
                </div>
            </>
        );
    }
}

export default user;
