import { Grid, Card, Typography, Button, Box, MenuItem, Menu, OutlinedInput, Chip, Select } from '@mui/material'
import React, { Component } from 'react';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AddIcon from '@mui/icons-material/Add';
import "../../../pages/Space/component/spacenew.css";
import Pagination from "@mui/material/Pagination";
// import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import FormControl from '@mui/material/FormControl';
import Multiselect from 'multiselect-react-dropdown';

// import Select from 'react-select';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import moment from 'moment';
import Loader from '../../../common/loader/containers/loader_cont';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, "Active", 24, 4.0),
    createData('Ice cream sandwich', 237, "Inactive", 37, 4.3),
    createData('Eclair', 262, "Active", 24, 6.0),
    createData('Cupcake', 305, "Inactive", 67, 4.3),
    createData('Gingerbread', 356, " Active", 49, 3.9),
];

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
export default class spacenew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addtask: false,
            isHidden: true,
            calicon: false,
            value: new Date(),
            selectedOptions: [],
            personName: [],
            member_id: [],
            memebrId: "",
            space_id: "",
            task_name: "",
            task_desc: "",
            start_date: "",
            end_date: "",
            assign: false,
        }
    }


    handleChange = (event) => {
        const { value } = event.target;

        this.setState({
            personName: typeof value === 'string' ? value.split(',') : value,
        });
    };
    addMemberid(new_value) {
        // Get the current valueArray from state
        const { member_id } = this.state;

        // Define the new value
        const newValue = new_value;

        // Check if the new value already exists in the array
        if (member_id.includes(newValue)) {
            // If it exists, remove it from the array
            const newValueArray = member_id.filter((value) => value !== newValue);
            this.setState({ member_id: newValueArray });
        } else {
            // If it doesn't exist, add it to the array
            const newValueArray = [...member_id, newValue];
            this.setState({ member_id: newValueArray });
        }
    };

    handleSelectChange = (selectedOptions) => {
        this.setState({ selectedOptions });
    };

    toggleElement = () => {
        this.setState((prevState) => ({
            isHidden: !prevState.isHidden,
        }));
    };

    handleChangeCal = (calvalue) => {
        ;
        this.setState({ calicon: false });
        const formattedDate = calvalue.toISOString().split('T')[0];
        this.setState({ calvalue: formattedDate });
    };
    handleChangeCal1 = (calvalue1) => {
        ;
        this.setState({ calicon1: false });
        const formattedDate = calvalue1.toISOString().split('T')[0];
        this.setState({ calvalue1: formattedDate });
    };
    componentDidMount() {

        this.props.viewAllMembers(this.props.login.org_id);
        this.props.view_space(this.props.space.space_details._id);
        console.log(this.props.space.space_details)
    }

    render() {
        const { calvalue, calvalue1 } = this.state;
        const { selectedOptions } = this.state;
        const today = moment().format('YYYY-MM-DD');
        const options = [
            { value: 'option1', label: 'Option 1' },
            { value: 'option2', label: 'Option 2' },
            { value: 'option3', label: 'Option 3' },
            { value: 'option4', label: 'Option 4' },
        ];

        const customStyles = {
            control: (base) => ({
                ...base,
                width: 50, // Set the desired width here
            }),
        };
        return (

            <Grid  >
                <Grid className='bgimg'>
                    <Grid style={{ marginLeft: "5%", marginRight: "5%", padding: 10 }}>
                        <Card style={{ marginTop: "5%", }}>
                            <Grid style={{ display: "flex", marginBottom: "1%", justifyContent: "space-between", alignItems: "center", padding: 5, flexDirection: "row" }}>


                                <Grid style={{ display: "flex", marginTop: "1%", }}>

                                    <Typography className='userrr' style={{ textAlign: "center", marginLeft: "%", width: "200px" }}>{this.props.space.space_details.space_name}</Typography>
                                    {/* <Paper
                                        component="form"
                                        style={{ display: "flex", width: "300px", height: "43px", backgroundColor: "#fff", boxShadow: "none", border: "1px solid gray" }}
                                    >
                                        <IconButton
                                            type="button"
                                            style={{ p: "150px" }}
                                            aria-label="search"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                        <InputBase
                                            style={{ ml: 5, flex: 1, fontSize: "15px" }}
                                            placeholder="Search User"
                                            inputProps={{ "aria-label": "Search here" }}
                                        />
                                    </Paper> */}
                                </Grid>
                                {/* 
                            <Tabs>
                                <TabList className='tab-list' style={{ whiteSpace: "nowrap" }}>
                                    <Tab ><Button className='tab-list-btn' size='small'>Daily</Button></Tab>
                                    <Tab style={{ marginLeft: "2%" }} ><Button className='tab-list-btn' size='small'>Weekly</Button></Tab>
                                    <Tab style={{ marginLeft: "2%" }}> <Button className='tab-list-btn' size='small'>Monthly</Button></Tab>

                                </TabList>
                                <TabPanel>

                                </TabPanel>
                            </Tabs> */}

                                <Grid>
                                    <Button style={{ borderRadius: "4px", background: "#1C352D", color: "white", }} variant="contained" startIcon={<AddIcon />}
                                        onClick={() => { this.setState({ assign: true }); }}
                                    >
                                        Assign Member
                                    </Button>
                                    <Button style={{ borderRadius: "4px", background: "#1C352D", color: "white", marginLeft: "20px" }} variant="contained" startIcon={<AddIcon />}
                                        onClick={() => { this.setState({ addtask: true }); }}
                                    >
                                        Add Task
                                    </Button>

                                </Grid>
                            </Grid>


                            <Box>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead sx={{
                                            padding: "24px 16px",
                                            backgroundColor: "#B5E4D5"
                                        }}>
                                            <TableRow >

                                                <TableCell
                                                    // align="center"
                                                    sx={{
                                                        padding: "24px 40px",
                                                    }}>User</TableCell>
                                                <TableCell align="center">User ID</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                                <TableCell align="center">Due Tasks</TableCell>
                                                {/* <TableCell align="center">Protein&nbsp;(g)</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(this.props.space.space_details.users) && this.props.space.space_details.users.map((row, index) => (
                                                <TableRow
                                                    key={row?.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", backgroundColor: index % 2 == 0 ? "rgba(213, 213, 213, 0.25)" : null }}
                                                ><Link to="/Dasdmodal" style={{ textDecoration: "none", display: "contents" }}
                                                    onClick={() => {
                                                        this.props.SetMember(row)
                                                        this.props.SetPage("")
                                                    }}
                                                >
                                                        <TableCell
                                                            // align="center"
                                                            sx={{
                                                                // padding:"11px 16px"
                                                            }} component="th" scope="row">

                                                            <ListItem >
                                                                <ListItemAvatar >
                                                                    <Avatar src={row?.profile_pic}>
                                                                        {/* <ImageIcon /> */}
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={row?.name}

                                                                />
                                                            </ListItem>


                                                        </TableCell>
                                                        <TableCell sx={{
                                                            // padding:"11px 16px"
                                                        }} align="center">{row?.email_id}</TableCell>
                                                        <TableCell sx={{
                                                            // padding:"11px 16px"
                                                        }} align="center" ><Button variant='contained' style={{ color: "#000", backgroundColor: "#B5E4D5" }}>{Array.isArray(row?.task_number) ? (row.task_number.filter(item => item.status && item.status === "start").length > 0 ? "Active" : "In-Active") : "In-Active"}</Button></TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                // padding:"11px 16px"
                                                            }}><ListItemAvatar align="center">
                                                                <Avatar
                                                                    // className="circle one"
                                                                    className="blink-animate"
                                                                    style={{ backgroundColor: "#FCBCA8", color: "#8A2100", fontSize: "20px" }}>
                                                                    {/* {row.task_number} */}
                                                                    {Array.isArray(row?.task_number) ? (row?.task_number.filter(e => (e.status && e.status !== "completed"))).length : 0}

                                                                </Avatar>
                                                            </ListItemAvatar></TableCell>
                                                        {/* <TableCell sx={{
                    padding:"11px 16px"
                  }} align="right">{row.protein}</TableCell> */}
                                                    </Link> </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Card>
                    </Grid>


                    {/* <Grid className="page-button">
                        <Pagination
                            count={10}
                            siblingCount={0}
                            size="small"
                            className='pagination'
                        />
                    </Grid> */}
                </Grid>


                <Dialog
                    open={this.state.assign} style={{}}>
                    <Grid style={{ height: "400px", width: "500px", padding: 25 }}>


                        <Grid style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}>
                            <Typography style={{ fontSize: "18px", fontWeight: "500" }}>Assign Members</Typography>
                            <CloseIcon
                                onClick={() => {
                                    this.setState({ assign: false });
                                }}
                            />
                        </Grid>


                        <Grid style={{ marginTop: "3%", display: "flex" }}>
                            <FormControl sx={{ m: 1, width: 800 }}>
                                <InputLabel labelId="demo-multiple-chip-label">Assign Member here</InputLabel>
                                <Select
                                    style={{
                                        // width: "450px",
                                        width: "100%",
                                    }}
                                    value={this.state.memebrId}
                                    onChange={(e) => {
                                        this.setState({ memebrId: e.target.value })
                                    }}
                                >
                                    {/* <MenuItem value={"All Batch"}>{"All Batch"}</MenuItem> */}
                                    {Array.isArray(this.props.space.all_members) && this.props.space.all_members.
                                        filter(item => Array.isArray(this.props.space.space_details.users) ?
                                            !this.props.space.space_details.users.some(obj => obj?._id === item?._id):item).
                                        map((item) => (
                                            <MenuItem value={item?._id}
                                            // onClick={() => {
                                            //     this.addMemberid(item._id)
                                            // }}
                                            >{item.name}</MenuItem>
                                        ))}
                                </Select>

                            </FormControl>


                        </Grid>



                        {/* <Grid style={{bottom:"0",}}>
                            <Button variant='contained' style={{backgroundColor: "#1C352D"}}>Submit</Button>
                        </Grid> */}


                    </Grid>
                    <Grid style={{ padding: "19px", display: "flex", justifyContent: "space-between" }}>
                        <Button variant='standard'
                            onClick={() => {
                                this.setState({ assign: false });
                            }}
                            style={{ border: "1px solid #1C352D", color: "#1C352D" }}>Cancel</Button>
                        <Button variant='contained'
                            disabled={this.state.memebrId === ""}
                            onClick={() => {
                                this.setState({ assign: false, memebrId: "" });
                                this.props.add_member_space(this.props.space.space_details?._id, this.state.memebrId)
                            }}
                            style={{ backgroundColor: "#1C352D" }}>Submit</Button>
                    </Grid>
                </Dialog>

                <Dialog
                    open={this.state.calicon}
                >
                    <Calendar
                        onChange={this.handleChangeCal} value={calvalue} />
                </Dialog>
                <Dialog
                    open={this.state.addtask} style={{}}>
                    <Grid style={{ height: "450px", width: "600px", padding: 25 }}>


                        <Grid style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}>
                            <Typography style={{ fontSize: "18px", fontWeight: "500" }}>Assign Task</Typography>
                            <CloseIcon onClick={() => {
                                this.setState({ addtask: false });
                            }} />
                        </Grid>


                        <Grid style={{ display: "flex", flexDirection: "row", gap: 100 }}>

                            <TextField id="standard-basic" label="Task Name " variant="standard"
                                onChange={(e) => {
                                    this.setState({ task_name: e.target.value })
                                }}
                            ></TextField>
                            <TextField id="standard-basic" label="Notes" variant="standard" style={{ color: "black" }}
                                onChange={(e) => {
                                    this.setState({ task_desc: e.target.value })
                                }}
                            > </TextField>
                        </Grid>

                        <Grid style={{ display: "flex", flexDirection: "row", gap: 100, marginTop: "5%", width: "500px" }}>
                            <TextField
                                style={{ width: "185px" }}
                                id="input-with-icon-textfield"
                                value={calvalue}
                                type='date'
                                // label="Start Date"
                                inputProps={{ min: today }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {/* <CalendarMonthIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => { this.setState({ calicon: true }); }}
                      /> */}
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={(e) => {
                                    this.setState({ start_date: e.target.value })
                                }}
                            ></TextField>


                            <TextField
                                style={{ width: "185px" }}

                                id="input-with-icon-textfield"
                                // label="Deadline Date"
                                type='date'
                                value={calvalue1}
                                inputProps={{ min: today }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={(e) => {
                                    this.setState({ end_date: e.target.value })
                                }}
                            />




                        </Grid>

                        <Grid style={{ display: "flex", flexDirection: "row", gap: 100, marginTop: "5%", width: "500px" }}>
                            <TextField id="standard-basic" label="Total Hours" variant="standard"
                                type='number'
                                onChange={(e) => {
                                    this.setState({ total_hrs: e.target.value })
                                }}
                            />
                            <TextField id="standard-basic" label="Select Member " variant="standard"
                                select
                                value={this.state.memebrId}
                                onChange={(e) => {
                                    this.setState({ memebrId: e.target.value })
                                }}
                            >
                                {Array.isArray(this.props.space.space_details.users) && this.props.space.space_details.users.map((item) => {
                                    return (
                                        <MenuItem value={item?._id}>
                                            {item?.name}
                                        </MenuItem>
                                    )
                                })
                                }
                            </TextField>
                        </Grid>

                        <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "14%", width: "500px" }}>
                            <span></span>
                            <Button
                                disabled={this.state.memebrId === "" || this.state.task_name === "" || this.state.task_desc === "" || this.state.start_date === "" || this.state.end_date === "" || this.state.total_hrs === ""}
                                onClick={() => {
                                    this.setState({ addtask: false, task_name: "", task_desc: "", start_date: "", end_date: "", memebrId: "", total_hrs: "", space_id: "", });
                                    this.props.add_task(this.props.space.space_details._id, this.state.task_name, this.state.task_desc, this.state.start_date, this.state.end_date, this.state.memebrId, this.props.login.user_id, this.state.total_hrs)
                                }}
                                size='small' variant='contained' style={{ backgroundColor: "#1C352D", fontSize: "14px", marginRight: "2%", textTransform: "capitalize" }}

                            >Submit</Button>
                        </Grid>






                    </Grid>
                </Dialog>
                <Loader />
            </Grid>
        )
    }
}
