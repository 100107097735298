
import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import 'react-phone-input-2/lib/bootstrap.css'
import vdo from "../../media/img/Computer.gif";
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Dialog from "@mui/material/Dialog";
import "../../auth/components/org.css";
import Textarea from '@mui/joy/Textarea';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import loader_cont from '../../common/loader/containers/loader_cont';



export default class Taskorg extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addorg: false,
            category: '',
            logo: "",
            SelectOrg: false,
            orgdata: [
                {

                    title: "Technoboot.pvt.ltd",

                },
                {

                    title: "Technoboot.pvt.ltd",

                },
                {

                    title: "Technobt.pvt.ltd",

                },

            ]

        };
    }

    componentDidMount() {
        this.props.viewAccessByUser(this.props.login.user_id)

    }
    render() {

        return (

            <Grid container sx={{ backgroundColor: "#FBFFFD", justifyContent: 'space-between' }} direction={'row'}>
                <Grid item xs={5} mt={'1%'}>
                    <img className='videos' src={vdo} />

                </Grid>

                <Grid style={{ backgroundColor: "#EFFFFA" }} item xs={6} >
                    <center>
                        <Grid container display={'flex'} spacing={-20}>
                            <Grid className='login_text1' item xs={12} mt={20}>
                                <p style={{ fontWeight: 700 }}>Choose an Organization</p>
                            </Grid>
                            <Card style={{ marginLeft: "auto", marginRight: "auto", width: "300px", marginTop: "10px", height: "auto", boxShadow: "2px 2px 15px rgba(0, 0, 0, 0.2)", borderRadius: "10px" }}>
                                <CardContent>
                                    <Box>
                                        <Card style={{ boxShadow: "none", }}>
                                            {/* maxHeight: "300", overflowY: "scroll" */}


                                            {Array.isArray(this.props.login.all_org) && this.props.login.all_org.map((orgdata, index) => (
                                                <Box style={{ display: "flex", marginLeft: "30px", marginTop: "12px" }}>
                                                    <Button
                                                        onClick={() => {
                                                            this.props.viewUserOrgDetails(this.props.login.user_id, orgdata._id)
                                                        }}
                                                    >
                                                        <Avatar
                                                            src={orgdata.logo}
                                                            sx={{ width: 24, height: 24, marginLeft: "5%", marginTop: "2%" }} />
                                                        <Typography

                                                            style={{ color: "black", display: "flex", fontSize: "15px", marginTop: "6px", marginLeft: "10px", fontWeight: 400 }}>
                                                            {orgdata.org_name}
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            ))}


                                        </Card>
                                    </Box>


                                    <Box style={{ display: "flex", marginTop: "5%", marginLeft: "30px", }}>


                                        <Button

                                            onClick={() => {
                                                this.setState({ SelectOrg: true });
                                                this.props.view_owned_org(this.props.login.user_id, 'tms')
                                            }}
                                            startIcon={<AddIcon style={{ fontSize: "15px", color: "#1C352D", }} />} 
                                             style={{ color: "#1C352D", display: "flex", fontSize: "13px", fontWeight: 600 }}>
                                            Add Organization
                                        </Button>



                                    </Box>


                                </CardContent>

                            </Card>





                        </Grid>


                    </center>
                </Grid>


                <Dialog
                    // onClose={this.setState({addorg:false})}
                    open={this.state.SelectOrg} style={{}}
                    onClose={() => {
                        this.setState({ SelectOrg: false })
                    }}
                >
                    <Grid style={{ height: "auto", width: "450px", marginBottom: "30px" }}>

                        <Box >

                            <Typography className='Create'>Select Organization</Typography>

                            {Array.isArray(this.props.login.org_own) && this.props.login.org_own.map((orgdata, index) => (
                                <Box style={{ display: "flex", marginLeft: "30px", marginTop: "12px" }}>
                                    <Button
                                        onClick={() => {
                                            this.setState({ SelectOrg: false })
                                            this.props.create_new_access(this.props.login.user_id, orgdata._id.toString(), 'tms')
                                        }}
                                    >
                                        <Avatar
                                            src={orgdata.logo}
                                            sx={{ width: 24, height: 24, marginLeft: "5%", marginTop: "2%" }} />
                                        <Typography
                                            style={{ color: "black", display: "flex", fontSize: "15px", marginTop: "6px", marginLeft: "10px", fontWeight: 400 }}>
                                            {orgdata.org_name}
                                        </Typography>
                                    </Button>
                                </Box>
                            ))}
                            <Grid style={{ marginTop: "5%" }}>
                                <center>
                                    <Button

                                        onClick={() => {
                                            this.setState({ addorg: true });
                                        }}
                                        startIcon={<AddIcon style={{ fontSize: "15px", color: "#1C352D", }} />} style={{ color: "#1C352D", display: "flex", fontSize: "13px", fontWeight: 600 }}>
                                        Create Organization
                                    </Button>
                                </center>

                            </Grid>
                        </Box>

                    </Grid>
                </Dialog>

                <Dialog
                    // onClose={this.setState({addorg:false})}
                    open={this.state.addorg} style={{}}
                    onClose={() => {
                        this.setState({ addorg: false })
                    }}
                >
                    <Grid style={{ height: "auto", width: "450px", marginBottom: "30px" }}>

                        <Box >

                            <Typography className='Create'>Create Organization</Typography>


                            <Grid style={{ marginTop: "5%" }}>
                                <Avatar src={this.state.logo !== "" ? URL.createObjectURL(this.state.logo) : this.state.logo} style={{ marginLeft: "auto", marginRight: "auto" }}>
                                    <input style={{ display: "none" }} type="file" id="file" onChange={(e) => { this.setState({ logo: e.target.files[0] }) }} />
                                    <label htmlFor="file"><CameraAltIcon style={{ marginTop: "25%" }} /></label>
                                </Avatar>

                                <Typography className='Name' style={{}}>Name of Organization</Typography>
                                <TextField style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
                                    onChange={(e) => {
                                        this.setState({ org_name: e.target.value })
                                    }}
                                />



                                <Typography className='Name' style={{ marginTop: "10px" }}>Email</Typography>
                                <TextField type="Email" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value })
                                    }}
                                />

                                <Typography className='Name' style={{ marginTop: "10px" }}>Phone Number</Typography>
                                <TextField type="number" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
                                    onChange={(e) => {
                                        this.setState({ mobile: e.target.value })
                                    }}
                                />

                                <Typography className='Name' style={{ marginTop: "10px" }}>GSTIN</Typography>
                                {/* <TextField style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
                                    onChange={(e) => {
                                        this.setState({ gstin: e.target.value })
                                    }}
                                />
                                <Typography className='Name' style={{ marginTop: "10px" }}>Address</Typography> */}


                                <Textarea style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5", marginTop: "10px" }} name="Outlined" placeholder="Type in here…" variant="outlined"
                                    onChange={(e) => {
                                        this.setState({ address: e.target.value })
                                    }}
                                />

                                <center>
                                    <button className='buttonnnn'
                                        disabled={this.state.org_name === ""}
                                        onClick={() => {
                                            this.setState({ addorg: false });
                                            this.props.uploadOrgLogo(this.state.logo, this.state.org_name, this.state.email, this.state.mobile, this.state.gstin, this.props.login.user_id)
                                        }}
                                        style={{ color: "black" }}>Add Organization</button>
                                </center>

                            </Grid>
                        </Box>

                    </Grid>
                </Dialog>

                <loader_cont />

            </Grid>





        )
    }
}

