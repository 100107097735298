import React, { Component } from 'react';
import Style from "../Assigntask/assigntask.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
// import { TextField, FormControl, MenuItem, Menu, Select, DialogTitle, Link } from "@mui/material";
import { Typography, Button, Divider, TextField, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Paper from "@mui/material/Paper";
// import TableCell from '@mui/material/TableCell';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import BackgroundImg from "../../pages/image/BackgroundTaskplay 1.png";
import Startico from "../image/lucide_play-circle.png";
import Start from "../image/start.png";
import Pause from "../image/pause.png";
import Complete from "../image/complete.png";
import { styled } from '@mui/material/styles';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import PauseIcon from '@mui/icons-material/Pause';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CompleteTaskImg from "../image/completeImg.png";
import reactangle from "../image/Rectangle 8.png";
import CachedIcon from '@mui/icons-material/Cached';
import Loader from '../../common/loader/containers/loader_cont';
import { CloseOutlined } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        //   backgroundColor: theme.palette.common.#95C7B7,
        backgroundColor: "#95C7B7",

        color: "#2E384D",

    },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "white",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "#B5E4D5",
    },
}));


class assigntask extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            createSpace: false,
            isHidden: false,
            showTime: false,
            copied: false,
            cong: false,
            open: false,
            assign_to: "",
            spacedate: [

                {
                    taskname: "UI Design",
                    totalHour: "5hr",

                },
                {
                    taskname: "UI Design complete and another page start",
                    totalHour: "5hr",

                },
                {
                    taskname: "UI Design complete and another page start and finish",
                    totalHour: "5hr",

                },


            ],
            task_details: "",

        }

    }

    toggleTime = () => {
        this.setState({
            showTime: !this.state.showTime,

        });
    };

    toggleElement = () => {
        this.setState((prevState) => ({
            isHidden: !prevState.isHidden,
        }));
    };
    handleRefresh = () => {
        window.location.reload();
    };
    handleChangeCal = (calvalue) => {
        ;
        this.setState({ calicon: false });
        const formattedDate = calvalue.toISOString().split('T')[0];
        this.setState({ calvalue: formattedDate });
    };
    handleChangeCal1 = (calvalue1) => {
        ;
        this.setState({ calicon1: false });
        const formattedDate = calvalue1.toISOString().split('T')[0];
        this.setState({ calvalue1: formattedDate });
    };
    componentDidMount() {
        // this.props.view_all_task_assign(this.props.login.user_id);
        if (this.props.login.type === "Viewer") {
            this.props.view_all_task_assign2(this.props.myspace.space_details._id, this.props.login.user_id);
        }
        else if (this.props.login.type === "Editor") {
            this.props.view_all_space_task(this.props.myspace.space_details._id);
        }


    }
    render() {
        const { calvalue, calvalue1 } = this.state;
        const today = moment().format('YYYY-MM-DD');
        const { isOpen } = this.state;
        return (
            <div className='bg-img'>
                {((Array.isArray(this.props.assgn_tsk.assign_task) && this.props.assgn_tsk.assign_task.length >= 1) || this.props.login.type === "Editor") && <Grid style={{ marginLeft: "10%", display: "flex", justifyContent: "space-between" }}>

                    <Grid style={{ marginLeft: "5%", paddingTop: "3%", width: "100%" }}>
                        <Grid style={{
                            marginLeft: "7%", width: "80%", borderRadius: "4px", boxShadow: "0px 0px 16px 0px #878787"
                        }}>
                            <Grid style={{ display: "flex", justifyContent: "space-between", backgroundColor: "white", padding: "9px", paddingRight: "18px" }}>
                                <Grid style={{ padding: "10px", marginBottom: "0%", borderRadius: "4px 4px 0px 0px" }}>
                                    <Typography style={{ color: "black", fontSize: "23px", fontWeight: "500" }}>
                                        Task Inventory
                                    </Typography>
                                </Grid>
                                {/* <Grid style={{ padding: "10px", background: "#1C352D", marginBottom: "0%", borderRadius: "4px 4px 0px 0px" }}> */}
                                {this.props.login.type === "Editor" &&
                                    <Button style={{ borderRadius: "4px", background: "#1C352D", color: "white", }} variant="contained" startIcon={<AddIcon />}
                                        onClick={() => { this.setState({ addtask: true }); }}

                                    >
                                        Add Task
                                    </Button>

                                }
                            </Grid>
                            {/* </Grid> */}



                            <Grid style={{ padding: "15px", background: "white", borderRadius: " 0px 0px 4px 4px " }}>

                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">

                                        <TableHead style={{ background: "#95C7B7", height: "12px" }}>
                                            <TableRow >

                                                <StyledTableCell>Sr. No.</StyledTableCell>
                                                <StyledTableCell> Task Name</StyledTableCell>
                                                {this.props.login.type === "Editor" && <StyledTableCell> Assign To</StyledTableCell>}
                                                <StyledTableCell> Assign By</StyledTableCell>
                                                <StyledTableCell style={{ whiteSpace: "nowrap" }}>Total Hours</StyledTableCell>
                                                <StyledTableCell>Action</StyledTableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {Array.isArray(this.props.assgn_tsk.assign_task) && this.props.assgn_tsk.assign_task.
                                                sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                                .map((item, index) => {
                                                    return (

                                                        // here all table row background color same for mapping
                                                        // <StyledTableRow  style={{background:"#EFFFFA",border:"1px solid lightgray"}}>

                                                        <StyledTableRow
                                                            onClick={() => {
                                                                this.setState({ task_details: item, open: true })
                                                                this.props.view_task_by_id(item._id.toString())
                                                            }}
                                                        >
                                                            {/* <Link to="/user" className="table-row" style={{ display: "contents", textDecoration: "none" }}> */}
                                                            <StyledTableCell> {index + 1}</StyledTableCell>
                                                            <StyledTableCell> {item.task_name}</StyledTableCell>
                                                            {this.props.login.type === "Editor" && <StyledTableCell> {item.assign_to_detail && item.assign_to_detail.name}</StyledTableCell>}
                                                            <StyledTableCell> {item.assign_by_detail && item.assign_by_detail.name}</StyledTableCell>
                                                            <StyledTableCell> {item.total_time}</StyledTableCell>


                                                            <StyledTableCell >
                                                                <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column", background: "#1C352D", borderRadius: "4px" }}>
                                                                    <Button
                                                                        // onClick={this.handleButtonClick}
                                                                        disabled={item.status === "completed"}
                                                                        onClick={() => {
                                                                            // this.setState({ createSpace: true, task_id: item._id, status: item.status });

                                                                        }}
                                                                        style={{ gap: "10px", textTransform: "capitalize", background: "#1C352D", padding: "12px", color: "white" }}>
                                                                        {/* <img src={Startico}
                                                                    className='img-focus'
                                                                    onClick={this.toggleElement} /> */}
                                                                        {item.status === "notstarted" ? <NotStartedIcon /> : item.status === "stop" ? <PauseIcon /> : item.status === "start" ? <PlayCircleFilledWhiteIcon /> : <CheckCircleOutlineIcon />}
                                                                        {item.status === "notstarted" ? "Pending" : item.status === "stop" ? "Pause" : item.status === "start" ? "Start" : item.status === "completed" ? "Complete" : item.status}
                                                                        <br></br>
                                                                        {item.status === "completed" &&
                                                                            item.complete_hours + 'h' + ":" + item.complete_minutes + 'm' + ":" + item.complete_second + 's'
                                                                        }
                                                                    </Button>
                                                                    {/* {this.state.isHidden ? null :
                                                                        <button style={{ paddingBottom: "4px", border: "none", outline: "none", color: "white", background: "none", fontWeight: "bold" }}>
                                                                            00.24.50
                                                                        </button>
                                                                    } */}

                                                                </Box>

                                                                {/* code here : when deadline is over button timeline button will be red bg color */}

                                                                {/* <Button
                                                                onClick={this.handleButtonClick}
                                                                style={{ gap: "10px", textTransform: "capitalize", background: "maroon", padding: "12px", color: "white" }}>
                                                                <img src={Startico}
                                                                    className='img-focus'
                                                                    onClick={this.toggleElement} />
                                                                Start
                                                                {this.state.isHidden ? null :
                                                                    <button style={{ border: "none", outline: "none", color: "white", background: "none", fontWeight: "bold" }}>
                                                                        00.24.50
                                                                    </button>
                                                                }
                                                            </Button> */}

                                                            </StyledTableCell>


                                                            {/* </Link> */}

                                                        </StyledTableRow>



                                                    )
                                                })}

                                        </TableBody>

                                    </Table>

                                </TableContainer>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/* right side part here  */}
                    {this.props.assgn_tsk.task_details && (this.props.assgn_tsk.task_details.status === "notstarted" || this.props.assgn_tsk.task_details.status === "start" || this.props.assgn_tsk.task_details.status === "stop") && this.state.open &&
                        <Grid style={{ width: "45%", height: "100vh", background: "white" }}>

                            <Grid style={{ marginTop: "7%", marginLeft: "7%", }}>
                                <Button style={{ textTransform: "capitalize", width: "25%", borderRadius: "4px", background: "#FFD78C", color: "#9A6400", width: "30%", padding: "10px", whiteSpace: "nowrap" }}>{this.props.assgn_tsk.task_details.status === "notstarted" || this.props.assgn_tsk.task_details.status === "stop" ? "Time is off" : "Time is On"}</Button>

                                <CloseIcon style={{ color: "gray", cursor: "pointer", marginLeft: "200px", marginTop: "9px" }}
                                    onClick={() => {
                                        this.setState({ open: false });
                                    }} />

                            </Grid>

                            <Grid style={{ background: "#EFFFFA", border: "3px solid #97adc6 ", borderRadius: "4px", height: "300px", width: "350px", alignItems: "center", textAlign: "center", padding: " 30px 20px 0px 30px", marginTop: "8%", marginLeft: "7%" }}>
                                <Typography style={{ color: "#8097B1", fontSize: "16px", fontWeight: "400" }}>{this.props.assgn_tsk.task_details.task_name && this.props.assgn_tsk.task_details.task_name}</Typography>
                                <Typography style={{ color: "#2E384D", fontSize: "18px", fontWeight: "500", marginTop: "1%" }}> {this.props.assgn_tsk.task_details && this.props.assgn_tsk.task_details.desc}</Typography>

                                <Box style={{ marginTop: "15px", textAlign: "center", marginLeft: "15%", marginBottom: "3%" }}>
                                    <Box style={{ display: "flex", gap: "20px" }}>
                                        <Typography style={{ color: "#8097B1", fontSize: "16px", fontWeight: "400" }}>Given By </Typography>
                                        <Typography style={{ fontSize: "16px", fontWeight: "400" }}>{this.props.assgn_tsk.task_details && this.props.assgn_tsk.task_details.assign_by_detail && this.props.assgn_tsk.task_details.assign_by_detail.name}</Typography>
                                    </Box>
                                </Box>
                                <Box style={{ marginTop: "15px", textAlign: "center", marginLeft: "15%", marginBottom: "3%" }}>
                                    <Box style={{ display: "flex", gap: "20px" }}>
                                        <Typography style={{ color: "#8097B1", fontSize: "16px", fontWeight: "400" }}>Start Date </Typography>
                                        <Typography style={{ fontSize: "16px", fontWeight: "400" }}>{this.props.assgn_tsk.task_details.start_date}</Typography>
                                    </Box>
                                </Box>
                                <Box style={{ marginTop: "15px", textAlign: "center", marginLeft: "15%", marginBottom: "3%" }}>
                                    <Box style={{ display: "flex", gap: "20px" }}>
                                        <Typography style={{ color: "#8097B1", fontSize: "16px", fontWeight: "400" }}>Dead Line</Typography>
                                        <Typography style={{ fontSize: "16px", fontWeight: "400" }}>{this.props.assgn_tsk.task_details.start_date}</Typography>
                                    </Box>
                                </Box>

                                <Box style={{ paddingTop: "4%", display: "flex", justifyContent: "space-between", gap: "20px" }}>

                                    <Grid
                                        onClick={() => {
                                            this.setState({ createSpace: true, task_id: this.props.assgn_tsk.task_details._id, assign_to: this.props.assgn_tsk.task_details.assign_to, status: this.props.assgn_tsk.task_details.status });
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                            {/* <img src={Start} /> */}
                                            {this.props.assgn_tsk.task_details.status === "notstarted" || this.props.assgn_tsk.task_details.status === "stop" ?
                                                <img src={Pause} /> : this.props.assgn_tsk.task_details.status === "start" && <img src={Start} />}
                                            <Typography style={{ color: "#2E384D" }}>
                                                {this.props.assgn_tsk.task_details.status === "notstarted" ?
                                                    "Pending" : this.props.assgn_tsk.task_details.status === "stop" ?
                                                        "Pause" : this.props.assgn_tsk.task_details.status === "start" ?
                                                            "Start" : this.props.assgn_tsk.task_details.status === "completed" ? "Complete" : this.props.assgn_tsk.task_details.status}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}
                                        onClick={() => {
                                            if (this.props.login.user_id === this.props.assgn_tsk.task_details.assign_to) {
                                                this.props.mark_as_complete(this.props.assgn_tsk.task_details._id, this.props.login.user_id, this.props.login.type, this.props.myspace.space_details._id)
                                            }
                                        }}
                                    >
                                        <a style={{ textDecoration: "none" }}>
                                            <img src={Complete} />
                                        </a>
                                        <Typography style={{ color: "#2E384D" }}>Complete</Typography>

                                    </Box>

                                </Box>

                            </Grid>

                        </Grid>
                    }

                    {this.props.assgn_tsk.task_details && this.props.assgn_tsk.task_details.status === "completed" && this.state.open &&
                        <>

                            <Grid style={{ width: "45%", height: "100vh", background: "white", padding: "12px" }} >


                                <CloseIcon style={{ color: "gray", cursor: "pointer" }}
                                    onClick={() => {
                                        this.setState({ open: false });
                                    }} />


                                <Grid style={{}}>
                                    <Grid style={{ marginBottom: "9%", paddingTop: "19%" }}>
                                        <img src={CompleteTaskImg} />
                                    </Grid>
                                    <Grid style={{ padding: "17px", marginTop: "30px" }}>
                                        <Typography style={{ textAlign: "center", padding: "17px" }}>
                                            Congratulations on completing the task!
                                            Your hard work and dedication have made a significant impact.
                                            Well done!
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </>
                    }
                </Grid>}
                <Dialog
                    open={this.state.addtask} style={{}}>
                    <Grid style={{ height: "450px", width: "600px", padding: 25 }}>


                        <Grid style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}>
                            <Typography style={{ fontSize: "18px", fontWeight: "500" }}>Assign Task</Typography>
                            <CloseIcon onClick={() => {
                                this.setState({ addtask: false });
                            }} />
                        </Grid>


                        <Grid style={{ display: "flex", flexDirection: "row", gap: 100 }}>

                            <TextField id="standard-basic" label="Task Name " variant="standard"
                                onChange={(e) => {
                                    this.setState({ task_name: e.target.value })
                                }}
                            ></TextField>
                            <TextField id="standard-basic" label="Notes" variant="standard" style={{ color: "black" }}
                                onChange={(e) => {
                                    this.setState({ task_desc: e.target.value })
                                }}
                            > </TextField>
                        </Grid>

                        <Grid style={{ display: "flex", flexDirection: "row", gap: 100, marginTop: "5%", width: "500px" }}>
                            <TextField
                                inputProps={{ min: today }}
                                style={{ width: "185px" }}
                                id="input-with-icon-textfield"
                                value={calvalue}
                                type='date'
                                // label="Start Date"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {/* <CalendarMonthIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => { this.setState({ calicon: true }); }}
                      /> */}
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={(e) => {
                                    this.setState({ start_date: e.target.value })
                                }}
                            ></TextField>


                            <TextField
                                style={{ width: "185px" }}

                                id="input-with-icon-textfield"
                                // label="Deadline Date"
                                type='date'
                                value={calvalue1}
                                inputProps={{ min: today }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                onChange={(e) => {
                                    this.setState({ end_date: e.target.value })
                                }}
                            />




                        </Grid>

                        <Grid style={{ display: "flex", flexDirection: "row", gap: 100, marginTop: "5%", width: "500px" }}>
                            <TextField id="standard-basic" label="Total Hours" variant="standard"
                                type='number'
                                onChange={(e) => {
                                    this.setState({ total_hrs: e.target.value })
                                }}
                            />
                            <TextField id="standard-basic" label="Select Members " variant="standard"
                                select
                                value={this.state.memebrId}
                                onChange={(e) => {
                                    this.setState({ memebrId: e.target.value })
                                }}
                            >
                                {Array.isArray(this.props.myspace.space_details.users) && this.props.myspace.space_details.users.map((item) => {
                                    return (
                                        <MenuItem value={item._id}>
                                            {item.name}
                                        </MenuItem>
                                    )
                                })
                                }
                            </TextField>
                        </Grid>

                        <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "14%", width: "500px" }}>
                            <span></span>
                            <Button
                                disabled={this.state.memebrId === "" || this.state.task_name === "" || this.state.task_desc === "" || this.state.start_date === "" || this.state.end_date === "" || this.state.total_hrs === ""}
                                onClick={() => {
                                    this.setState({ addtask: false, task_name: "", task_desc: "", start_date: "", end_date: "", memebrId: "", total_hrs: "", space_id: "" });
                                    this.props.add_task(this.props.myspace.space_details._id, this.state.task_name, this.state.task_desc, this.state.start_date, this.state.end_date, this.state.memebrId, this.props.login.user_id, this.state.total_hrs, "E")
                                }}
                                size='small' variant='contained' style={{ backgroundColor: "#1C352D", fontSize: "14px", marginRight: "2%", textTransform: "capitalize" }}
                            >Submit</Button>
                        </Grid>
                    </Grid>
                </Dialog>
                <Dialog
                    onClose={() => this.setState({ createSpace: false })}
                    open={this.state.createSpace} style={{}}>

                    {/* <CloseOutlined /> */}

                    <Grid style={{ textAlign: "center", padding: "24px" }}>
                        <Typography>
                            Great start! Pause or complete as needed. Keep up the excellent work on your journey.
                        </Typography>
                        <Button variant='contained'
                            disabled={this.props.login.user_id !== this.state.assign_to}
                            onClick={() => {
                                this.setState({ createSpace: false });
                                if (this.state.status === "stop" || this.state.status === "notstarted") {
                                    this.props.start_time(this.props.login.user_id, this.state.task_id, this.props.login.type, this.props.myspace.space_details._id)
                                }
                                else {
                                    this.props.stop_task(this.props.login.user_id, this.state.task_id, this.props.login.type, this.props.myspace.space_details._id)
                                }
                            }}
                            style={{ background: "#2F5247", marginTop: "3%" }}>
                            Okay
                        </Button>
                    </Grid>
                </Dialog>
                {this.props.login.type === "Viewer" && Array.isArray(this.props.assgn_tsk.assign_task) && this.props.assgn_tsk.assign_task.length === 0 &&
                    <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Grid style={{ borderRadius: "2px", background: "white", height: '600px', display: 'flex', flexDirection: 'column', gap: '40px', justifyContent: 'center', boxShadow: '0px 0px 16px 0px #878787', alignItems: 'center', width: '600px', padding: '80px 100px' }}>
                            <img style={{ width: '403px', height: '285px' }} src={reactangle} alt="" />
                            <Typography style={{ color: '#2E384D', textAlign: 'center', fontSize: '16px', fontWeight: 400, lineHeight: '24px', }}>We haven't assigned a task to you yet. Stay tuned for new tasks in your queue!</Typography>


                            <Typography style={{ display: 'flex', flexDirection: 'row', gap: '8px', cursor: "pointer" }}
                                onClick={() => {
                                    this.handleRefresh()
                                }}
                            >
                                <CachedIcon style={{ height: '24px', width: '24px', padding: '3px' }} />
                                <Typography>Refresh</Typography>
                            </Typography>
                        </Grid>
                    </Grid>}
                <Loader />
            </div>

        );
    }
}

export default assigntask;