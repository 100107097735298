import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Facebook from "../../pages/image/facebook.png"
import Google from "../../pages/image/google.png"
import LoaderCon from "../../common/loader/containers/loader_cont";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import OtpInput from 'react-otp-input';
import vdo from "../../media/img/Computer.gif";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { Row } from 'rsuite';
import Pay from "../../auth/components/payment"
import loader_cont from '../../common/loader/containers/loader_cont';
import { useParams } from 'react-router-dom';
// import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

// import Login from "taskplay-web"


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            otp: "",
            phone: "",
            contact_num: "",
            otpCode: '',
            change_num: false,
            enter_num: false,
            bt_type: true,
            otpClick: false,
            email: "",
        };
    }

    handleOnChange = (value, data, event, formattedValue) => {
        this.setState({
            contact_num: value,
        });
    }
    componentDidMount() {
        document.body.style.backgroundColor = "var(--bg, #FBFFFD)";
        console.log(this.props.params)
        if (this.props.params.id === undefined) {
            console.log("called")
            const payload = {
                product: "tms",
            };
            fetch('https://sso-backend-zpicrijtna-el.a.run.app/v1/login_called', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status) {
                        this.setState({
                            data: data,
                            isLoading: false,
                            openErrorSnackbar: true,
                            message: "Otp Verified",
                            org: true,
                            orgData: data.result,
                        });
                        window.location.href = "https://sso.technoboot.co/" + data.result
                        console.log(this.state.orgData)
                    }
                    else {
                        this.setState({
                            data: data,
                            isLoading: false,
                            openErrorSnackbar: true,
                            message: "Some Error Ocured"
                        });
                    }

                })
                .catch((error) => {
                    console.error(error);
                    this.setState({ openErrorSnackbar: true, message: "Soome Error Occured" })
                });
        } else {
            this.props.login_auth(this.props.params.id);
        }
    }
    // handleChangeNumber = (event) => {
    //     const inputValue = event.target.value;
    //     const numberValue = Number(inputValue);
    //     if (!isNaN(numberValue) && numberValue.toString().length <= 10) {
    //       this.props.onChange(numberValue);
    //     }
    //   };
    handlenumChange = (value) => {
        // const inputValue = event.target.value;
        // const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
        // const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
        this.setState({ contact_num: value, });
    }
    handleChange = (otp) => this.setState({ otp });
    render() {
        // const handleChange = otpCode => {
        //     this.setState({ otpCode });
        // };
        return (

            <Grid container sx={{ backgroundColor: "#FBFFFD" }} direction={'row'}>
                <Grid item xs={5} alignContent={'center'} mt={'3%'}>
                    <img className='videos' src={vdo} >
                    </img>
                </Grid>
                <Grid item xs={0.5}>
                </Grid>
                <Grid item xs={6} >
                    <center>
                        <Grid container display={'flex'} spacing={-20}>
                            <Grid className='login_text1' item xs={12} mt={20}>
                                <p>Welcome, Admin!</p>
                            </Grid>
                            <Grid className='login_text2' item xs={12} mt={-3}>
                                <p>Your steady hand keeps chaos at bay. Lead with patience, as you guide the team to success with grace.</p>
                            </Grid>
                            <Grid className='login_text3' item xs={12} mt={-2}>
                                <p>Login with your company's Gmail account to view assigned tasks and access your account.</p>
                            </Grid>
                            <Grid container >
                                <Grid item xs={12} mr={"60%"}>
                                    <p>Enter Your Email_ID<strong style={{ color: 'red' }}>*</strong></p>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField
                                        placeholder='Enter Your Email Id'
                                        sx={{ marginLeft: '10%' }}
                                        fullWidth
                                        size='small'
                                        type='email'
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={9}></Grid>
                                <Grid item xs={2} mt={1} direction={'row'}>
                                    {!this.state.otpClick && <Button

                                        sx={
                                            // this.state.email === '' ?
                                            { borderColor: "#AAAAAA", backgroundColor: "#AAAAAA", color: "#555555", }
                                            // : { width: "auto", borderColor: "#AAAAAA", backgroundColor: "var(--PrimaryColor, #1C352D)", color: "white" }
                                        }
                                        variant='outlined'
                                        onClick={() => {
                                            this.setState({ otpClick: true })
                                            this.props.phone_login(this.state.email)
                                        }}
                                    >
                                        {
                                            // this.state.email === '' ?
                                            "Submit"
                                            // : 'Request OTP'
                                        }
                                    </Button>}
                                    {/* <Pay/> */}

                                </Grid>

                            </Grid>
                            {this.state.otpClick && <Grid container mt={'-3%'} >
                                <Grid item xs={12} mr={"80%"} >
                                    <p>OTP</p>
                                </Grid>
                                <Grid item xs={12} ml={'8%'}>
                                    <div style={{ marginLeft: '-2%' }}>
                                        <OtpInput
                                            inputStyle={{
                                                marginLeft: '%',
                                                margin: '2%',
                                                backgroundColor: '#FBFFFD',
                                                width: '5rem',
                                                height: '2rem',
                                                // marginLeft: "3%",
                                                fontSize: '2rem',
                                                borderRadius: '4px',
                                                borderBottom: '1px solid var(--grey-g-3, #B0BAC9)',
                                                borderTop: '0px solid black',
                                                borderRight: '0px solid black',
                                                borderLeft: '0px solid black',
                                                // boxShadow: " 2px 2px 6px rgba(0, 0, 0, 0.25)"
                                            }}

                                            onChange={this.handleChange}
                                            numInputs={5}
                                            value={this.state.otp}
                                            renderSeparator={<span></span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={8.2} display={'flex'} justifyContent={'flex-start'} mt={1} color={'var(--Warning-Color, #E74C3C)'}>Resend OTP</Grid>
                                <Grid item xs={2} mt={1} display={'flex'} justifyContent={'flex-start'}>
                                    <Button

                                        sx={{ borderColor: "#AAAAAA", backgroundColor: "var(--PrimaryColor, #1C352D)", color: "var(--grey-pure-white, #FFF)", }}
                                        variant='outlined'
                                        onClick={() => {
                                            this.props.verify_otp(this.state.email, this.state.otp)
                                        }}
                                    >
                                        Submit
                                    </Button>
                                    {/* <Pay/> */}

                                </Grid>

                            </Grid>}
                        </Grid>
                    </center>
                </Grid>
                <Grid item xs={0.5}>

                </Grid>
                <loader_cont />

            </Grid>
        )
    }
}
export default withParams(login)