import {
  VIEW_ALL_SPACE,
  VIEW_ALL_MEMBERS,
  SET_SPACE_ID,
  SEARCH_SPACE,
  SET_SPACE,
  SET_MEMBER,
  VIEW_ASIGN_TASK,
  TASK_DETAILS,

} from "./constant";
const initial_state = {


  spaces: [],
  all_members: [],
  space_id: "",
  total_space: 0,
  serach_spaces: [],
  space_details: {},
  member_details: {},
  assign_task: [],
  task_details: {},




};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case VIEW_ALL_SPACE:
      // 
      return state = {
        ...state,
        spaces: action.payload.result, serach_spaces: action.payload.result, total_space: action.payload.length
      };
    case VIEW_ALL_MEMBERS:
      // 
      return state = {
        ...state,
        all_members: action.payload,
      };
    case SET_SPACE_ID:
      // 
      return state = {
        ...state,
        space_id: action.payload,
      };
    case SET_SPACE:
      // 
      return state = {
        ...state,
        space_details: action.payload,
      };
    case SEARCH_SPACE:
      // 
      return state = {
        ...state,
        serach_spaces: action.payload,
      };

    case SET_MEMBER:
      // 
      return state = {
        ...state,
        member_details: action.payload,
      };
    case VIEW_ASIGN_TASK:
      // 
      return state = {
        ...state,
        assign_task: action.payload,
      };
    case TASK_DETAILS:
      // 
      return state = {
        ...state,
        task_details: action.payload,
      };
    default:
      return state;
  }
}

